export const QUIZZES_SET_QUIZ_FILTER = '[Quizzes] Set quiz filter';
export const QUIZZES_SET_SHARED = '[Quizzes] Set shared';
export const QUIZZES_SET_THEME_VALUE = '[Quizzes] Set theme value';

export const initialState = {
    quizFilter: {
        title: '',
        themes: []
    },
    shared: 'unshared',
    themeValue: ''
}

export const setQuizFilter = filter => {
    return { type: QUIZZES_SET_QUIZ_FILTER, quizFilter: filter }
}

export const setShared = value => {
    return { type: QUIZZES_SET_SHARED, shared: value }
}

export const setThemeValue = value => {
    return { type: QUIZZES_SET_THEME_VALUE, themeValue: value }
}
