import {
    initialState,
    QUESTIONS_SET_QUESTION_FILTER,
    QUESTIONS_SET_QUIZ_VALUE,
    QUESTIONS_SET_THEME_VALUE
} from '../actions/questions.action';

const questions = (state = initialState, action) => {
    switch (action.type) {
        case QUESTIONS_SET_QUESTION_FILTER:
            return { ...state, questionFilter: action.questionFilter };
        case QUESTIONS_SET_QUIZ_VALUE:
            return { ...state, quizValue: action.quizValue };
        case QUESTIONS_SET_THEME_VALUE:
            return { ...state, themeValue: action.themeValue };
        default:
            return state
    }
}

export default questions;