import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import Config from '../../config';

class ConnectedUserIconNew extends Component {

    render() {
        const styleContainer = {
            base: {
                display: 'inline-block',
                verticalAlign: 'top',
                margin: '.5em'
            }
        }

        const styleIcon = {
            base: {
                cursor: 'pointer',
                background: '#3a3f51',
                color: '#ffffff',
                border: 'solid 1px #ffffff',
                textAlign: 'center',
                fontSize: '3em',
                paddingTop: '.3em'
            }
        }

        return (
            <div style={[styleContainer.base]}>
                <div className="img-lg rounded-circle" style={[styleIcon.base]} onClick={() => this.props.onClick(Config.action.ADMIN)}>
                    <i className="fa fa-plus menu-icon"></i>
                </div> 
            </div>
        )
    }
}


ConnectedUserIconNew = Radium(ConnectedUserIconNew);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const UserIconNew = connect(mapStateToProps, null)(ConnectedUserIconNew);

export default UserIconNew;