import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';
import Config from '../../config';

export class ConnectedQuestionPublic extends Component {

    render() {
        const { style } = this.props.store;

        let styleContainer = {
            base: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                borderRadius: '3px',
                fontSize: '.8em',
                margin: '.5em .5em 0 .5em',
                padding: '.5em'
            }
        };

        /*const styleImage = {
            width: '4em',
            backgroundImage: `url(${process.env.REACT_APP_IMAGES_DIRECTORY + this.props.question.picture})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
        }*/

        const styleLocale = {
            marginLeft:'.3rem'
        }

        const styleLocales = {
            marginLeft:'.5rem'
        }

        const styleQuestion = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center'
        }

        const styleStatement = {
            marginLeft:'1em',
            paddingTop: '.4em',
            textAlign: 'left'
        }

        const locales = this.props.question.locales.split(',');
        const dynamicLocales = locales.map((item, index) => {
            return <i className={`flag-icon flag-icon-${Config.locales.ICON[item]}`} style={styleLocale} title={item} id={item}></i>
        });

        return (
            <div style={[styleContainer.base, style.question.container[this.props.kind]]} >
                <div style={styleQuestion}>
                    {/*this.props.question.picture && (<div style={styleImage}></div>)*/}
                    <div style={styleStatement} >{this.props.question.statement}</div>
                    <div style={styleLocales}>{dynamicLocales}</div>
                </div>
            </div>
        );
    }
}

ConnectedQuestionPublic = Radium(ConnectedQuestionPublic);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const QuestionPublic = connect(mapStateToProps, null)(ConnectedQuestionPublic);

QuestionPublic.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuestionPublic);