import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';
import { sortLabel, sortStatement } from "../core/helpers/tools.helper";

import ButtonRounded from '../shared/components/button-rounded.component';
import Dialog from '../shared/components/dialog.component';
import { InputAutoComplete } from '../shared/components/form/input-autocomplete.component';
import InputSelect from '../shared/components/form/input-select.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import InputSelectInline from '../shared/components/form/input-select-inline.component';
import Question from './components/question.component';
import { QuestionExtend } from './components/question-extend.component';
import { initializeQuiz } from '../store/actions/question-create.action';
import { setLastPage } from '../store/actions/app.action';
import { setQuestionFilter, setQuizValue, setThemeValue } from '../store/actions/questions.action';
import { showError, showWarning, showSuccess } from '../store/actions/message.action';
import Config from '../config';

class ConnectedQuestionsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            availableQuizzes: [],
            filtredQuestions: [],
            dialogDelete: false,
            dialogRestore: false,
            dialogQuestion: [],
            questions: [],
            quizzes: [],
            displayExtendButton: true,
            displayNumber: Config.question.EXTEND,
            questionFilter: {
                statement: '',
                quizzes: [],
                themes: [],
                active: 'active'
            },
            companyId: '',
            quizFilter: [],
            quizValue: '',
            oldQuizValue: '',
            themeFilter: [],
            themeValue: '',
            oldThemeValue: '',
            themes: [],
            activeTypes: [],
            active: 'active',
            filterDisabled: ''
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChangeQuizzes = this.handleChangeQuizzes.bind(this);

        this.handleDeleteQuestionOnHide = this.handleDeleteQuestionOnHide.bind(this);
        this.handleDeleteQuestionOnSubmit = this.handleDeleteQuestionOnSubmit.bind(this);
        this.handleRestoreQuestionOnHide = this.handleRestoreQuestionOnHide.bind(this)
        this.handleRestoreQuestionOnSubmit = this.handleRestoreQuestionOnSubmit.bind(this);

        this.handleExtend = this.handleExtend.bind(this);
        this.handleFindQuestions = this.handleFindQuestions.bind(this);

        this.handleFilterQuestions = this.handleFilterQuestions.bind(this);
        this.handleFilterQuizzes = this.handleFilterQuizzes.bind(this);
        this.handleFilterThemes = this.handleFilterThemes.bind(this);
        this.handleChangeActive = this.handleChangeActive.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        const questionFilter = this.props.store.questions.questionFilter;
        const quizValue = this.props.store.questions.quizValue;
        const themeValue = this.props.store.questions.themeValue;

        let companyId = '';
        let filtredQuestions = [];
        let questions = [];
        let quizFilter = [];
        let quizzes = [];
        let themes = [];
        let themeFilter = [];

        if (id) {
            companyId = id;
        } else {
            companyId = this.props.store.company.id;
        }

        let activeTypes = Config.question.TYPE.map(item => {
            return { ...item, label: this.props.intl.formatMessage({ id: item.label }) };
        });

        questions = await this.getQuestions(companyId, questionFilter);

        if (questionFilter.statement !== '' || questionFilter.quizzes.length !== 0 || questionFilter.themes.length !== 0 || questionFilter.active !== 'all') {
            await this.handleFilterQuestions(questionFilter, questions);
            filtredQuestions = this.state.filtredQuestions;
        } else {
            filtredQuestions = questions;
        }

        const responseQuizzes = await http.get(`/manager/quizzes/company/${companyId}`);
        if (!responseQuizzes.ok) {
            this.props.showError(responseQuizzes.error);
        } else {
            quizzes = responseQuizzes.data.quizzes;
        }

        // Get list of available themes of company and filter question
        themes = await this.getThemes(companyId);
        if (themes.length > 0) {
            themeFilter = themes.map(item => {
                return { label: item.title, value: item.id };
            });
        }

        if (themeValue && themeValue !== 'all' && themeValue !== 'none') {
            const index = await themes.map(function (e) { return e.id; }).indexOf(parseInt(themeValue, 10));
            const quizzes = themes[index].quizzes;
            quizFilter = quizzes.map(item => {
                return { label: item.title, value: item.id };
            });
        } else if (themeValue === 'none') {
            quizFilter = [];
        } else {
            quizFilter = quizzes.map(item => {
                return { label: item.title, value: item.id };
            });
        };

        themeFilter.sort(sortLabel);
        quizFilter.sort(sortLabel);
        filtredQuestions.sort(sortStatement);

        this.setState({companyId, questions, filtredQuestions, themes, themeFilter,
            questionFilter, quizFilter, quizzes, themeValue, quizValue, activeTypes,
        });
    }

    async getQuestions(companyId, questionFilter = []) {
        let questions = [];

        let filter = '';
        if (questionFilter.quizzes.length > 0) {
            filter = `?quiz=${questionFilter.quizzes.toString()}`;
        } else if (questionFilter.themes.length > 0) {
            filter = `?theme=${questionFilter.themes.toString()}`;
        }
        
        if(questionFilter.active !== ''){
            let startFilter = (filter !== '') ? '&' : '?'
            filter = filter + `${startFilter}status=${questionFilter.active}`;
        }

        const response = await http.get(`/manager/questions/company/${companyId}${filter}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            questions = response.data.questions;
        }

        questions.sort(function (a, b) {
            if (a.statement < b.statement)
                return -1;
            if (a.statement > b.statement)
                return 1;
            return 0;
        });

        return questions;
    }

    async getThemes(companyId) {
        let themes = [];

        const response = await http.get(`/manager/players/${this.props.store.player.id}/companies/${companyId}/themes`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            themes = response.data.themes;
        }

        return themes;
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.ADD:
                this.props.initializeQuiz();
                this.props.setLastPage('/questions');
                history.push('/questions/new');
                break;
            case Config.action.MODIFY:
                this.props.setLastPage('/questions');
                history.push('/questions/modify/' + data.id);
                break;
            case Config.action.DELETE:
                this.setState({ dialogDelete: true, dialogQuestion: data });
                break;
            case Config.action.RESTORE:
                this.setState({ dialogRestore: true, dialogQuestion: data });
                break;
            default:
        }
    }

    /**
      * Change quizzes
      * 
      * @param {*} event 
      */
    async handleChangeQuizzes(event) {
        let filtredQuestions = await this.state.questions.filter((question) => {
            let value = '';
            if (event.value.title) {
                value = event.value.title;
            } else {
                value = event.value;
            }
            return question.quizzes.toLowerCase().includes(value.toLowerCase());
        });

        filtredQuestions.sort(sortStatement);
        this.setState({ filtredQuestions });
    }

    /**
     * Hide question delete
     */
    handleDeleteQuestionOnHide() {
        this.setState({ dialogDelete: false, dialogQuestion: [] });
    }

    /**
     * Hide question restore
     */
    handleRestoreQuestionOnHide(){
        this.setState({ dialogRestore: false, dialogQuestion: [] });
    }

    /**
     * Submit question delete
     */
    async handleDeleteQuestionOnSubmit() {

        const data = { question: { deleted:  true} };
        const response = await http.patch(`/manager/questions/${this.state.dialogQuestion.id}`, data);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            this.props.showSuccess({ summary: 'app.question.deleted' });
            const questions = await this.getQuestions(this.state.companyId, this.state.questionFilter);
            this.handleFilterQuestions(this.state.questionFilter, questions);
            this.setState({ questions: questions});
        }

        this.setState({ dialogDelete: false, dialogQuestion: [] });
    }

    /**
     * Submit question restore
     */
    async handleRestoreQuestionOnSubmit(){
        const data = { question: { deleted: false } };
        const response = await http.patch(`/manager/questions/${this.state.dialogQuestion.id}`, data);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            this.props.showSuccess({ summary: 'app.question.restored' });
            const questions = await this.getQuestions(this.state.companyId, this.state.questionFilter);
            this.handleFilterQuestions(this.state.questionFilter, questions);
            this.setState({ questions: questions});
        }
        this.setState({ dialogRestore: false, dialogQuestion: [] });
    }

    /**
     * Extend question list
     * 
     * @param {*} event 
     */
    handleExtend(event) {
        let displayExtendButton = this.state.displayExtendButton;
        let displayNumber = this.state.displayNumber + 25;
        if (displayNumber > this.state.filtredQuestions.length) {
            displayExtendButton = false;
            displayNumber = this.state.filtredQuestions.length;
        }
        this.setState({ displayExtendButton: displayExtendButton, displayNumber: displayNumber });
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} name
     * @param {*} value 
     */
    async handleFilterQuestions(questionFilter, questions) {
        this.props.setQuestionFilter(questionFilter);
        var filtredQuestions = await questions.filter((question) => {
            return question.statement.toLowerCase().includes(questionFilter.statement.toLowerCase());
        });

        filtredQuestions.sort(sortStatement);
        this.setState({ filtredQuestions, questionFilter });
    }

    /**
     * Filter available quizzes
     * 
     * @param {*} event 
     */
    async handleFilterQuizzes(event) {
        const quizValue = event.target.value;
        let questionFilter = [];
        let questions = [];

        this.props.setQuizValue(quizValue);

        questionFilter = this.state.questionFilter;

        if (quizValue !== 'all') {
            questionFilter.quizzes = [quizValue];
        } else {
            questionFilter.quizzes = [];
        }
        questions = await this.getQuestions(this.state.companyId, questionFilter);

        this.setState({ quizValue: quizValue, questions: questions });
        this.handleFilterQuestions(questionFilter, questions);
    }

    /**
     * Filter available themes
     * Change available quizzes
     * 
     * @param {*} event 
     */
    async handleFilterThemes(event) {
        const themeValue = event.target.value;
        let questionFilter = [];
        let questions = [];
        let quizFilter = [];

        this.props.setThemeValue(themeValue);
        this.props.setQuizValue('all');

        questionFilter = this.state.questionFilter;
        questionFilter.themes = [themeValue];
        questionFilter.quizzes = [];

        questions = await this.getQuestions(this.state.companyId, questionFilter);

        if (themeValue !== 'all' && themeValue !== 'none') {
            const themes = this.state.themes;
            let index = await themes.map(function (e) { return e.id; }).indexOf(parseInt(themeValue, 10));
            const quizzes = themes[index].quizzes;
            quizFilter = quizzes.map(item => {
                return { label: item.title, value: item.id };
            });
        } else if (themeValue === 'all') {
            quizFilter = this.state.quizzes.map(item => {
                return { label: item.title, value: item.id };
            });
        } else if (themeValue === 'none') {
            quizFilter = [];
        };

        quizFilter.sort(sortLabel);

        this.setState({ quizFilter, themeValue, questions });
        this.handleFilterQuestions(questionFilter, questions);
    }

    /**
      * Find questions
      * 
      * @param {*} event 
      */
    async handleFindQuestions(event) {
        let questionFilter = this.state.questionFilter;
        questionFilter.statement = event.value;
        this.handleFilterQuestions(questionFilter, this.state.questions);
    }

    /**
     * After each field key press, update questions visible on screen
     */
    async handleChangeActive(event) {
        if (event.target.value) {
            const active = event.target.value;
            let requestQuestionFilter = this.state.questionFilter;
            let stateQuestionFilter = this.state.questionFilter;

            stateQuestionFilter.active = active;
            requestQuestionFilter.active = active;

            let newQuizValue = this.state.quizValue;
            let newThemeValue = this.state.themeValue;
            let oldThemeValue = this.state.oldThemeValue;
            let oldQuizValue = this.state.oldQuizValue;
            let disabled = this.state.filterDisabled;

            if (active === 'trash') {
                requestQuestionFilter = { ...this.state.questionFilter, themes: [], quizzez: [] };

                if (newQuizValue !== '') {
                    oldQuizValue = newQuizValue;
                    newQuizValue = '';
                }
                if (newThemeValue !== '') {
                    oldThemeValue = newThemeValue;
                    newThemeValue = '';
                }
                disabled = 'disabled';
            } else {
                if (oldQuizValue !== '') {
                    newQuizValue = oldQuizValue;
                    oldQuizValue = '';
                }

                if (oldThemeValue !== '') {
                    newThemeValue = oldThemeValue;
                    oldThemeValue = '';
                }
                disabled = '';
            }

            const questions = await this.getQuestions(this.state.companyId, requestQuestionFilter);

            this.handleFilterQuestions(stateQuestionFilter, questions);
            this.setState({ active: active, questions: questions, themeValue: newThemeValue, 
                            oldThemeValue: oldThemeValue, quizValue: newQuizValue, 
                            oldQuizValue: oldQuizValue, filterDisabled: disabled });
        }
    }

    render() {
        const styleHeaderContainer = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        };

        const styleHeaderSelection = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            flexWrap: 'wrap'
        };

        const styleHeaderResult = {
            marginBottom: '.8em'
        };

        const styleHeaderActions = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        };

        const styleHeaderActive = {
            marginLeft: '1rem',
            marginBottom: '.5rem'
        }

        const pageHeader = (
            <div style={styleHeaderContainer}>
                <div style={styleHeaderSelection}>
                    <InputSelect name="theme" label="app.theme" onChange={this.handleFilterThemes} all={true} none={true}
                        options={this.state.themeFilter} value={this.state.themeValue} disabled={this.state.filterDisabled} />

                    <InputSelect name="quiz" label="app.quiz" onChange={this.handleFilterQuizzes} all={true} none={true}
                        options={this.state.quizFilter} value={this.state.quizValue} disabled={this.state.filterDisabled} />

                    <InputAutoComplete field="statement" label="app.find" size="40"
                        onChange={this.handleFindQuestions} value={this.state.questionFilter.statement} />

                    <div style={styleHeaderResult}>{this.state.filtredQuestions.length} {this.props.intl.formatMessage({ id: 'app.result' })}</div>

                    <div style={styleHeaderActive}>
                        <InputSelectInline name="active" options={this.state.activeTypes} value={this.state.active}
                            onChange={this.handleChangeActive} />
                    </div>
                </div>
                <div style={styleHeaderActions}>
                    <div style={{ marginRight: '.5em' }}>
                        <ButtonRounded label="app.create.question" onClick={() => this.handleAction(Config.action.ADD)}
                            styles={this.props.store.style} kind="expandedPrimary"
                            disabled={this.state.companyId !== this.props.store.company.id} />
                    </div>
                </div>
            </div>
        );

        const dynamicQuestions = this.state.filtredQuestions.map((question, index) => {
            return <Question key={question.id} question={question} onClick={this.handleAction} styles={this.props.store.style} kind={question.deleted ? "closed": "question"} deleted={question.deleted} />
        });

        return (
            <Page header={pageHeader} >
                <PageCard>
                    {dynamicQuestions.slice(0, this.state.displayNumber + 1)}
                    {this.state.displayExtendButton && this.state.filtredQuestions.length > Config.question.EXTEND && (<QuestionExtend styles={this.props.store.style} onClick={this.handleExtend} />)}
                </PageCard>
                <Dialog visible={this.state.dialogDelete}
                    text={this.props.intl.formatMessage({ id: 'app.dialog.delete.question' })}
                    onHide={this.handleDeleteQuestionOnHide} onSubmit={this.handleDeleteQuestionOnSubmit} />

                <Dialog visible={this.state.dialogRestore}
                    text={this.props.intl.formatMessage({ id: 'app.dialog.restore.question' })}
                    onHide={this.handleRestoreQuestionOnHide} onSubmit={this.handleRestoreQuestionOnSubmit}/>
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            player: state.player,
            questions: state.questions,
            style: state.style
        }
    };
};

const mapDispatchToProps = {
    initializeQuiz,
    setLastPage,
    setQuestionFilter, setQuizValue, setThemeValue,
    showError, showWarning, showSuccess
};

const QuestionsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestionsPage);

QuestionsPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuestionsPage);
