import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { InputText } from '../../shared/components/form/input-text.component';
import { InputTextArea } from '../../shared/components/form/input-text-area.component';
import { Panel } from 'primereact/panel';
import Config from '../../config';

class ThemeDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dirty: false,
            theme: []
        };

        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            theme: nextProps.theme
        };
    }

    handleChangeDescription(event) {
        let theme = this.state.theme;
        theme.description = { ...theme.description, [this.props.lang]: event.target.value };
        this.setState({ theme: theme });
        this.props.onChange(theme);
    }

    handleChangeTitle(event) {
        let theme = this.state.theme;
        theme.title = { ...theme.title, [this.props.lang]: event.target.value };
        this.setState({ theme: theme });
        this.props.onChange(theme);
    }

    render() {
        const lang = this.props.lang;
        const { title, description } = this.state.theme;

        const stylePanel = {
            marginBottom: '2em'
        }

        return (
            <Panel header={this.props.intl.formatMessage({ id: Config.locales.LABELS[lang] })}
                style={stylePanel} toggleable={true}>
                <div className="row">
                    <div className="col-6">
                        <InputText name="title" label="app.title"
                            value={title ? title[lang] : ''}
                            onChange={this.handleChangeTitle} submitted={this.props.submitted} mandatory="true" />
                    </div>
                    <div className="col-6">
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <InputTextArea name="description" label="app.description"
                            value={description ? description[lang] : ''}
                            onChange={this.handleChangeDescription} submitted={this.props.submitted} />
                    </div>
                </div>
            </Panel>
        );
    }
}

ThemeDetail.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ThemeDetail);