import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';

import { ButtonRoundedIcon } from './button-rounded-icon.component';
import { Dialog as DialogBox } from 'primereact/dialog';

class ConnectedDialog extends Component {

    render() {

        const styleDialog = {
            backgroundColor: '#f2f2f2',
            textAlign: 'center',
            padding: '2em 5em 1em 5em',
            width: '40em !important'
        }

        const styleButtons = {
            marginTop: '1em'
        }

        return (
            <DialogBox showHeader={false} visible={this.props.visible}
                modal={true} onHide={this.props.onHide} style={styleDialog}>
                <div>{this.props.text}</div>
                <div style={styleButtons}>
                    <ButtonRoundedIcon icon="fa fa-times" onClick={this.props.onHide} styles={this.props.store.style} kind="secondary" />
                    <ButtonRoundedIcon icon="fa fa-check" onClick={this.props.onSubmit}  styles={this.props.store.style} kind="primary" />
                </div>
            </DialogBox>
        );
    }
}

ConnectedDialog = Radium(ConnectedDialog);

const mapStateToProps = state => {
    return { 
        store: {
            style: state.style
        }
    };
};

const Dialog = connect(mapStateToProps, null)(ConnectedDialog);

export default Dialog;