import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Button } from '../shared/components/button.component';
import ButtonRounded from '../shared/components/button-rounded.component';
import Dialog from '../shared/components/dialog.component';
import { Form } from '../shared/components/form/form.component';
import { InputAutoComplete } from '../shared/components/form/input-autocomplete.component';
import { InputText } from '../shared/components/form/input-text.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { PickList } from 'primereact/picklist';
import { setLastPage } from '../store/actions/app.action';
import { setFilterName } from '../store/actions/department-modify.action';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';

class ConnectedDepartmentModifyPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            submitDisable: false,
            availableUsers: [],
            department: {
                id: '',
                type: '',
                name: '',
                reference: ''
            },
            dialog: false,
            selectedUsers: [],
            users: [],
            user: null
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeUsers = this.handleChangeUsers.bind(this);
        this.handleDeleteDepartmentOnHide = this.handleDeleteDepartmentOnHide.bind(this);
        this.handleDeleteDepartmentOnSubmit = this.handleDeleteDepartmentOnSubmit.bind(this);
        this.handleOnClickNewUser = this.handleOnClickNewUser.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.pickListOnChange = this.pickListOnChange.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        let users = [];
        let department = [];
        let selectedUsers = [];

        // Get department
        const responseDepartment = await http.get(`/manager/departments/${id}`);
        if (!responseDepartment.ok) {
            this.props.showError(responseDepartment.error);
        } else {
            department = responseDepartment.data.department;
            selectedUsers = responseDepartment.data.managers;
        }

        // Get company users
        const responseUsers = await http.get('/manager/companies/' + this.props.store.company.id + '/users');
        if (!responseUsers.ok) {
            this.props.showError(responseUsers.error);
        } else {
            users = responseUsers.data.users;
        }

        // 
        let availableUsers = [];
        if (this.props.store.page.filterName || selectedUsers.length !== 0) {
            availableUsers = await users.filter((user) => {
                const index = selectedUsers.map(function (e) { return e.id; }).indexOf(parseInt(user.id, 10));
                const value = this.props.store.page.filterName;
                const name = user.firstname.toLowerCase() + user.lastname.toLowerCase();
                if (value) {
                    return name.includes(value.toLowerCase()) && index === -1;
                } else {
                    return index === -1;
                }
            });
        } else {
            availableUsers = users;
        }

        this.setState({
            department: department,
            availableUsers: availableUsers,
            selectedUsers: selectedUsers,
            users: users,
            user: this.props.store.page.filterName
        });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push('/departments');
                break;
            case Config.action.DELETE:
                this.setState({ dialog: true });
                break;
            default:
        }
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChange(event) {
        const { name, value } = event.target;
        let department = this.state.department;
        department[name] = value;
        this.setState({ department: department });
    }

    /**
      * Change users
      * 
      * @param {*} event 
      */
    async handleChangeUsers(event) {
        const value = event.value;
        const results = await this.state.users.filter((user) => {
            const index = this.state.selectedUsers.map(function (e) { return e.id; }).indexOf(parseInt(user.id, 10));
            const name = user.firstname.toLowerCase() + user.lastname.toLowerCase();
            return name.includes(value.toLowerCase()) && index === -1;
        });
        this.setState({ availableUsers: results, user: value });
        this.props.setFilterName(value);
    }

    /**
     * Hide quiz delete
     */
    handleDeleteDepartmentOnHide() {
        this.setState({ dialog: false });
    }

    /**
     * Submit quiz delete
     */
    async handleDeleteDepartmentOnSubmit() {
        const response = await http.del(`/manager/departments/${this.state.department.id}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            this.handleAction(Config.action.BACK);
        }
        this.setState({ dialog: false });
    }

    /**
     * New user
     * 
     * @param {*} event 
     */
    handleOnClickNewUser(event) {
        this.props.setLastPage(`/departments/modify/${this.state.department.id}`);
        history.push(`/users/new`);
    }

    /**
     * Submit department
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, submitDisable: true });
        const { department, selectedUsers } = this.state;

        if (department.name && department.reference) {
            const data = {
                department: department,
                managers: selectedUsers
            }
            const response = await http.put(`/manager/departments/${department.id}`, data);
            if (!response.ok) {
                this.props.showError(response.error, ['app.department.not.updated']);
            } else {
                this.props.showSuccess({ summary: 'app.department.updated' });
                this.setState({ department: response.data.department });
            }
        }

        this.setState({ submitDisable: false });
    }

    /**
     * Event called when a user move to another column
     * 
     * @param {*} event 
     */
    pickListOnChange(event) {
        this.setState({
            availableUsers: event.source,
            selectedUsers: event.target
        });
    }

    /**
     * Questions template of pick list column
     * 
     * @param {*} user 
     */
    pickListUserTemplate(user) {
        return (
            <div>{user.firstname} {user.lastname}</div>
        );
    }

    render() {
        const intl = this.props.intl;
        const { submitted, submitDisable, department, user } = this.state;

        const styleButtonActions = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        };

        const stylePickListHeader = {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '.3em'
        };

        const stylePickListHeaderLeft = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '48%'
        };

        const stylePickListHeaderCenter = {
            width: '4%'
        }

        const stylePickListHeaderRight = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '48%'
        }

        const stylePickListFilter = {
            ...stylePickListHeader
        };

        const stylePickListFilterLeft = {
            ...stylePickListHeaderLeft,
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
        };

        const stylePickListFilterCenter = {
            ...stylePickListHeaderCenter
        }

        const stylePickListFilterRight = {
            ...stylePickListHeaderRight,
            justifyContent: 'flex-end',
            flexWrap: 'wrap'
        }

        return (
            <Page>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.add.administrators' })} onClick={this.handleAction} back={Config.action.BACK} >
                    <Form onSubmit={this.handleSubmit}>

                        <InputText name="name" label="app.department.name" value={department.name}
                            onChange={this.handleChange} submitted={submitted} mandatory="true" />

                        <InputText name="reference" label="app.department.reference" value={department.reference}
                            onChange={this.handleChange} submitted={submitted} mandatory="true" maxLength="6" />

                        <div style={stylePickListFilter}>
                            <div style={stylePickListFilterLeft}>
                                <InputAutoComplete field="user" label="app.find.user" value={user}
                                    onChange={this.handleChangeUsers} />
                            </div>
                            <div style={stylePickListFilterCenter}></div>
                            <div style={stylePickListFilterRight}>
                                <ButtonRounded label="app.user.new" onClick={this.handleOnClickNewUser}
                                    styles={this.props.store.style} kind="secondary" />
                            </div>
                        </div>

                        <div className="opp-picklist">
                            <PickList source={this.state.availableUsers} target={this.state.selectedUsers}
                                itemTemplate={this.pickListUserTemplate} onChange={this.pickListOnChange}
                                sourceStyle={{ 'height': '30em' }} targetStyle={{ 'height': '30em' }}
                                showSourceControls={false} showTargetControls={false} responsive={true}
                                sourceHeader={intl.formatMessage({ id: 'app.users.availables' })}
                                targetHeader={intl.formatMessage({ id: 'app.users.selected' })} />
                        </div>

                        <div style={styleButtonActions}>
                            <div style={{ marginRight: '.5em' }}>
                                <Button label="app.delete" onClick={() => this.handleAction(Config.action.DELETE)}
                                    styles={this.props.store.style} kind="secondary" disabled={submitDisable ? submitDisable : false} />
                            </div>
                            <div>
                                <Button label="app.save" onClick={this.handleSubmit}
                                    styles={this.props.store.style} kind="primary" disabled={submitDisable ? submitDisable : false} />
                            </div>
                        </div>

                    </Form>
                    <Dialog visible={this.state.dialog}
                        text={intl.formatMessage({ id: 'app.dialog.delete.department' })}
                        onHide={this.handleDeleteDepartmentOnHide} onSubmit={this.handleDeleteDepartmentOnSubmit} />
                </PageCard>
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            app: state.app,
            company: state.company,
            page: state.departmentModify,
            style: state.style
        }
    };
};

const mapDispatchToProps = {
    setLastPage,
    setFilterName,
    showError, showSuccess
};

const DepartmentModifyPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedDepartmentModifyPage);

DepartmentModifyPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(DepartmentModifyPage);
