export const DEPARTMENT_CREATE_ADD_SELECTED_USER = '[Department create] Add selected users';
export const DEPARTMENT_CREATE_INITIALIZE = '[Department create] Initialize';
export const DEPARTMENT_CREATE_SET_FILTER_NAME = '[Department create] Set filter name';
export const DEPARTMENT_CREATE_SET_FILTRED_USERS = '[Department create] Set filtred users';
export const DEPARTMENT_CREATE_SET_SELECTED_USERS = '[Department create] Set selected users';
export const DEPARTMENT_CREATE_SET_NAME = '[Department create] Set name';
export const DEPARTMENT_CREATE_SET_REFERENCE = '[Department create] Set reference';

export const initialState = {
    name: '',
    reference: '',
    filterName: '',
    filtredUsers: [],
    selectedUsers: []
}

export const addSelectedUser = selectedUser => {
    return { type: DEPARTMENT_CREATE_ADD_SELECTED_USER, selectedUser: selectedUser }
}

export const initializeDepartmentCreate = () => {
    return { type: DEPARTMENT_CREATE_INITIALIZE, departmentCreate: initialState }
}

export const setFilterName = name => {
    return { type: DEPARTMENT_CREATE_SET_FILTER_NAME, filterName: name }
}

export const setFiltredUsers = filtredUsers => {
    return { type: DEPARTMENT_CREATE_SET_FILTRED_USERS, filtredUsers: filtredUsers }
}

export const setSelectedUsers = selectedUsers => {
    return { type: DEPARTMENT_CREATE_SET_SELECTED_USERS, selectedUsers: selectedUsers }
}

export const setName = name => {
    return { type: DEPARTMENT_CREATE_SET_NAME, name: name }
}

export const setReference = reference => {
    return { type: DEPARTMENT_CREATE_SET_REFERENCE, reference: reference }
}