export const QUESTIONS_SET_QUESTION_FILTER = '[Questions] Set question filter';
export const QUESTIONS_SET_QUIZ_VALUE = '[Questions] Set quiz value';
export const QUESTIONS_SET_THEME_VALUE = '[Questions] Set theme value';

export const initialState = {
    questionFilter: {
        statement: '',
        quizzes: [],
        themes: [],
        active: 'active'
    },
    quizValue: '',
    themeValue: ''
}

export const setQuestionFilter = filter => {
    return { type: QUESTIONS_SET_QUESTION_FILTER, questionFilter: filter }
}

export const setQuizValue = value => {
    return { type: QUESTIONS_SET_QUIZ_VALUE, quizValue: value }
}

export const setThemeValue = value => {
    return { type: QUESTIONS_SET_THEME_VALUE, themeValue: value }
}
