import React from 'react';
import { IntlMessage } from '../intl-message.component';
import Config from '../../../config';

export const InputTextArea = React.forwardRef((props, ref) => (
    <div className="form-group">
        <label><IntlMessage id={props.label} /></label>
        <textarea ref={ref} type="text" className="form-control" id={props.name}
            name={props.name} value={props.value} placeholder={props.placeholder}
            maxLength={props.maxLength ? props.maxLength : Config.inputText.DEFAULT_MAX_LENGTH}
            onChange={props.onChange.bind(this)}
            rows={props.rows ? props.rows : '6'}
            disabled={props.disabled ? props.disabled : false} ></textarea>
        {props.submitted && props.mandatory && !props.value &&
            <div className="help-block">*Ce champ est obligatoire</div>
        }
    </div>
));
