import React, { Component } from "react";
import InputSelectInline from './form/input-select-inline.component';

export class DepartmentRoleList extends Component {

    render() {
        const dynamicDepartmentsRoleSelect = this.props.availableDepartments.map((item, i) => {
            const index = this.props.departments.map(function (e) { return e.id; }).indexOf(parseInt(item.id, 10));
            return <div key={item.value} className="opp-department-role-item">
                        <InputSelectInline name={item.value.toString()} label={item.label} noIntlLabel={true}
                            options={this.props.departmentRoleOptions} value={this.props.departments[index].value}
                            onChange={(e) => this.props.onChange(item.id, e.value)} />
                </div>
        });

        return (
            <div className="opp-department-role-list">{ dynamicDepartmentsRoleSelect }</div>
        );
    }
}

export default DepartmentRoleList;