import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';

class ConnectedUserBox extends Component {

    render() {
        const styleContainer = {
            base: {
                display: 'inline-block',
                textAlign: 'center',
                width: '9em'
            }
        };

        const styleEdit = {
            base: {
                position: 'absolute'
            }
        };

        const styleEditButton = {
            base: {
                display: 'flex',
                width: '1rem',
                height: '1rem',
                color: '#ffffff'
            }
        };

        const styleEditIcon = {
            base: {
                margin: 'auto',
                fontSize: '.5em',
                color: '#ffffff'
            }
        };

        const styleImage = {
            base: {
                backgroundImage: this.props.user.picture ? `url('${process.env.REACT_APP_IMAGES_DIRECTORY + this.props.user.picture}')` : '',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderRadius: '.2em',
                height: '100%',
                width: '100%'
            }
        };

        const styleName = {
            base: {
                fontSize: '.7em'
            }
        };

        const styleNoPictureContainer = {
            base: {
                display: 'flex',
                background: '#3a3f51',
                height: '100%'
            }
        };

        const styleNoPicture = {
            base: {
                margin: 'auto',
                background: '#3a3f51',
                fontSize: '3em'
            }
        };

        const stylePictureContainer = {
            base: {
                borderRadius: '.3em',
                border: 'solid 1px #3a3f51',
                color: '#dedede',
                height: '8em',
                margin: '.5em',
                verticalAlign: 'top',
                width: '8em'
            }
        };

        return (
            <div style={[styleContainer.base]}>
                {this.props.edit && (<div style={[styleEdit.base]}><button type="button" className="btn btn-rounded opp-btn-danger btn-icon" style={[styleEditButton.base]}
                    onClick={() => this.props.onClick(this.props.user, this.props.department)}><i className="fa fa-times menu-icon" style={[styleEditIcon.base]}></i></button></div>)}
                <div style={[stylePictureContainer.base]}>
                    {this.props.user.picture  && (<div style={[styleImage.base]}>&nbsp;</div>)}
                    {!this.props.user.picture && (<div style={[styleNoPictureContainer.base]}>
                            <div style={[styleNoPicture.base]}>{this.props.user.firstname.substring(0,1).toUpperCase()}{this.props.user.lastname.substring(0,1).toUpperCase()}</div>
                        </div>)}
                </div>
                <div style={[styleName.base]}>{ this.props.user.firstname } { this.props.user.lastname }</div>
            </div>
        )
    }
}

ConnectedUserBox = Radium(ConnectedUserBox);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const UserBox = connect(mapStateToProps, null)(ConnectedUserBox);

export default UserBox;
