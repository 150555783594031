import {
    initialState,
    NOTIFICATION_INITIALIZE_USERS,
    NOTIFICATION_SET_USERS
 } from '../actions/notification.action';

const notification = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_INITIALIZE_USERS:
                return { ...state, users: action.users };
        case NOTIFICATION_SET_USERS:
            return { ...state, users: action.users };
        default:
            return state
    }
}

export default notification;