import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';

class ConnectedUserIcon extends Component {
    render() {
        const styleContainer = {
            base: {
                display: 'inline-block',
                verticalAlign: 'top',
                margin: '.5em'
            }
        }

        const styleImage = {
            base: {
                backgroundImage: this.props.user.picture ? `url('${process.env.REACT_APP_IMAGES_DIRECTORY + this.props.user.picture}')` : '',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderRadius: '50%',
                width: '92px',
                height: '92px'
            }
        };

        const styleNoPictureContainer = {
            base: {
                background: '#ffffff',
                textAlign: 'center',
                fontSize: '3em',
                paddingTop: '.3em'
            }
        };

        return (
            <div style={[styleContainer.base]}>
                {this.props.user.picture && (<div><div style={[styleImage.base]}></div></div>)}
                {!this.props.user.picture && (<div className="img-lg rounded-circle" style={[styleNoPictureContainer.base]}>{this.props.user.firstname.substring(0, 1).toUpperCase()}{this.props.user.lastname.substring(0, 1).toUpperCase()}</div>)}
            </div>
        )
    }
}

ConnectedUserIcon = Radium(ConnectedUserIcon);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const UserIcon = connect(mapStateToProps, null)(ConnectedUserIcon);

export default UserIcon;