import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Form } from '../shared/components/form/form.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { showError, showSuccess } from '../store/actions/message.action';
import StaticText from '../shared/components/form/static-text.component';
import Config from '../config';

class ConnectedQuestionDeletePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            id: '',
            statement: '',
            answer1: '',
            answer2: '',
            answer3: '',
            answer4: '',
            correct: ''
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ id: id });

        http.get(`/manager/questions/${id}`)
            .then(response => {
                if (response.ok) {
                    const question = response.data.question;
                    if (question.statement) { this.setState({ statement: question.statement }) };
                    if (question.answer1) { this.setState({ answer1: question.answer1 }) };
                    if (question.answer2) { this.setState({ answer2: question.answer2 }) };
                    if (question.answer3) { this.setState({ answer3: question.answer3 }) };
                    if (question.answer4) { this.setState({ answer4: question.answer4 }) };
                    if (question.correct) { this.setState({ correct: question.correct }) };
                }
            });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push('/questions');
                break;
            default:
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        const { id } = this.state;
        const response = await http.del(`/manager/questions/${id}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            this.props.showSuccess({ summary: 'app.question.deleted' });
            history.push('/questions');
        }
    }

    render() {
        const intl = this.props.intl;
        const { statement, answer1, answer2, answer3, answer4, correct } = this.state;

        return (
            <Page>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.delete.question' })}
                    onClick={this.handleAction} back={Config.action.BACK}>
                    <Form onSubmit={this.handleSubmit}>
                        <StaticText label="app.statement" value={statement} />
                        <StaticText label="app.answer1" value={answer1} />
                        <StaticText label="app.answer2" value={answer2} />
                        <StaticText label="app.answer3" value={answer3} />
                        <StaticText label="app.answer4" value={answer4} />
                        <StaticText label="app.correct.answer" value={correct} />
                        <button type="submit" className="btn btn-primary mr-2">{intl.formatMessage({ id: 'app.delete' })}</button>
                    </Form>
                </PageCard>
            </Page>
        );
    }
}

const mapDispatchToProps = {
    showError, showSuccess
};

const QuestionDeletePage = connect(null, mapDispatchToProps)(ConnectedQuestionDeletePage);

QuestionDeletePage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuestionDeletePage);
