import {
    initialState,
    QUESTION_CREATE_INITIALIZE_QUIZ,
    QUESTION_CREATE_SET_QUIZ
} from '../actions/question-create.action';

const questionCreate = (state = initialState, action) => {
    switch (action.type) {
        case QUESTION_CREATE_INITIALIZE_QUIZ:
        return { ...state, quiz: initialState.quiz };
        case QUESTION_CREATE_SET_QUIZ:
            return { ...state, quiz: action.quiz };
        default:
            return state
    }
}

export default questionCreate;