import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { InputText } from '../../shared/components/form/input-text.component';
import { InputTextArea } from '../../shared/components/form/input-text-area.component';
import { Panel } from 'primereact/panel';
import { Answer } from './answer.component';
import Config from '../../config';
import { InputMarkdown } from '../../shared/components/form/input-markdown.component';

class QuestionDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            question: []
        };

        this.handleChangeAnswer = this.handleChangeAnswer.bind(this);
        this.handleChangeComment = this.handleChangeComment.bind(this);
        this.handleChangeStatement = this.handleChangeStatement.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            question: nextProps.question
        };
    }

    handleChangeAnswer(name, value) {
        let question = this.state.question;
        question[name] = { ...question[name], [this.props.lang]: value };
        this.setState({ question: question });
        this.props.onChange(question);
    }

    handleChangeComment(eventOrString) {
        let value = typeof eventOrString === 'string' ? eventOrString : eventOrString.target.value;

        let question = this.state.question;
        question.comment = { ...question.comment, [this.props.lang]: value };
        this.setState({ question: question });
        this.props.onChange(question);
    }

    handleChangeStatement(event) {
        let question = this.state.question;
        question.statement = { ...question.statement, [this.props.lang]: event.target.value };
        this.setState({ question: question });
        this.props.onChange(question);
    }

    handleClick(name) {
        let question = this.state.question;
        switch (name) {
            case 'answer1':
                question.correct = 1;
                break;
            case 'answer2':
                question.correct = 2;
                break;
            case 'answer3':
                question.correct = 3;
                break;
            case 'answer4':
                question.correct = 4;
                break;
            default:
                question.correct = 0;
                break;
        }
        this.setState({ question: question });
        this.props.onChange(question);
    }

    render() {
        const lang = this.props.lang;
        const question = this.state.question;
        const correct = question ? question.correct : 0;

        const styleContainer = {
        };

        return (
            <Panel header={this.props.intl.formatMessage({ id: 'app.locale.' + this.props.lang })} style={{ marginTop: '2em', marginBottom: '2em' }} toggleable={true} collapsed={false}>
                <div style={styleContainer} >
                    <div className="row">
                        <div className="col-12">
                            <InputText name="statement" label="app.statement"
                                maxLength={Config.inputText.APP_STATEMENT_MAX_LENGTH} placeholder={Config.inputText.APP_STATEMENT_MAX_LENGTH}
                                value={question ? question.statement[lang] : ''}
                                onChange={this.handleChangeStatement} submitted={this.props.submitted} mandatory="true" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Answer name="answer1" label="app.answer1" answer={question ? question.answer1[lang] : ''} correct={(correct === 1) ? true : false}
                                active={question.correct === 1 ? true : false} submitted={this.props.submitted}
                                onChange={this.handleChangeAnswer} onClick={this.handleClick} styles={this.props.styles} />

                            <Answer name="answer3" label="app.answer3" answer={question ? question.answer3[lang] : ''} correct={(correct === 3) ? true : false}
                                active={question.correct === 3 ? true : false} submitted={this.props.submitted}
                                onChange={this.handleChangeAnswer} onClick={this.handleClick} styles={this.props.styles} />
                        </div>
                        <div className="col-6">
                            <Answer name="answer2" label="app.answer2" answer={question ? question.answer2[lang] : ''} correct={(correct === 2) ? true : false}
                                active={question.correct === 2 ? true : false} submitted={this.props.submitted}
                                onChange={this.handleChangeAnswer} onClick={this.handleClick} styles={this.props.styles} />

                            <Answer name="answer4" label="app.answer4" answer={question ? question.answer4[lang] : ''} correct={(correct === 4) ? true : false}
                                active={question.correct === 4 ? true : false} submitted={this.props.submitted}
                                onChange={this.handleChangeAnswer} onClick={this.handleClick} styles={this.props.styles} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <InputMarkdown name="comment" label="app.comment"
                                value={question.comment ? question.comment[lang] : ''} onChange={this.handleChangeComment} maxLength={this.props.maxLength} />
                        </div>
                    </div>
                </div>
            </Panel>
        );
    }
}

QuestionDetail.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuestionDetail);
