import {
    initialState,
    THEMES_SET_THEME_FILTER,
    THEMES_SET_DEPARTMENT_VALUE
} from '../actions/themes.action';

const themes = (state = initialState, action) => {
    switch (action.type) {
        case THEMES_SET_THEME_FILTER:
            return { ...state, themeFilter: action.themeFilter };
        case THEMES_SET_DEPARTMENT_VALUE:
            return { ...state, departmentValue: action.departmentValue };
        default:
            return state
    }
}

export default themes;