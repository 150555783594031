import {
    initialState,
    QUIZ_MANAGER_SET_DETAILS_COLLAPSED,
    QUIZ_MANAGER_SET_MODIFY_COLLAPSED,
    QUIZ_MANAGER_SET_QUESTIONS_COLLAPSED,
    QUIZ_MANAGER_SET_QUESTIONS_NUMBER,
    QUIZ_MANAGER_SET_STATISTICS_COLLAPSED
} from '../actions/quiz-manager.action';

const quizManager = (state = initialState, action) => {
    switch (action.type) {
        case QUIZ_MANAGER_SET_DETAILS_COLLAPSED:
            return { ...state, detailsCollapsed: action.detailsCollapsed };
        case QUIZ_MANAGER_SET_MODIFY_COLLAPSED:
            return { ...state, modifyCollapsed: action.modifyCollapsed };
        case QUIZ_MANAGER_SET_QUESTIONS_COLLAPSED:
            return { ...state, questionsCollapsed: action.questionsCollapsed };
        case QUIZ_MANAGER_SET_QUESTIONS_NUMBER:
            return { ...state, questionsNumber: action.questionsNumber };
        case QUIZ_MANAGER_SET_STATISTICS_COLLAPSED:
            return { ...state, statisticsCollapsed: action.statisticsCollapsed };
        default:
            return state
    }
}

export default quizManager;