import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';

class ConnectedCompanyAdmins extends Component {

    render() {
        const styleContainer = {
            base: {
                marginBottom: '3em'
            }
        }

        const styleCard = {
            base: {
                background: '#3a3f51'
            }
        }

        const styleCardBody = {
            base: {
                display: 'flex',
                flexDirection: 'row'
            }
        }

        return (
            <div style={[styleContainer.base]}>
                <div className="card" style={[styleCard.base]}>
                    <div className="card-body" style={[styleCardBody.base]}>{this.props.children}</div>
                </div>
            </div>
        )
    }
}

ConnectedCompanyAdmins = Radium(ConnectedCompanyAdmins);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const CompanyAdmins = connect(mapStateToProps, null)(ConnectedCompanyAdmins);

export default CompanyAdmins;