import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';
import Config from '../../config';

class ConnectedQuizPublic extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quiz: [],
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            quiz: nextProps.quiz
        };
    }

    render() {
        const quiz = this.state.quiz;
        const { store } = this.props;

        const styleContainer = {
            base: {
                background: 'transparent',
                border: `1px solid  ${store.style.color.shadow.base}`,
                borderRadius: '3px',
                color: '#000000',
                fontSize: '.8rem',
                margin: '0 1em 1em 0',
                padding: '0',
                width: '16rem',
                height: '12rem'
            }
        };

        const styleLocale = {
            marginRight: '.5rem'
        };

        const styleQuizInfos = {
            paddingRight:'.3rem',
            paddingLeft:'.3rem'
        };

        const stylePublicUsed = {
            float: 'right',
            fontSize: '1.5rem',
            color: store.style.color.primary.base,
        };

        const styleQuiz = {
            base: {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '9rem',
                zIndex: '20',
                ':hover': {
                    cursor: 'pointer'
                }
            }
        };

        const styleQuizText = {
            base: {
                fontWeight: 'bold',
                textAlign: 'center',
                zIndex: '30'
            },
            title: {
                fontSize: '1.8em'
            }
        };

        const styleQuizImage = {
            base: {
                backgroundImage: quiz.picture ? `url('${process.env.REACT_APP_IMAGES_DIRECTORY + quiz.picture}')` : '',
                backgroundSize: 'cover',
                borderRadius: '2px 2px 0 0',
                height: '9rem',
                opacity: '.5',
                position: 'absolute',
                width: '16rem',
                zIndex: '10',
                ':hover': {
                    cursor: 'pointer'
                }
            }
        };

        const locales = this.props.quiz.locales.split(',');
        const dynamicLocales = locales.map((item, index) => {
            return <i key={index} className={`flag-icon flag-icon-${Config.locales.ICON[item]}`} style={styleLocale} title={item} id={item}></i>
        });

        const themes = this.props.quiz.themes
        const dynamicThemes = themes.map((item, index) => {
            const title = item.title.length > 20 ? `${item.title.substring(0, 17)}...` : item.title;
            return <span key={index}>{index === 0 ? title : `, ${title}`}</span>;
        });

        return (
            <div style={[styleContainer.base]}>
                {(quiz.themes && quiz.themes.length > 0) && (<div style={stylePublicUsed}><i className="fa fa-check"></i></div>)}
                <div key="image" style={[styleQuizImage.base]} onClick={(e) => this.props.onClick(Config.action.QUIZ_PUBLIC, { id: this.props.quiz.id })}></div>
                <div key="title" style={[styleQuiz.base]} onClick={(e) => this.props.onClick(Config.action.QUIZ_PUBLIC, { id: this.props.quiz.id })}>
                    <div style={[styleQuizText.base, styleQuizText.title]}>{quiz.title}</div>
                    <div style={[styleQuizText.base]}>{quiz.questionsNumber} <i className="fa fa-question-circle"></i></div>
                </div>
                <div style={styleQuizInfos}>{dynamicLocales} {dynamicThemes}</div>
            </div >
        );
    }
}

ConnectedQuizPublic = Radium(ConnectedQuizPublic);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const QuizPublic = connect(mapStateToProps, null)(ConnectedQuizPublic);

QuizPublic.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizPublic);