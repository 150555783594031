import React, { Component } from 'react';
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { injectIntl, intlShape } from 'react-intl';
import { httpService as http } from "../core/services/http.service";

import { Button } from '../shared/components/button.component';
import Dialog from '../shared/components/dialog.component';
import { showError, showSuccess } from '../store/actions/message.action';
import { InputMultiSelect } from '../shared/components/form/input-multiselect.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import InputCheckbox from '../shared/components/form/input-checkbox.component';
import InputSwitch from '../shared/components/form/input-switch.component';
import ImageUpload from '../shared/components/form/image-upload.component';
import QuizDetail from './components/quiz-detail.component';
import Config from '../config';

class ConnectedQuizDetailsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            availableFilterThemes: [],
            availableQuizThemes: [],
            details: {},
            dialog: false,
            dirty: false,
            pictureFile: [],
            logoFile: [],
            themes: [],
            quiz: {
                id: '',
                title: [],
                description: [],
                picture: '',
                logo: '',
                published: false,
                public: false,
                locales: [Config.app.DEFAULT_LOCALE]
            },
            submitDisable: false,
            submitted: false,
            picture: null,
            logo: null,
        }

        this.handleAction = this.handleAction.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleChangeMultiSelect = this.handleChangeMultiSelect.bind(this);
        this.handleChangeQuiz = this.handleChangeQuiz.bind(this);
        this.handleChangeQuizPicture = this.handleChangeQuizPicture.bind(this);
        this.handleChangeQuizLogo = this.handleChangeQuizLogo.bind(this);
        this.handleChangeQuizPublic = this.handleChangeQuizPublic.bind(this);
        this.handleChangeQuizPublished = this.handleChangeQuizPublished.bind(this);
        this.handleClickPicture = this.handleClickPicture.bind(this);
        this.handleClickLogo = this.handleClickLogo.bind(this);
        this.handleDeleteQuizOnHide = this.handleDeleteQuizOnHide.bind(this);
        this.handleDeleteQuizOnSubmit = this.handleDeleteQuizOnSubmit.bind(this);
        this.handleQuizSubmit = this.handleQuizSubmit.bind(this);

    }

    async componentDidMount() {
        const { id } = this.props.params;
        let quiz = [];
        let themes = [];
        let quizThemes = [];
        let availableQuizThemes = [];

        // Get quiz entity
        const response = await http.get(`/manager/quizzes/${id}`);
        if (response.ok) {
            quiz = response.data.quiz;
            themes = response.data.themes.map(item => {
                return item.id;
            });
        }

        // Get list of available themes of company
        const responseThemes = await http.get(`/manager/players/${this.props.store.player.id}/companies/${this.props.store.company.id}/themes`);
        if (responseThemes.ok) {
            availableQuizThemes = responseThemes.data.themes.map(item => {
                return { label: item.title, value: item.id };
            });
            quizThemes = themes.filter(item => {
                const index = availableQuizThemes.map(function (e) { return e.value; }).indexOf(item);
                return index !== -1;
            });
        }

        this.setState({
            quiz: { ...quiz, locales: quiz.locales.split(',') },
            themes: quizThemes,
            picture: quiz.picture ? process.env.REACT_APP_IMAGES_DIRECTORY + quiz.picture : null,
            logo: quiz.logo ? process.env.REACT_APP_IMAGES_DIRECTORY + quiz.logo: null,
            availableQuizThemes: availableQuizThemes,
            availableFilterThemes: availableQuizThemes
        });
    }

    handleAction(action) {
        switch (action) {
            case Config.action.ADD:
                history.push(`/questions/new`);
                break;
            case Config.action.BACK:
                history.push('/quizzes');
                break;
            case Config.action.DELETE:
                this.setState({ dialog: true });
                break;
            default:
        }
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChangeLocales(event) {
        let quiz = this.state.quiz;
        if (event.value.length > 0) {
            quiz.locales = event.value;
        }
        this.setState({ quiz: quiz });
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChangeMultiSelect(event) {
        this.setState({ themes: event.value });
    }

    /**
     * Set state values of quiz
     * 
     * @param {*} quiz 
     */
    handleChangeQuiz(quiz) {
        this.setState({ quiz: quiz });
    }

    /**
     * Set picture to state
     * 
     * @param {*} files
     */
    handleChangeQuizPicture(files) {
        const pictureFile = files[0];
        if (pictureFile) {
            let quiz = this.state.quiz;
            quiz.picture = pictureFile.name;
            this.setState({ pictureFile: pictureFile, quiz: quiz, dirty: true, picture: URL.createObjectURL(pictureFile) });
        }
    }

    /**
     * Set logo file
     * @param {*} quiz 
     */
    handleChangeQuizLogo(files){
        const logoFile = files[0];
        if(logoFile){
            let quiz = this.state.quiz;
            quiz.logo = logoFile.name;
            this.setState({ logoFile: logoFile, quiz: quiz, dirty: true, logo: URL.createObjectURL(logoFile) });
        }
    }

    /**
     * Set state public value of question
     * 
     * @param {*} event 
     */
    handleChangeQuizPublic(event) {
        let quiz = this.state.quiz;
        quiz.public = event.checked;
        this.setState({ quiz: quiz });
    }

    /**
    * Set state published value of question
    * 
    * @param {*} event 
    */
    handleChangeQuizPublished(event) {
        let quiz = this.state.quiz;
        quiz.published = event.value;
        this.setState({ quiz: quiz });
    }

    /**
     * Set state values of question
     * 
     * @param {*} quiz 
     */
    handleClickPicture() {
        let quiz = this.state.quiz;
        quiz.picture = '';
        this.setState({ dirty: false, picture: null, quiz: quiz });
    }

    /**
     * Set state values for the logo
     * @param {*} quiz
     */
    handleClickLogo() {
        let quiz = this.state.quiz;
        quiz.logo = '';
        this.setState({ dirty: false, logo: null, quiz: quiz});
    }

    /**
     * Hide quiz delete
     */
    handleDeleteQuizOnHide() {
        this.setState({ dialog: false });
    }

    /**
     * Submit quiz delete
     */
    async handleDeleteQuizOnSubmit() {
        const response = await http.del(`/manager/quizzes/${this.state.quiz.id}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            this.handleAction(Config.action.BACK);
        }
        this.setState({ dialog: false });
    }

    async handleQuizSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, submitDisable: true });
        const { quiz, themes, pictureFile, logoFile } = this.state;

        let themesOK = true;
        // On oblige à lier à une thème
        /*if (this.props.store.player.manager
            && !this.props.store.player.admin
            && themes.length === 0) {
            themesOK = false;
        }*/
        if (themes.length === 0) {
            themesOK = false;
        }

        // Id, title and theme are mandatory
        if (Object.keys(quiz.title).length > 0 && themesOK) {

            // Send picture to API, response is an anonimised reference image name
            let pictureName = quiz.picture;
            let pictureOK = true;

            if (pictureFile.name) {
                let dataPicture = new FormData();
                dataPicture.append('image', pictureFile);
                const responsePicture = await http.postFile('/upload/image', dataPicture);
                if (!responsePicture.ok) {
                    pictureOK = false;
                    this.props.showError(responsePicture.error, ['app.picture.not.uploaded']);
                } else {
                    pictureName = responsePicture.data.image
                }
            }

            let logoName = quiz.logo;
            let logoOk = true;
            //Same as the picture but for the logo
            if(logoFile.name){
                let dataLogo = new FormData();
                dataLogo.append('image', logoFile);
                const responseLogo = await http.postFile('/upload/image', dataLogo);

                if (!responseLogo.ok) {
                    logoOk = false;
                    this.props.showError(responseLogo.error, ['app.picture.not.uploaded']);
                } else {
                    logoName = responseLogo.data.image;
                }
            }

            if (pictureOK && logoOk) {
                const data = {
                    quiz: { ...quiz, picture: pictureName, logo: logoName, locales: quiz.locales.toString() },
                    themes: themes
                };

                const response = await http.put(`/manager/quizzes/${quiz.id}`, data)
                if (!response.ok) {
                    this.props.showError(response.error, ['app.quiz.not.updated']);
                } else {
                    const quiz = { ...response.data.quiz, locales: response.data.quiz.locales.split(',') };
                    const quizThemes = response.data.themes.map(item => {
                        return item.id;
                    });
                    this.setState({ quiz: { ...quiz, themes: quizThemes } });
                    this.props.showSuccess({ summary: 'app.quiz.updated' });
                }
            }
        }

        this.setState({ submitDisable: false });
    }

    render() {
        const { intl } = this.props;
        const { submitted, submitDisable, quiz, themes, availableQuizThemes//, selectedFilterQuizzes
        } = this.state;

        const styleButtonActions = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        };

        const styleParameters = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            marginBottom: '.5em'
        };

        const styleMultiSelect = {
            marginBottom: '.3em',
            width: '100%'
        };

        const styleLogo = {
            marginBottom: '1.5em'
        };

        const dynamicDetails = this.state.quiz.locales.map((locale) => {
            return <QuizDetail key={locale} lang={locale} quiz={this.state.quiz}
                onChange={this.handleChangeQuiz}
                submitted={this.state.submitted} styles={this.props.store.style} />
        });

        return (
            <div>
                <div className="row">
                    <div className="col-6">
                        <div style={styleParameters}>
                            <InputMultiSelect name="themes" label="app.theme" values={themes} options={availableQuizThemes}
                                onChange={this.handleChangeMultiSelect} submitted={submitted} mandatory={true}
                                styles={styleMultiSelect} />
                        </div>
                        <div style={styleParameters}>
                            <InputSwitch label={quiz.published ? "app.published" : "app.draft"} checked={quiz.published} onChange={this.handleChangeQuizPublished} />
                        </div>
                        <div style={styleParameters}>
                            <InputCheckbox label="app.quiz.public" checked={quiz.public} onChange={this.handleChangeQuizPublic} />
                        </div>
                        <div className="col-6" style={styleLogo}>
                            <ImageUpload name="logo" imageLabel="app.choose.image.label.quiz"
                                styles={this.props.store.style} kind="primary" onClick={this.handleClickLogo}
                                onChange={this.handleChangeQuizLogo} value={this.state.logo} format="small-square" label="app.choose.logo.title"/>
                        </div>
                    </div>
                    <div className="col-6">
                        <ImageUpload name="picture" imageLabel="app.choose.image.label"
                            styles={this.props.store.style} kind="primary" onClick={this.handleClickPicture}
                            onChange={this.handleChangeQuizPicture} value={this.state.picture} label="app.choose.image.title" />
                    </div>
                </div>

                <InputMultiSelectInline name="locales" label="app.language" kind="quiz"
                    options={Config.locales.LIST} value={this.state.quiz.locales}
                    onChange={this.handleChangeLocales} />

                {dynamicDetails}

                <div style={styleButtonActions}>
                    <div style={{ marginRight: '.5em' }}>
                        <Button label="app.delete" onClick={() => this.handleAction(Config.action.DELETE)}
                            styles={this.props.store.style} kind="secondary" disabled={submitDisable ? submitDisable : false} />
                    </div>
                    <div>
                        <Button label="app.save" onClick={(e) => this.handleQuizSubmit(e)}
                            styles={this.props.store.style} kind="primary" disabled={submitDisable ? submitDisable : false} />
                    </div>
                </div>

                <Dialog visible={this.state.dialog}
                    text={intl.formatMessage({ id: 'app.dialog.delete.quiz' })}
                    onHide={this.handleDeleteQuizOnHide} onSubmit={this.handleDeleteQuizOnSubmit} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            player: state.player,
            quizDetails: state.quizDetails,
            style: state.style,
        }
    };
};

const mapDispatchToProps = {
    showError,
    showSuccess
};

const QuizDetailsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuizDetailsPage);

QuizDetailsPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizDetailsPage);