import React, { Component } from "react";
import Radium from 'radium';
import Config from '../../config';

class Button extends Component {

    render() {
        return (
            <button ref={this.props.innerRef} type="button" className="btn btn-rounded btn-icon"
                style={(this.props.styles && this.props.kind) ? [this.props.styles.buttonIcon.base, this.props.styles.buttonIcon[this.props.kind]] : '' }
                onClick={this.props.onClick.bind(this)} disabled={this.props.disabled ? this.props.disabled : false}>
                <i className={this.props.icon ? this.props.icon : Config.button.rounded.DEFAULT_FA} style={this.props.styles ? this.props.styles.icon : []} />
            </button>);
    }
}

Button = Radium(Button);

export const ButtonRoundedIcon = React.forwardRef((props, ref) => (<Button {...props} innerRef={ref} />));
