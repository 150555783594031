import {
    initialState,
    QUIZZES_SET_QUIZ_FILTER,
    QUIZZES_SET_SHARED,
    QUIZZES_SET_THEME_VALUE
} from '../actions/quizzes.action';

const quizzes = (state = initialState, action) => {
    switch (action.type) {
        case QUIZZES_SET_QUIZ_FILTER:
            return { ...state, quizFilter: action.quizFilter };
        case QUIZZES_SET_SHARED:
                return { ...state, shared: action.shared };
        case QUIZZES_SET_THEME_VALUE:
            return { ...state, themeValue: action.themeValue };
        default:
            return state
    }
}

export default quizzes;