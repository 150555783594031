import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';
import { sortLabel } from "../core/helpers/tools.helper";

import ButtonRounded from '../shared/components/button-rounded.component';
import { InputAutoComplete } from '../shared/components/form/input-autocomplete.component';
import InputSelectInline from '../shared/components/form/input-select-inline.component';
import InputSelect from '../shared/components/form/input-select.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import Quiz from './components/quiz.component';
import QuizPublic from './components/quiz-public.component';
import { setPage } from '../store/actions/app.action';
import { setQuizFilter, setShared, setThemeValue } from '../store/actions/quizzes.action';
import { showError, showWarning } from '../store/actions/message.action';
import Config from '../config';

class ConnectedQuizzesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filtredQuizzes: [],
            quizFilter: {
                title: '',
                themes: []
            },
            companyId: '',
            quizzes: [],
            theme: null,
            themeFilter: [],
            themeValue: '',
            themes: [],
            shared: 'unshared',
            sharedTypes: []
        };

        this.getQuizzes = this.getQuizzes.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.handleChangeQuiz = this.handleChangeQuiz.bind(this);
        this.handleChangeShared = this.handleChangeShared.bind(this);
        this.handleFilterQuizzes = this.handleFilterQuizzes.bind(this);
        this.handleFilterThemes = this.handleFilterThemes.bind(this);
        this.handleFindQuizzes = this.handleFindQuizzes.bind(this);
    }

    async componentDidMount() {
        this.props.setPage(Config.page.QUIZZES);

        const { id } = this.props.match.params;
        const { quizFilter, themeValue, shared } = this.props.store.quizzes;

        let companyId = '';
        let filtredQuizzes = [];
        let quizzes = [];
        let sharedTypes = Config.quiz.TYPE.map(item => {
            return { ...item, label: this.props.intl.formatMessage({ id: item.label }) };
        });
        let themes = [];
        let themeFilter = [];

        if (id) {
            companyId = id;
        } else {
            companyId = this.props.store.company.id;
        }

        if (this.props.store.quizzes.shared === 'unshared') {
            quizzes = await this.getQuizzes(companyId, quizFilter);
            themes = await this.getThemes(companyId);
        } else {
            quizzes = await this.getPublicQuizzes(quizFilter);
            themes = await this.getPublicThemes();
        }

        if (quizFilter.title !== '') {
            filtredQuizzes = await quizzes.filter((quiz) => {
                return quiz.title.toLowerCase().includes(quizFilter.title.toLowerCase());
            });
        } else {
            filtredQuizzes = quizzes;
        }

        if (themes.length > 0) {
            themeFilter = themes.map(item => {
                return { label: item.title, value: item.id };
            });
        }

        themeFilter.sort(sortLabel);

        this.setState({ companyId, quizzes, filtredQuizzes, themes, themeFilter,
            quizFilter, themeValue, shared, sharedTypes
        });
    }

    async getPublicQuizzes(quizFilter = []) {
        let quizzes = [];

        let filter = '?public=true';
        if (quizFilter.themes.length > 0) {
            filter = `${filter}&theme=${quizFilter.themes.toString()}`;
        }
        
        const response = await http.get(`/manager/quizzes${filter}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            quizzes = response.data.quizzes;
        }

        quizzes.sort(function (a, b) {
            if (a.title < b.title)
                return -1;
            if (a.title > b.title)
                return 1;
            return 0;
        });

        return quizzes;
    }

    async getPublicThemes() {
        let themes = [];
        let filter = '?public=true';
        const response = await http.get(`/manager/themes${filter}`)
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            themes = response.data.themes;
        }
        return themes;
    }

    async getQuizzes(companyId, quizFilter = []) {
        let filter = '';
        let quizzes = [];
        
        if (quizFilter.themes.length > 0) {
            filter = `?theme=${quizFilter.themes.toString()}`;
        }

        const response = await http.get(`/manager/quizzes/company/${companyId}${filter}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            quizzes = response.data.quizzes;
        }

        quizzes.sort(function (a, b) {
            if (a.title < b.title)
                return -1;
            if (a.title > b.title)
                return 1;
            return 0;
        });

        return quizzes;
    }

    async getThemes(companyId) {
        let themes = [];

        const response = await http.get(`/manager/players/${this.props.store.player.id}/companies/${companyId}/themes`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            themes = response.data.themes;
        }

        return themes;
    }

    handleAction(action, data) {
        switch (action) {
            case 'add':
                history.push(`/quizzes/new`);
                break;
            case 'modify':
                history.push(`/quizzes/${data.id}/manager`);
                break;
            case Config.action.QUIZ_PUBLIC:
                history.push(`/quizzes/${data.id}/public`);
                break;
            case 'delete':
                history.push(`/quizzes/${data.id}/delete`);
                break;
            default:
        }
    }

    /**
      * Change quiz
      * 
      * @param {*} event 
      */
    async handleChangeQuiz(quiz) {
        const quizzes = this.state.quizzes;
        const filtredQuizzes = this.state.filtredQuizzes;

        const data = { quiz: { published: quiz.published } };
        const response = await http.patch(`/manager/quizzes/${quiz.id}`, data)
        if (!response.ok) {
            this.props.showError(response.error, ['app.quiz.not.updated']);
        } else {
            const index = quizzes.map(function (e) { return e.id; }).indexOf(parseInt(quiz.id, 10));
            quizzes[index] = quiz;

            const indexFiltred = filtredQuizzes.map(function (e) { return e.id; }).indexOf(parseInt(quiz.id, 10));
            if (indexFiltred !== -1) {
                filtredQuizzes[indexFiltred] = quiz
            }

            this.setState({ quizzes: quizzes, filtredQuizzes: filtredQuizzes });
        }
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    async handleChangeShared(event) {
        if (event.target.value) {
            const { quizFilter, shared } = this.state;
            let filtredQuizzes = this.state.filtredQuizzes;
            let quizzes = this.state.quizzes;
            let themes = this.state.themes;
            let themeFilter = this.state.themeFilter;

            if (event.target.value !== shared) {
                this.props.setShared(event.target.value);
                if (event.target.value === 'unshared') {
                    quizzes = await this.getQuizzes(this.state.companyId, quizFilter);
                    themes = await this.getThemes(this.state.companyId);
                } else {
                    quizzes = await this.getPublicQuizzes(quizFilter);
                    themes = await this.getPublicThemes();
                }

                if (themes.length > 0) {
                    themeFilter = themes.map(item => {
                        return { label: item.title, value: item.id };
                    });
                }

                if (quizFilter.title !== '') {
                    filtredQuizzes = await quizzes.filter((quiz) => {
                        return quiz.title.toLowerCase().includes(quizFilter.title.toLowerCase());
                    });
                } else {
                    filtredQuizzes = quizzes;
                }

                this.setState({ 
                    filtredQuizzes: filtredQuizzes,
                    quizzes: quizzes,
                    shared: event.target.value, 
                    themes: themes,
                    themeFilter: themeFilter
                });
            }
        }
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} quizFilter
     * @param {*} quizzes
     */
    async handleFilterQuizzes(quizFilter) {
        this.props.setQuizFilter(quizFilter);
        const results = await this.state.quizzes.filter((quiz) => {
            return quiz.title.toLowerCase().includes(quizFilter.title.toLowerCase());
        });
        this.setState({ filtredQuizzes: results, quizFilter: quizFilter });
    }

    /**
     * Filter available themes
     * 
     * @param {*} event 
     */
    async handleFilterThemes(event) {
        const themeValue = event.target.value;
        let quizFilter = [];
        let quizzes = [];

        this.props.setThemeValue(themeValue);

        quizFilter = this.state.quizFilter;
        quizFilter.themes = [themeValue];

        if (this.state.shared === 'unshared') {
            quizzes = await this.getQuizzes(this.state.companyId, quizFilter);
        } else {
            quizzes = await this.getPublicQuizzes(quizFilter);
        }

        this.setState({ themeValue: themeValue, quizzes: quizzes });
        this.handleFilterQuizzes(quizFilter);
    }

    /**
      * Find quizzes
      * 
      * @param {*} event 
      */
    async handleFindQuizzes(event) {
        let quizFilter = this.state.quizFilter;
        quizFilter.title = event.value;
        this.handleFilterQuizzes(quizFilter);
    }

    render() {

        const styleHeaderContainer = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        };

        const styleHeaderSelection = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            flexWrap: 'wrap'
        };

        const styleHeaderActions = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        };

        const pageHeader = (
            <div style={styleHeaderContainer}>
                <div style={styleHeaderSelection}>
                    <InputSelect name="theme" label="app.theme" onChange={this.handleFilterThemes} all={true} 
                        none={this.state.shared === 'unshared' && this.props.store.player.admin ? true : false}
                        options={this.state.themeFilter} value={this.state.themeValue} />

                    <InputAutoComplete field="title" label="app.find" size="60"
                        onChange={this.handleFindQuizzes} value={this.state.quizFilter.title} />

                    {this.props.store.player.admin && (<div style={{ marginBottom: '.5em' }}>
                        <InputSelectInline name="shared"
                            options={this.state.sharedTypes} value={this.state.shared}
                            onChange={this.handleChangeShared} />
                    </div>)}
                </div>
                <div style={styleHeaderActions}>
                    <div style={{ marginRight: '.5em' }}>
                        <ButtonRounded label="app.create.quiz" onClick={() => this.handleAction(Config.action.ADD)}
                            styles={this.props.store.style} kind="expandedPrimary"
                            disabled={this.state.companyId !== this.props.store.company.id} />
                    </div>
                </div>
            </div>
        );

        const stylePageCard = {
            display: 'flex',
            flexWrap: 'wrap'
        };

        const dynamicQuizzes = this.state.filtredQuizzes.map((quiz, index) => {
            if (this.state.shared === 'unshared') {
                return <Quiz key={index} quiz={quiz} onClick={this.handleAction} onChange={this.handleChangeQuiz} styles={this.props.store.style} />
            } else {
                return <QuizPublic key={index} quiz={quiz} onClick={this.handleAction} styles={this.props.store.style} />
            }
        });

        return (
            <Page header={pageHeader}>
                <PageCard styles={stylePageCard} >
                    {dynamicQuizzes}
                </PageCard>
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            player: state.player,
            quizzes: state.quizzes,
            style: state.style
        }
    };
};

const mapDispatchToProps = {
    setPage,
    setQuizFilter, setShared, setThemeValue,
    showError, showWarning
};

const QuizzesPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuizzesPage);

QuizzesPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizzesPage);
