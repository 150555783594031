import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Button } from '../shared/components/button.component';
import ButtonRounded from '../shared/components/button-rounded.component';
import { Form } from '../shared/components/form/form.component';
import { InputAutoComplete } from '../shared/components/form/input-autocomplete.component';
import { InputText } from '../shared/components/form/input-text.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { PageSubTitle } from '../shared/components/page/page-sub-title.component';
import { PickList } from 'primereact/picklist';
import { setLastPage } from '../store/actions/app.action';
import { initializeDepartmentCreate, setName, setReference, setFilterName, setFiltredUsers, setSelectedUsers } from '../store/actions/department-create.action';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';

class ConnectedDepartmentCreatePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            submitDisable: false,
            name: '',
            reference: '',
            availableUsers: [],
            selectedUsers: [],
            users: [],
            user: null
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeUsers = this.handleChangeUsers.bind(this);
        this.handleOnClickNewUser = this.handleOnClickNewUser.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.pickListOnChange = this.pickListOnChange.bind(this);
    }

    async componentDidMount() {
        let users = [];

        // Get company users
        const response = await http.get('/manager/companies/' + this.props.store.company.id + '/users');
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            users = response.data.users;
        }

        // 
        let availableUsers = [];
        if (this.props.store.page.filterName || this.props.store.page.selectedUsers.length !== 0) {
            availableUsers = await users.filter((user) => {
                const index = this.props.store.page.selectedUsers.map(function (e) { return e.id; }).indexOf(parseInt(user.id, 10));
                const value = this.props.store.page.filterName;
                const name = user.firstname.toLowerCase() + user.lastname.toLowerCase();
                if (value) {
                    return name.includes(value.toLowerCase()) && index === -1;
                } else {
                    return index === -1;
                }
            });
        } else {
            availableUsers = users;
        }

        this.setState({
            name: this.props.store.page.name,
            reference: this.props.store.page.reference,
            availableUsers: availableUsers,
            selectedUsers: this.props.store.page.selectedUsers,
            users: users,
            user: this.props.store.page.filterName
        });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push('/departments');
                break;
            default:
        }
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
        switch (name) {
            case 'name':
                this.props.setName(value);
                break;
            case 'reference':
                this.props.setReference(value);
                break;
            default:
        }
    }

    /**
      * Change users
      * 
      * @param {*} event 
      */
    async handleChangeUsers(event) {
        const value = event.value;
        const results = await this.state.users.filter((user) => {
            const index = this.state.selectedUsers.map(function (e) { return e.id; }).indexOf(parseInt(user.id, 10));
            const name = user.firstname.toLowerCase() + user.lastname.toLowerCase();
            return name.includes(value.toLowerCase()) && index === -1;
        });
        this.setState({ availableUsers: results, user: value });
        this.props.setFilterName(value);
    }

    /**
     * New user
     * 
     * @param {*} event 
     */
    handleOnClickNewUser(event) {
        this.props.setLastPage('/departments/new');
        history.push(`/users/new`);
    }

    /**
     * Submit department
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, submitDisable: true });
        const { name, reference, selectedUsers } = this.state;

        if (name && reference) {
            const data = {
                department: {
                    name: name,
                    reference: reference
                },
                managers: selectedUsers
            }
            const response = await http.post('/manager/companies/' + this.props.store.company.id + '/departments', data);
            if (!response.ok) {
                this.props.showError(response.error, ['app.department.not.created']);
            } else {
                this.props.initializeDepartmentCreate();
                history.push(`/departments`);
            }
        }

        this.setState({ submitDisable: false });
    }

    /**
     * Event called when a user move to another column
     * 
     * @param {*} event 
     */
    pickListOnChange(event) {
        this.setState({
            availableUsers: event.source,
            selectedUsers: event.target
        });
        this.props.setSelectedUsers(event.target);
    }

    /**
     * Questions template of pick list column
     * 
     * @param {*} user 
     */
    pickListUserTemplate(user) {
        return (
            <div>{user.firstname} {user.lastname}</div>
        );
    }

    render() {
        const intl = this.props.intl;
        const { submitted, submitDisable, name, reference, user } = this.state;

        const styleButtonActions = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        };

        const stylePickListHeader = {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '.3em'
        };

        const stylePickListHeaderLeft = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '48%'
        };

        const stylePickListHeaderCenter = {
            width: '4%'
        }

        const stylePickListHeaderRight = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '48%'
        }

        const stylePickListFilter = {
            ...stylePickListHeader
        };

        const stylePickListFilterLeft = {
            ...stylePickListHeaderLeft,
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
        };

        const stylePickListFilterCenter = {
            ...stylePickListHeaderCenter
        }

        const stylePickListFilterRight = {
            ...stylePickListHeaderRight,
            justifyContent: 'flex-end',
            flexWrap: 'wrap'
        }

        const styleAutoComplete = {
            marginBottom: '.5em'
        };

        return (
            <Page>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.new.department' })} onClick={this.handleAction} back={Config.action.BACK} >
                    <Form onSubmit={this.handleSubmit}>

                        <InputText name="name" label="app.department.name" value={name}
                            onChange={this.handleChange} submitted={submitted} mandatory="true" />

                        <InputText name="reference" label="app.department.reference" value={reference}
                            onChange={this.handleChange} submitted={submitted} mandatory="true"  maxLength="6" />

                        <PageSubTitle title={this.props.intl.formatMessage({ id: 'app.add.administrators' })} />

                        <div style={stylePickListFilter}>
                            <div style={stylePickListFilterLeft}>
                                <InputAutoComplete field="user" label="app.find.user" value={user} styles={styleAutoComplete}
                                    onChange={this.handleChangeUsers} />
                            </div>
                            <div style={stylePickListFilterCenter}></div>
                            <div style={stylePickListFilterRight}>
                                <ButtonRounded label="app.user.new" onClick={this.handleOnClickNewUser}
                                    styles={this.props.store.style} kind="secondary" />
                            </div>
                        </div>

                        <div className="opp-picklist">
                            <PickList source={this.state.availableUsers} target={this.state.selectedUsers}
                                itemTemplate={this.pickListUserTemplate} onChange={this.pickListOnChange}
                                sourceStyle={{ 'height': '30em' }} targetStyle={{ 'height': '30em' }}
                                showSourceControls={false} showTargetControls={false} responsive={true}
                                sourceHeader={intl.formatMessage({ id: 'app.users.availables' })}
                                targetHeader={intl.formatMessage({ id: 'app.users.selected' })} />
                        </div>

                        <div style={styleButtonActions}>
                            <div>
                                <Button label="app.save" onClick={this.handleSubmit}
                                    styles={this.props.store.style} kind="primary" disabled={submitDisable ? submitDisable : false} />
                            </div>
                        </div>

                    </Form>
                </PageCard>
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            app: state.app,
            company: state.company,
            page: state.departmentCreate,
            style: state.style
        }
    };
};

const mapDispatchToProps = {
    setLastPage,
    initializeDepartmentCreate, setName, setReference, setFilterName, setFiltredUsers, setSelectedUsers,
    showError, showSuccess
};

const DepartmentCreatePage = connect(mapStateToProps, mapDispatchToProps)(ConnectedDepartmentCreatePage);

DepartmentCreatePage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(DepartmentCreatePage);
