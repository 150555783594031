import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Button } from '../shared/components/button.component';
import ImageUpload from '../shared/components/form/image-upload.component';
import { InputText } from '../shared/components/form/input-text.component';
import StaticText from '../shared/components/form/static-text.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { setLastPage } from '../store/actions/app.action';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';

class ConnectedCompanyModifyPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            submitDisable: false,
            logoFile: [],
            company: {
                id: '',
                name: '',
                description: '',
                logo: '',
                website: '',
                duelExpirationDays: 1,
            },
            context: {
                id: null,
            },
            logo: null
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLogo = this.handleChangeLogo.bind(this);
        this.handleClickLogo = this.handleClickLogo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ id: id });
        http.get(`/manager/companies/${id}`)
            .then(response => {
                if (response.ok) {
                    const { company, context } = response.data;
                    if (company.name) {
                        this.setState({
                            company: company,
                            context: context,
                            lastPage: this.props.store.app.lastPage,
                            logo: company.logo ? process.env.REACT_APP_IMAGES_DIRECTORY + company.logo : null
                        })
                    }
                }
            });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                if (this.state.lastPage) {
                    const lastPage = this.state.lastPage;
                    this.props.setLastPage(``);
                    history.push(lastPage);
                }
                break;
            default:
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        let company = this.state.company;
        this.setState({ company: { ...company, [name]: value } });
    }

    /**
     * Set logo file
     * 
     * @param {*} files 
     */
    handleChangeLogo(files) {
        const logoFile = files[0];
        if (logoFile) {
            let company = this.state.company;
            company.logo = logoFile.name;
            this.setState({ logoFile: logoFile, company: company, dirty: true, logo: URL.createObjectURL(logoFile) });
        }
    }

    /**
     * Set state values of question
     */
    handleClickLogo() {
        let company = this.state.company;
        company.logo = '';
        this.setState({ dirty: false, logo: null, company: company });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, submitDisable: true });
        const { company, logoFile } = this.state;
        if (Object.keys(company.name).length > 0) {

            // Send logo to API, response is an anonimised reference name
            let logoName = company.logo;
            let logoOK = true;
            if (logoFile.name) {
                let dataLogo = new FormData();
                dataLogo.append('image', logoFile);
                const responseLogo = await http.postFile('/upload/image', dataLogo);
                if (!responseLogo.ok) {
                    logoOK = false;
                    this.props.showError(responseLogo.error, ['app.logo.not.uploaded']);
                } else {
                    logoName = responseLogo.data.image
                }
            }

            if (logoOK) {
                const data = {
                    company: { ...company, logo: logoName },
                };

                const response = await http.put(`/manager/companies/${company.id}`, data);
                if (!response.ok) {
                    this.props.showError(response.error, ['app.company.not.updated']);
                } else {
                    this.props.showSuccess({ summary: 'app.company.updated' });
                    if (this.state.lastPage) {
                        const lastPage = this.state.lastPage;
                        this.props.setLastPage('');
                        history.push(lastPage);
                    }
                }
            }
        }

        this.setState({ submitDisable: false });
    }

    render() {
        const { submitted, company, context } = this.state;

        const styleButtonActions = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        };

        return (
            <Page>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.modify.company' })}
                    onClick={this.handleAction} back={this.state.lastPage ? Config.action.BACK : ''}>

                    <div className="row">
                        <div className="col-6">
                            <InputText name="name" label="app.company.name" value={company.name}
                                onChange={this.handleChange} submitted={submitted} mandatory="true" />

                            <InputText name="description" label="app.company.description" value={company.description}
                                onChange={this.handleChange} />

                            <InputText name="website" label="app.company.website" value={company.website}
                                onChange={this.handleChange} />

                            <InputText name="duelExpirationDays" label="app.company.days.before.duel.expiration"
                                value={company.duelExpirationDays} onChange={this.handleChange} inputType="number" />

                            <StaticText name="code" label="app.company.code" value={context.id} />
                        </div>
                        <div className="col-6">
                            <ImageUpload name="logo"
                                styles={this.props.store.style} kind="primary" onClick={this.handleClickLogo}
                                onChange={this.handleChangeLogo} value={this.state.logo} format="square" />
                        </div>
                    </div>

                    <div style={styleButtonActions}>
                        <Button label="app.save" onClick={(e) => this.handleSubmit(e)}
                            styles={this.props.store.style} kind="primary" disabled={this.state.submitDisable} />
                    </div>

                </PageCard>
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            app: state.app,
            company: state.company,
            player: state.player,
            style: state.style
        }
    };
};

const mapDispatchToProps = {
    setLastPage,
    showError, showSuccess
};

const CompanyModifyPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedCompanyModifyPage);

CompanyModifyPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(CompanyModifyPage);
