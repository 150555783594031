import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';

class ConnectedStaticText extends Component {

    render() {

        const styleContainer = {
            base: {
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '1.5rem'
            }
        }

        const styleLabel = {
            base: {}
        }

        const styleComponent = {
            base: {
                backgroundColor: '#f4f4f4',
                opacity: '1',
                border: '1px solid #c8c8c8',
                borderRadius: '3px',
                paddingTop: '.5rem',
                paddingBottom: '.5rem',
                paddingLeft: '1rem'
            }
        }

        return (
            <div style={[styleContainer.base]}>
                <label style={[styleLabel.base]}>{this.props.intl.formatMessage({ id: this.props.label })}</label>
                <input type="text" style={[styleComponent.base]} value={this.props.value} disabled></input>
            </div>
        )
    }
}

ConnectedStaticText = Radium(ConnectedStaticText);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const StaticText = connect(mapStateToProps, null)(ConnectedStaticText);

StaticText.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(StaticText);