import React from 'react';

const styleTitle = {
    marginBottom: '1em'
}

export const PageSubTitle = (props) => (
    <h5 style={styleTitle}>
        {props.title}
    </h5>
)