import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { DepartmentRoleList } from '../shared/components/department-role-list.component';
import { Form } from '../shared/components/form/form.component';
import InputSelectInline from '../shared/components/form/input-select-inline.component';
import { InputText } from '../shared/components/form/input-text.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { PageSubTitle } from '../shared/components/page/page-sub-title.component';
import PageTitle from '../shared/components/page/page-title.component';
import { addSelectedUser } from '../store/actions/department-create.action';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';

class ConnectedUserCreatePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            availableDepartments: [],
            company: [],
            companyRole: Config.company.role.USER,
            companyRoleOptions: [],
            departments: [],
            departmentRoleOptions: [],
            lastPage: '',
            localesOptions: [],
            step: 'profile',
            submitted: false,
            submittedStep1: false,
            submitDisable: false,
            superadmin: false,
            user: {
                firstname: '',
                lastname: '',
                function: '',
                email: '',
                locale: Config.app.DEFAULT_LOCALE
            },
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChangeCompanyRole = this.handleChangeCompanyRole.bind(this);
        this.handleChangeDepartments = this.handleChangeDepartments.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSubmitStep1 = this.handleSubmitStep1.bind(this);
    }

    async componentDidMount() {
        const intl = this.props.intl;

        let availableDepartments = [];
        let company = [];
        let initDepartments = [];
        let superadmin = false;

        if (this.props.store.users.company.length !== 0) {
            if (this.props.store.user.role !== Config.app.role.SUPER_ADMIN) {
                this.props.showError({ summary: 'app.error.forbidden' });
                history.push('/');
            }
            superadmin = true;
            company = this.props.store.users.company;
        } else {
            superadmin = false;
            company = this.props.store.company
        }

        const response = await http.get(`/manager/companies/${company.id}/departments`);
        if (response.ok) {
            const departments = response.data.departments;
            availableDepartments = departments.map(item => {
                return { id: item.id, label: item.name, value: item.id };
            });
            initDepartments = departments.map(item => {
                return { id: item.id, value: '' };
            });
        }

        // List of locales
        const locales = Config.locales.LIST;
        const localesOptions = locales.map(item => {
            const localeLabel = intl.formatMessage({ id: item.label });
            return { label: localeLabel, value: item.value };
        });

        // List of company roles
        const companyRoles = Config.company.role.OPTIONS;
        const companyRoleOptions = companyRoles.map(item => {
            const companyRoleLabel = intl.formatMessage({ id: item.label });
            return { label: companyRoleLabel, value: item.value };
        });

        const departmentRoles = Config.department.role.OPTIONS;
        const departmentRoleOptions = departmentRoles.map(item => {
            const departmentRoleLabel = intl.formatMessage({ id: item.label });
            return { label: departmentRoleLabel, value: item.value };
        });

        this.setState({
            availableDepartments: availableDepartments,
            company: company,
            companyRoleOptions: companyRoleOptions,
            departments: initDepartments,
            departmentRoleOptions: departmentRoleOptions,
            lastPage: this.props.store.app.lastPage,
            localesOptions: localesOptions,
            superadmin: superadmin
        });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.state.lastPage);
                break;
            default:
        }
    }

    /**
     * After each field key press, update state of value
     * 
     * @param {*} event 
     */
    handleChangeCompanyRole(event) {
        this.setState({ companyRole: event.value });
    }

    /**
     * After each field key press, update state of department value
     * 
     * @param {*} event 
     */
    handleChangeDepartments(id, value) {
        const departments = this.state.departments;
        const index = departments.map(function (e) { return e.id; }).indexOf(parseInt(id, 10));

        if (index !== -1) {
            departments[index].value = value;
            this.setState({ departments: departments });
        }
    }

    /**
     * After each field key press on users fields, update state of value
     * 
     * @param {*} event 
     */
    handleChangeUser(event) {
        const { name, value } = event.target;
        let user = this.state.user;
        user[name] = value;
        this.setState({ user: user });
    }

    /**
     * Submit user
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, submitDisable: true });
        const { user, companyRole, departments } = this.state;

        // if (user.firstname && user.lastname && user.email && user.function) {
        if (user.firstname && user.lastname && user.email) {
            const data = {
                user: user,
                companyRole: companyRole === Config.company.role.ADMIN ? true : false,
                departments: departments
            };

            const response = await http.post(`/manager/companies/${this.state.company.id}/users`, data);
            if (!response.ok) {
                this.props.showError(response.error);
            } else {
                if (this.state.lastPage === '/departments/new') {
                    this.props.addSelectedUser(response.data.user);
                }
                history.push(this.state.lastPage);
            }
        }

        this.setState({ submitDisable: false });
    }

    /**
     * Next submit
     * 
     * @param {*} event 
     */
    async handleSubmitStep1(event) {
        event.preventDefault();
        this.setState({ submittedStep1: true });
        const { user } = this.state;

        if (user.firstname && user.lastname && user.email) {
            this.setState({ step: 'role' });
        }
    }

    render() {
        const intl = this.props.intl;
        const {
            submitted,
            submittedStep1,
            submitDisable,
            user,
            companyRoleOptions,
            companyRole,
            departmentRoleOptions,
            departments,
            localesOptions,
            availableDepartments,
            step
        } = this.state;

        let displayDepartments = false;
        if (availableDepartments.length > 0) {
            displayDepartments = true;
        }
    
        return (
            <Page title={this.state.superadmin ? this.state.company.name : ''}>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.new.user' })} onClick={this.handleAction} back={Config.action.BACK}>

                    <Form onSubmit={this.handleSubmit}>
                        <div className="opp-user-create-header">
                            {step === 'profile' && (
                                <div className="opp-step">
                                    <div className="opp-step-item opp-step-actif"><div className="opp-step-label">1. {intl.formatMessage({ id: 'app.profile' })}</div></div>
                                    <div className="opp-step-item"><div className="opp-step-label">2. {intl.formatMessage({ id: 'app.role' })}</div></div>
                                </div>
                            )}

                            {step === 'role' && (
                                <div className="opp-step">
                                    <div className="opp-step-item opp-step-actif"><div className="opp-step-label">1. {intl.formatMessage({ id: 'app.profile' })}</div></div>
                                    <div className="opp-step-item opp-step-actif"><div className="opp-step-label">2. {intl.formatMessage({ id: 'app.role' })}</div></div>
                                </div>
                            )}
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <div className="opp-user-create-body">

                                    {step === 'profile' && (
                                        <div id="profile">

                                            <PageTitle title={this.props.intl.formatMessage({ id: 'app.profile' })} />

                                            <InputText name="firstname" label="app.firstname" value={user.firstname}
                                                onChange={this.handleChangeUser} submitted={submittedStep1} mandatory="true" />

                                            <InputText name="lastname" label="app.lastname" value={user.lastname}
                                                onChange={this.handleChangeUser} submitted={submittedStep1} mandatory="true" />

                                            <InputText name="email" label="app.email" value={user.email}
                                                onChange={this.handleChangeUser} submitted={submittedStep1} mandatory="true" />

                                            <InputSelectInline name="locale" label="app.language"
                                                options={localesOptions} value={this.state.user.locale}
                                                onChange={this.handleChangeUser} />
                                        </div>
                                    )}

                                    {step === 'role' && (
                                        <div id="role">

                                            <PageTitle title={this.props.intl.formatMessage({ id: 'app.role' })} />

                                            <InputText name="function" label="app.function" value={user.function}
                                                onChange={this.handleChangeUser} submitted={submitted} mandatory="true" />

                                            <PageSubTitle title={this.props.intl.formatMessage({ id: 'app.business.royale.roles' })} />

                                            { this.props.store.user.companyAdmin && (<div className="form-group">
                                                <InputSelectInline name="companyRole" label="app.company"
                                                    options={companyRoleOptions} value={companyRole} onChange={this.handleChangeCompanyRole} />
                                            </div>)}

                                            {displayDepartments && <div className="form-group">
                                                <h5 className="card-title">{this.props.intl.formatMessage({ id: 'app.departments' })}</h5>
                                                <DepartmentRoleList availableDepartments={availableDepartments} departments={departments}
                                                    departmentRoleOptions={departmentRoleOptions} onChange={this.handleChangeDepartments} />
                                            </div>}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {step === 'profile' && (
                            <div className="opp-user-create-footer">
                                <button type="button" className="btn btn-primary btn-fw" onClick={this.handleSubmitStep1}>
                                    {intl.formatMessage({ id: 'app.next' })}
                                </button>
                            </div>
                        )}

                        {step === 'role' && (
                            <div className="opp-user-create-footer">
                                <button type="button" className="btn btn-secondary btn-fw" onClick={(e) => this.setState({ step: 'profile' })}
                                    disabled={submitDisable}>
                                    {intl.formatMessage({ id: 'app.previous' })}
                                </button>
                                <button type="submit" className="btn btn-primary btn-fw" disabled={submitDisable}>
                                    {intl.formatMessage({ id: 'app.save' })}
                                </button>
                            </div>
                        )}
                    </Form>
                </PageCard >
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            app: state.app,
            company: state.company,
            user: state.user,
            users: state.users
        }
    };
};

const mapDispatchToProps = {
    addSelectedUser,
    showError, showSuccess
};

const UserCreatePage = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserCreatePage);

UserCreatePage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(UserCreatePage);
