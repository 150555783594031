import React, { Component } from 'react';
import { IntlMessage } from './intl-message.component';

import { ButtonRoundedIcon } from '../../shared/components/button-rounded-icon.component';
import { Checkbox } from 'primereact/checkbox';

export class PageTable extends Component {

    render() {
        let dynamicHeads = this.props.cols.map((col, i) => {
            switch (col.header) {
                case 'action':
                    return <th key={col.field}>&nbsp;</th>;
                case 'check':
                    return <th key={col.field}><Checkbox value={'all'} onChange={(e) => this.props.onCheck(e)} checked={this.props.values.length === this.props.checked.length} ></Checkbox></th>;
                default:
                    return <th key={col.field}><IntlMessage id={col.header} /></th>;
            }
        });

        let dynamicTopButtons = ''
        if (this.props.topButtons) {
            dynamicTopButtons = this.props.topButtons.map((val, i) => {
                let buttonClass = "btn btn-rounded btn-sm btn-table";
                if (val.class) {
                    buttonClass = buttonClass + " " + val.class;
                }
                return <button key={val.action} className={buttonClass} onClick={() => this.props.onClick(val.action)} data-toggle={this.props.dataToggle} data-target={this.props.dataTarget} >
                    <IntlMessage id={val.label} />
                </button>
            });
        }

        let noData = false;
        let dynamicBodies = '';
        if (this.props.values.length > 0) {
            dynamicBodies = this.props.values.map((val, i) => {
                let dynamicTd = this.props.cols.map((col, i) => {
                    switch (col.field) {
                        case 'action':
                            let dynamicActions = this.props.actionButtons.map((action, i) => {
                                let disabled = false;
                                if (action.disabled && val[action.disabled]) {
                                    disabled = true;
                                }
                                return <ButtonRoundedIcon key={i} icon={action.icon} onClick={() => this.props.onClick(action.action, val)}
                                    styles={this.props.styles} kind="secondary" disabled={disabled} />
                            });
                            return <td key="action" className="action-buttons" style={{display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-end'}}>{dynamicActions}</td>;
                        case 'check':
                            let disabled = false;
                            console.log(val);
                            console.log(col);
                            if (col.disabled && val[col.disabled]) {
                                disabled = true;
                            }
                            return <td key="check"><Checkbox value={val.id} onChange={(e) => this.props.onCheck(e)} checked={this.props.checked.includes(val.id)} disabled={disabled}></Checkbox></td>;
                        default:
                            if (col.icon) {
                                let iconStyle = { fontSize: '1.3rem' };

                                if (col.switch) {
                                    if (val[col.field]) {
                                        iconStyle = { ...iconStyle, color: this.props.styles.color.primary.base };
                                    } else {
                                        iconStyle = { ...iconStyle, color: this.props.styles.color.danger.base };
                                    }
                                }

                                if (col.colors) {
                                    iconStyle = { ...iconStyle, color: col.colors[val[col.field]] };
                                }

                                if (col.disabled && val[col.disabled]) {
                                    iconStyle = { ...iconStyle, color: this.props.styles.color.light.base };
                                }

                                return <td key={col.field}><i className={col.icon} style={iconStyle} /></td>;
                            }
                            return <td key={col.field}>{val[col.field]}</td>;
                    } 
                });
                return <tr key={val.id}>{dynamicTd}</tr>;
            });
        } else {
            noData = true;
        }

        return (
            <div className="page-table">
                {this.props.tableTitle && (<h4 className="card-title"><IntlMessage id={this.props.tableTitle} /></h4>)}
                <div className="table-responsive">
                    <div className="top-buttons">{dynamicTopButtons}</div>
                    <table className="table">
                        <thead>
                            <tr>{dynamicHeads}{this.props.actionButton && (<th></th>)}</tr>
                        </thead>
                        {dynamicBodies && (<tbody>{dynamicBodies}</tbody>)}
                    </table>
                    {noData && (<div><IntlMessage id="app.no.data" /></div>)}
                </div>
            </div>
        );
    }
}