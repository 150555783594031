import React, { Component } from 'react';
import { IntlMessage } from '../intl-message.component';
import { AutoComplete } from 'primereact/autocomplete';

export class InputAutoComplete extends Component {

    render() {

        const styleContainer = {
            marginRight: '1em',
            marginBottom: '.5em'
        };

        let styleAutoComplete = {
            fontFamily: 'PTSans, sans-serif',
            fontSize: '.9em'
        };

        if (this.props.style) {
            styleAutoComplete = { ...styleAutoComplete, ...this.props.style }
        }

        return (
            <div className="form-group" style={styleContainer}>
                {this.props.label &&
                    <div>
                        <label><IntlMessage id={this.props.label} /></label>
                    </div>
                }
                <div>
                    <AutoComplete name={this.props.name} field={this.props.field} value={this.props.value}
                        suggestions={this.props.suggestions} onChange={this.props.onChange.bind(this)}
                        style={styleAutoComplete}
                        inputStyle={this.props.inputStyle ? this.props.inputStyle : {}}
                        completeMethod={this.props.completeMethod ? this.props.completeMethod.bind(this) : function () { }}
                        size={this.props.size ? Number(this.props.size) : 30}
                        minLength={this.props.minLength ? this.props.minLength : 1} />
                </div>
            </div>
        )
    }
}
