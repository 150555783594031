import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Form } from '../shared/components/form/form.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { showError, showSuccess } from '../store/actions/message.action';
import StaticText from '../shared/components/form/static-text.component';
import StaticTextArea from '../shared/components/form/static-text-area.component';
import { SubmitButton } from '../shared/components/form/submit-button.component';
import Config from '../config';

class ConnectedThemeDeletePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            id: '',
            title: '',
            description: '',
            departments: []
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ id: id });

        const response = await http.get(`/manager/themes/${id}`);
        if (response.ok) {
            const { title, description } = response.data.theme;
            const departments = await Promise.all(response.data.departments.map(async item => {
                return item.name;
            }));
            this.setState({
                title: title,
                description: description,
                departments: departments.toString().replace(',', ', ')
            });
        }
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push('/themes');
                break;
            default:
        }
    }

    /**
     * Submit delete theme
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        const { id } = this.state;
        const response = await http.del(`/manager/themes/${id}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            history.push('/themes');
        }
    }

    render() {
        const { submitDisable, title, description, departments } = this.state;

        return (
            <Page>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.delete.theme' })}
                    onClick={this.handleAction} back={Config.action.BACK}>
                    <Form onSubmit={this.handleSubmit}>
                        <StaticText label="app.title" value={title} />
                        <StaticTextArea label="app.description" value={description} />
                        <StaticText label="app.department" value={departments} />
                        <SubmitButton disabled={submitDisable} label="app.delete" />
                    </Form>
                </PageCard>
            </Page>
        );
    }
}

const mapDispatchToProps = {
    showError, showSuccess
};

const ThemeDeletePage = connect(null, mapDispatchToProps)(ConnectedThemeDeletePage);

ThemeDeletePage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ThemeDeletePage);