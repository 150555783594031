import React, { Component } from "react";
import { connect } from "react-redux";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Button } from '../shared/components/button.component';
import ImageUpload from '../shared/components/form/image-upload.component';
import { InputPassword } from '../shared/components/form/input-password.component';
import InputSelectInline from '../shared/components/form/input-select-inline.component';
import { InputText } from '../shared/components/form/input-text.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { PageSubTitle } from '../shared/components/page/page-sub-title.component';
import { updateUser } from '../store/actions/user.action';
import { setLocale } from '../store/actions/app.action';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from "../config";

class ConnectedUserAccountPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            submitDisable: false,
            user: {
                id: '',
                firstname: '',
                lastname: '',
                function: '',
                email: '',
                picture: '',
                locale: ''
            },
            password: '',
            confirmation: '',
            pictureFile: [],
            dirty: false,
            picture: null,
            locales: []
        };

        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePicture = this.handleChangePicture.bind(this);
        this.handleChangeUser = this.handleChangeUser.bind(this);
        this.handleClickPicture = this.handleClickPicture.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {

        const locales = Config.locales.LIST.map(item => {
            const locale = { ...item, label: this.props.intl.formatMessage({ id: item.label }) };
            return locale;
        });

        this.setState({
            user: { ...this.state.user, ...this.props.store.user },
            picture: this.props.store.user.picture ? process.env.REACT_APP_IMAGES_DIRECTORY + this.props.store.user.picture : '',
            locales: locales
        });
    }

    /**
     * After each field key press on users fields, update state of value
     * 
     * @param {*} event 
     */
    handleChangePassword(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    /**
     * Set picture file
     * 
     * @param {*} files 
     */
    handleChangePicture(files) {
        const pictureFile = files[0];
        if (pictureFile) {
            this.setState({ pictureFile: pictureFile, dirty: true, picture: URL.createObjectURL(pictureFile) });
        }
    }

    /**
     * After each field key press on users fields, update state of value
     * 
     * @param {*} event 
     */
    handleChangeUser(event) {
        const { name, value } = event.target;
        let user = this.state.user;
        user[name] = value;
        this.setState({ user: user });
    }

    /**
     * Set state values of question
     * 
     * @param {*} question 
     */
    handleClickPicture() {
        let user = this.state.user;
        user.picture = '';
        this.setState({ dirty: false, picture: null, user: user, pictureFile: [] });
    }

    /**
     * Submit user
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, submitDisable: true });
        const { user, pictureFile, password, confirmation } = this.state;
        const store = this.props.store;

        let passwordOK = true;
        if (password || confirmation) {
            if (password !== confirmation) {
                passwordOK = false;
                this.props.showError({ summary: 'app.user.password.not.confirmed' });
            } else {
                const data = {
                    id: user.id,
                    password: password,
                    passwordConfirmation: confirmation
                };
                const responsePassword = await http.patch(`/users/${user.id}/password`, data);
                if (!responsePassword.ok) {
                    passwordOK = false;
                    this.props.showError(responsePassword.error, ['app.user.not.updated']);
                } else {
                    this.props.showSuccess({ summary: 'app.user.password.updated' });
                }
            }
        }

        if (passwordOK && (
            user.firstname !== store.user.firstname ||
            user.lastname !== store.user.lastname ||
            user.email !== store.user.email ||
            user.function !== store.user.function ||
            user.locale !== store.user.locale ||
            user.picture !== store.user.picture ||
            pictureFile.name )
        ) {
            // Send picture to API, response is an anonimised reference image name
            let pictureName = user.picture;
            let pictureOK = true;
            if (pictureFile.name) {
                let dataPicture = new FormData();
                dataPicture.append('image', pictureFile);
                const responsePicture = await http.postFile('/upload/image', dataPicture);
                if (!responsePicture.ok) {
                    pictureOK = false;
                    this.props.showError(responsePicture.error, ['app.picture.not.uploaded']);
                } else {
                    pictureName = responsePicture.data.image
                }
            }

            if (pictureOK) {
                const data = {
                    user: { ...user, picture: pictureName }
                };

                const response = await http.patch(`/users/${user.id}`, data);
                if (!response.ok) {
                    this.props.showError(response.error, ['app.user.not.updated']);
                } else {
                    const user = response.data.user;
                    this.props.updateUser(user);
                    this.props.setLocale(user.locale);
                    const locales = Config.locales.LIST.map(item => {
                        const locale = { ...item, label: this.props.intl.formatMessage({ id: item.label }) };
                        return locale;
                    });
                    this.setState({ locales: locales });
                    this.props.showSuccess({ summary: 'app.user.updated' });
                }
            }
        }

        this.setState({ submitDisable: false });
    }

    render() {
        const intl = this.props.intl;
        const { user, submitted } = this.state;

        const styleButtonActions = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        };

        return (
            <Page>
                <PageCard title={intl.formatMessage({ id: 'app.user.account' })} >

                    <div className="row">
                        <div className="col-8">
                            <InputText name="firstname" label="app.firstname" value={user.firstname}
                                onChange={this.handleChangeUser} submitted={submitted} mandatory="true" />

                            <InputText name="lastname" label="app.lastname" value={user.lastname}
                                onChange={this.handleChangeUser} submitted={submitted} mandatory="true" />

                            <InputText name="email" label="app.email" value={user.email}
                                onChange={this.handleChangeUser} submitted={submitted} mandatory="true" />

                            <InputText name="function" label="app.function" value={user.function}
                                onChange={this.handleChangeUser} submitted={submitted} mandatory="true" />

                            <InputSelectInline name="locale" label="app.language" kind="profil"
                                options={this.state.locales} value={user.locale}
                                onChange={this.handleChangeUser} />

                            <PageSubTitle title={this.props.intl.formatMessage({ id: 'app.password.modify' })} />

                            <InputPassword name="password" label="app.password" value={user.password}
                                placeholder={this.props.intl.formatMessage({ id: 'app.user.password.rules' })}
                                onChange={this.handleChangePassword} />

                            <InputPassword name="confirmation" label="app.password.confirmation" value={user.confirmation}
                                onChange={this.handleChangePassword} />
                        </div>
                        <div className="col-4">
                            <ImageUpload name="picture"
                                styles={this.props.store.style} kind="primary" format="square" onClick={this.handleClickPicture}
                                onChange={this.handleChangePicture} value={this.state.picture} />
                        </div>
                    </div>

                    <div style={styleButtonActions}>
                        <div>
                            <Button label="app.save" onClick={(e) => this.handleSubmit(e)}
                                styles={this.props.store.style} kind="primary" />
                        </div>
                    </div>
                </PageCard>
            </Page >
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            user: state.user,
            style: state.style
        }
    };
};

const mapDispatchToProps = {
    updateUser,
    setLocale,
    showError, showSuccess
};

const UserAccountPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserAccountPage);

UserAccountPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(UserAccountPage);
