export const QUIZ_MANAGER_SET_DETAILS_COLLAPSED = '[QuizManager] Set details collapsed';
export const QUIZ_MANAGER_SET_MODIFY_COLLAPSED = '[QuizManager] Set modify collapsed';
export const QUIZ_MANAGER_SET_QUESTIONS_COLLAPSED = '[QuizManager] Set questions collapsed';
export const QUIZ_MANAGER_SET_QUESTIONS_NUMBER = '[QuizManager] Set questions number';
export const QUIZ_MANAGER_SET_STATISTICS_COLLAPSED = '[QuizManager] Set statistics collapsed';

export const initialState = {
    detailsCollapsed: true,
    modifyCollapsed: true,
    questionsCollapsed: false,
    statisticsCollapsed: false,
    questionsNumber: 0
}

export const setDetailsCollapsed = collapsed => {
    return {type: QUIZ_MANAGER_SET_DETAILS_COLLAPSED, detailsCollapsed: collapsed}
}

export const setModifyCollapsed = collapsed => {
    return {type: QUIZ_MANAGER_SET_MODIFY_COLLAPSED, modifyCollapsed: collapsed}
}

export const setQuestionsCollapsed = collapsed => {
    return {type: QUIZ_MANAGER_SET_QUESTIONS_COLLAPSED, questionsCollapsed: collapsed}
}

export const setQuestionsNumber = questionsNumber => {
    return {type: QUIZ_MANAGER_SET_QUESTIONS_NUMBER, questionsNumber: questionsNumber}
}

export const setStatisticsCollapsed = collapsed => {
    return {type: QUIZ_MANAGER_SET_STATISTICS_COLLAPSED, statisticsCollapsed: collapsed}
}