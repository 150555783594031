export const THEMES_SET_THEME_FILTER = '[Themes] Set theme filter';
export const THEMES_SET_DEPARTMENT_VALUE = '[Themes] Set department value';

export const initialState = {
    themeFilter: {
        title: '',
        departments: []
    },
    departmentValue: ''
}

export const setThemeFilter = filter => {
    return { type: THEMES_SET_THEME_FILTER, themeFilter: filter }
}

export const setDepartmentValue = value => {
    return { type: THEMES_SET_DEPARTMENT_VALUE, departmentValue: value }
}
