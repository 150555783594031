import React, { Component } from 'react';
import { IntlMessage } from '../../shared/components/intl-message.component';
import { Shelf } from '../../shared/components/shelf.component';
import Config from '../../config';

export class ShelfNoDepartment extends Component {

    render() {

        return (
            <Shelf>
                <div className="opp-shelf-no-department">
                    <div className="opp-shelf-no-department-bloc">
                        <p><IntlMessage id="app.no.department.registered" /></p>
                        <button type="button" onClick={() => this.props.onClick(Config.action.ADD)} className="btn btn-secondary btn-rounded btn-fw">
                            <IntlMessage id={"app.create.department"} />
                        </button>
                    </div>
                </div>
            </Shelf>
        );
    }
}
