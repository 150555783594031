import React, { Component } from "react";
import Radium from 'radium';
import { IntlMessage } from './intl-message.component';

class ButtonSimple extends Component {

    render() {
        return (
            <button ref={this.props.innerRef} type="button" className="btn"
                style={(this.props.styles && this.props.kind) ? [this.props.styles.button.base, this.props.styles.button[this.props.kind]] : '' }
                onClick={this.props.onClick.bind(this)} disabled={this.props.disabled ? this.props.disabled : false}>
                <IntlMessage id={this.props.label ? this.props.label : "app.save"} />
            </button>);
    }
}

ButtonSimple = Radium(ButtonSimple);

export const Button = React.forwardRef((props, ref) => (<ButtonSimple {...props} innerRef={ref} />));