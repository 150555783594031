export const NOTIFICATION_INITIALIZE_USERS = '[Notification] Initialize users';
export const NOTIFICATION_SET_USERS = '[Notification] Set users';

export const initialState = {
    users: []
}

export const initializeUsers = () => {
    return { type: NOTIFICATION_INITIALIZE_USERS, users: initialState.users }
}

export const setUsers = users => {
    return { type: NOTIFICATION_SET_USERS, users: users }
}
