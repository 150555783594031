import React from 'react';

const styleCard = {
    marginBottom: '1em'
};

const styleCardBody = {
    padding: '.5em'
};

export const Shelf = (props) => (
    <div className="card" style={styleCard}>
        <div className="card-body" style={styleCardBody}>{props.children}</div>
    </div>
)