import {MESSAGE_SHOW_MESSAGE, MESSAGE_RESET_MESSAGE} from '../actions/message.action';

const showMessages  = (state = {messages:[]}, action) => {
    let messages=[];
    switch (action.type) {
        case MESSAGE_SHOW_MESSAGE:
            messages.push(action.message);
            return {messages:messages};
        case MESSAGE_RESET_MESSAGE:
            return {messages:messages};
        default:
            return state
    }
}

export default showMessages;