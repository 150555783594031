import React, { Component } from 'react';
import Radium from 'radium';

import Nav from './nav.component';
import SideMenu from './sidemenu.component';

class PageContainer extends Component {

    render() {

        const styleContainer = {
            base: {
                overflow: 'hidden'
            }
        }

        const styleBodyWrapper = {
            base: {
                minHeight: 'calc(100vh - 70px)',
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                paddingTop: '70px',
                paddingRight: '0',
                paddingLeft: '0',
                marginRight: 'auto',
                marginLeft: 'auto'
            }
        }

        const styleMainPanel = {
            base: {
                transition: 'width 0.25s ease, margin 0.25s ease',
                width: '100%',
                minHeight: 'calc(100vh - 70px)',
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '255px',
                marginRight: '0'
            }
        }

        const styleContentWrapper = {
            base: {
                background: '#f2f2f2',
                padding: '1.5rem 1.7rem',
                width: '100%',
                flexGrow: '1'
            }
        }

        return (
            <div style={[styleContainer.base]}>
                <Nav />
                <div style={[styleBodyWrapper.base]}>
                    <SideMenu />
                    <div style={[styleMainPanel.base]}>
                        <div style={[styleContentWrapper.base]}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageContainer = Radium(PageContainer);