import React from 'react';
import { IntlMessage } from '../intl-message.component';
import Config from '../../../config';

const containerClass = "form-group";
const containerStyle = {};

const labelClass = "";
const labelStyle = {};

const inputClass = "form-control ui-corner-all";
const inputStyle = {};

const mandatoryClass = "help-block";
const mandatoryStyle = {};

const inputType = "text";

export const InputText = React.forwardRef((props, ref) => (
    <div className={props.containerClass ? props.containerClass : containerClass}
        style={props.containerStyle ? props.containerStyle : containerStyle}>

        <label className={props.labelClass ? props.labelClass : labelClass}
            style={props.labelStyle ? props.labelStyle : labelStyle}>
            {props.label && (<IntlMessage id={props.label} />)}
        </label>

        <input type={props.inputType ? props.inputType : inputType} ref={ref} id={props.name} name={props.name}
            className={props.inputClass ? props.inputClass : inputClass}
            style={props.inputStyle ? props.inputStyle : inputStyle}
            value={props.value} placeholder={props.placeholder}
            maxLength={props.maxLength ? props.maxLength : Config.inputText.DEFAULT_MAX_LENGTH}
            onChange={props.onChange.bind(this)}
            disabled={props.disabled ? props.disabled : false}>
        </input>

        {props.submitted && props.mandatory && !props.value &&
            <div className={props.mandatoryClass ? props.mandatoryClass : mandatoryClass}
                style={props.mandatoryStyle ? props.mandatoryStyle : mandatoryStyle}>
                <IntlMessage id="app.mandatory.field" />
            </div>
        }
    </div>
));