import React, { Component } from "react";
import { connect } from "react-redux";
import Radium from 'radium';
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Button } from '../shared/components/button.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import InputSelect from '../shared/components/form/input-select.component';
import NotificationDetail from './components/notification-detail.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { initializeUsers } from '../store/actions/notification.action';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';

class ConnectedUserNotificationPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            company: [],
            error: '',
            lastPage: '',
            locales: [Config.app.DEFAULT_LOCALE],
            message: [],
            quiz: null,
            quizOptions: [],
            users: [],
            submitted: false,
            submitDisable: true
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleChangeQuiz = this.handleChangeQuiz.bind(this);
        this.handleChangeMessage = this.handleChangeMessage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        let company = [];
        let superadmin = false;

        if (this.props.store.users.company.length !== 0) {
            if (this.props.store.user.role !== Config.app.role.SUPER_ADMIN) {
                this.props.showError({ summary: 'app.error.forbidden' });
                history.push('/');
            }
            superadmin = true;
            company = this.props.store.users.company;
        } else {
            superadmin = false;
            company = this.props.store.company
        }

        const [ responseCompany, responsePublic ] = await Promise.all([
            http.get(`/manager/quizzes/company/${company.id}`),
            http.get(`/manager/quizzes?company=${company.id}&public=true`)
        ]);
        
        let quizOptions = [];
        if (responseCompany.ok) {
            quizOptions = responseCompany.data.quizzes.map(item => {
                return { label: item.title, value: item.id };
            });
        }

        if (responsePublic.ok) {
            let quizzesPublic = [];
            quizzesPublic = responsePublic.data.quizzes.map(item => {
                return { label: item.title, value: item.id };
            });

            quizzesPublic.forEach(quiz => {
                const found = quizOptions.find(option => option.id === quiz.id);
                if (found) {
                    quizOptions.push(quiz);
                }
            });
        }

        this.setState({
            company: company,
            lastPage: this.props.store.app.lastPage,
            quizOptions: quizOptions,
            superadmin: superadmin,
            users: this.props.store.notification.users
        });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                this.props.initializeUsers();
                history.push(this.state.lastPage);
                break;
            default:
        }
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChangeLocales(event) {
        let locales = this.state.locales;
        if (event.value.length > 0) {
            locales = event.value;
        }
        this.setState({ locales: locales });
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChangeQuiz(event) {
        this.setState({ quiz: event.target.value });
    }

    /**
     * Set state values of message
     * 
     * @param {*} message 
     */
    handleChangeMessage(message) {
        this.setState({ message: message });
    }

    /**
     * Submit notification
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, submitDisable: true });
        if (Object.keys(this.state.message).length > 0 && this.state.locales.length > 0 && this.state.users.length > 0) {
            let message = this.state.message;
            for (var key in message) {
                if (message.hasOwnProperty(key) && !this.state.locales.includes(key)) {
                    delete message[key];
                }
            }

            let data = {
                message: this.state.message,
                users: this.state.users
            };

            if (this.state.quiz && this.state.quiz !== 'none') {
                data = { ...data, quizId: this.state.quiz };
            }

            const response = await http.post(`/manager/companies/${this.props.store.company.id}/notifications`, data);
            if (!response.ok) {
                this.props.showError(response.error);
            } else {
                this.props.showSuccess({ summary: 'app.notification.sent' });
                this.props.initializeUsers();
                history.push(this.state.lastPage);
            }
        }
        this.setState({ submitDisable: false });
    }

    render() {

        const dynamicDetails = this.state.locales.map((locale) => {
            return <NotificationDetail key={locale} lang={locale} message={this.state.message}
                onChange={this.handleChangeMessage} submitted={this.state.submitted} styles={this.props.store.style} />
        });

        return (
            <Page title={this.state.superadmin ? this.state.company.name : ''}>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.notification' })} onClick={this.handleAction} back={Config.action.BACK}>

                    <div className="row" style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
                        <div className="col-sm-6">
                            <InputSelect name="quiz" label="app.linked.quiz" onChange={this.handleChangeQuiz} none={true}
                                options={this.state.quizOptions} value={this.state.quiz} kind="notification" />
                        </div>
                        <div className="col-sm-6"></div>
                    </div>

                    <InputMultiSelectInline name="locales" label="app.language" kind="message"
                        options={Config.locales.LIST} value={this.state.locales}
                        onChange={this.handleChangeLocales} />

                    {dynamicDetails}

                    <div className="float-right">
                        <Button label="app.save" onClick={(e) => this.handleSubmit(e)}
                            styles={this.props.store.style} kind="primary" />
                    </div>
                </PageCard>
            </Page>
        );
    }
}

ConnectedUserNotificationPage = Radium(ConnectedUserNotificationPage);

const mapStateToProps = state => {
    return {
        store: {
            app: state.app,
            company: state.company,
            notification: state.notification,
            style: state.style,
            user: state.user,
            users: state.users
        }
    };
};

const mapDispatchToProps = {
    initializeUsers,
    showError, showSuccess
};

const UserNotificationPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserNotificationPage);

UserNotificationPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(UserNotificationPage);
