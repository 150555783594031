export const sortLabel = (a, b) => {
    if ( a.label.trim() < b.label.trim() ){
        return -1;
      }
      if ( a.label.trim() > b.label.trim() ){
        return 1;
      }
      return 0;
};

export const sortStatement = (a, b) => {
    if ( a.statement.trim() < b.statement.trim() ){
        return -1;
      }
      if ( a.statement.trim() > b.statement.trim() ){
        return 1;
      }
      return 0;
};