import { initialState, SET_COMPANY, INITIALIZE_COMPANY } from '../actions/company.action';

const company = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANY:
            return { ...state, ...action.company };
        case INITIALIZE_COMPANY:
            return initialState;
        default:
            return state
    }
}

export default company;