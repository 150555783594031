import React, {Component} from "react";
import {httpService as http} from "./services/http.service";

class EmailValidationPage extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            ok: false,
            error: false
        };
    }

    componentDidMount() {
        http.patch('/users/check/' + this.props.match.params.token)
            .then(response => {
                if (response.ok) {
                    this.setState({ok: true});
                    //window.location = 'BusinessRoyale://';
                } else {
                    this.setState({error: true});
                }
        });
    }

    render() {
        const {ok, error} = this.state;

        const styleEmailValidationContainer = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            background: 'url(\'templates/default/images/img_connexion.jpg\')',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }

        const styleEmailValidation = {
            color: '#ffffff',
            fontSize: '1.5em',
            textAlign: 'center'
        }

        return (
            <div style={styleEmailValidationContainer}>
                <div style={styleEmailValidation}>
                    { !ok && !error && <span>Attendre...</span> }
                    { ok && !error && <span>Votre adresse email est validée!</span> }
                    { !ok && error && <span>Requête invalide, veuillez redemander une validation de votre email.</span> }
                </div>
            </div>
        );
    }
}

export default EmailValidationPage;
