import React from 'react';

export const InputEmail = (props) => (
    <div className="form-group row">
        <label className="col-sm-3 col-form-label">{props.label}</label>
        <div className="col-sm-9">
            <input type="email" className="form-control" id={props.name} name={props.name} value={props.value}
                placeholder={props.placeholder} onChange={props.onChange.bind(this)}></input>
            {props.submitted && props.mandatory && !props.value &&
                <div className="help-block">*Ce champ est obligatoire</div>
            }
        </div>
    </div>
)