export const USERS_SET_COMPANY = '[Users] Set company';
export const USERS_INITIALIZE_COMPANY = '[Users] Initialize company';

export const initialState = {
    company: []
}

export const setCompany = company => {
    return { type: USERS_SET_COMPANY, company: company }
}

export const initializeCompany = () => {
    return { type: USERS_INITIALIZE_COMPANY, company: initialState.company }
}
