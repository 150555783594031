import React, {Component} from "react";
import {PageHeader} from '../shared/components/page-header.component';
import {httpService as http} from "../core/services/http.service";
import {historyHelper as history} from "../core/helpers/history.helper";
import {Form} from '../shared/components/form/form.component';
import {InputText} from '../shared/components/form/input-text.component';
import {InputEmail} from '../shared/components/form/input-email.component';

class CompanyCreatePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            companyName: '',
            firstname: '',
            lastname: '',
            email: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({[name]: value});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({submitted: true});
        const {companyName, firstname, lastname, email} = this.state;
        if (companyName && firstname && lastname && email) {
            const data = { 
                company: { name: companyName },
                user: {
                    lastname: lastname,
                    firstname: firstname,  
                    email: email
                }
            };
            http.post('/manager/companies', data)
                .then(response => {
                    if (response.ok) {
                        history.push('/companies');
                    }
                });
        }
    }

    render() {
        const {submitted, companyName, firstname, lastname, email} = this.state;

        // Breadcrumb
        const second = { id: 'companies', to: '/companies', defaultMessage: 'Entreprises' };
        const third = { id: 'new', defaultMessage: 'Nouveau' };

        return (
            <div className="page">
                <PageHeader title="Créer une entreprise" second={second} third={third} />
                <Form onSubmit={this.handleSubmit}>
                    <InputText name="companyName" label="Nom de l'entreprise" value={companyName}
                        onChange={this.handleChange} submitted={submitted} mandatory="true" />

                    <InputText name="firstname" label="Prénom du représentant" value={firstname}
                        onChange={this.handleChange} submitted={submitted} mandatory="true" />

                    <InputText name="lastname" label="Nom du représentant" value={lastname}
                        onChange={this.handleChange} submitted={submitted} mandatory="true" />

                    <InputEmail name="email" label="Email" value={email}
                        onChange={this.handleChange} submitted={submitted} mandatory="true" />

                    <button type="submit" className="btn btn-primary mr-2">Créer</button>
                </Form>
            </div>
        );
    }
}

export default CompanyCreatePage;
