import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';

import { ButtonRoundedIcon } from '../../shared/components/button-rounded-icon.component';
import UserBox from '../../shared/components/user-box.component';
import { Shelf } from '../../shared/components/shelf.component';
import Config from '../../config';

class ConnectedShelfGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false
        };
    }

    render() {
        const { group, store: { style } } = this.props;

        const styleHeaderContainer = {
            base: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: '.5em'
            }
        }

        const styleUserContainer = {
            base: {
                display: 'flex',
                flexDirection: 'row'
            }
        };

        const dynamicAdminsIcons = group.admins.map((val, i) => {
            return <UserBox key={i} user={val} department={group} />
        });

        return (
            <Shelf>
                <div style={[styleHeaderContainer.base]}>
                    <h5>{group.name}</h5>
                    <div>
                        <ButtonRoundedIcon icon="fa fa-trophy"
                            onClick={(e) => this.props.onClick(Config.action.RANKING, { id: group.id })}
                            styles={style} kind="secondary" />
                        <ButtonRoundedIcon icon="fa fa-pen"
                            onClick={(e) => this.props.onClick(Config.action.MODIFY, { id: group.id })}
                            styles={style} kind="secondary" />
                    </div>
                </div>
                <div style={[styleUserContainer.base]}>
                    {dynamicAdminsIcons}
                </div>
            </Shelf>
        );
    }
}

ConnectedShelfGroup = Radium(ConnectedShelfGroup);

const mapStateToProps = ({ style }) => {
    return { store: { style } };
};

const ShelfGroup = connect(mapStateToProps, null)(ConnectedShelfGroup);

export default ShelfGroup;
