import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';
import { sortLabel } from "../core/helpers/tools.helper";

import ButtonRounded from '../shared/components/button-rounded.component';
import { ButtonRoundedIcon } from '../shared/components/button-rounded-icon.component';
import Dialog from '../shared/components/dialog.component';
import { InputAutoComplete } from '../shared/components/form/input-autocomplete.component';
import InputSelect from '../shared/components/form/input-select.component';
import InputSelectInline from '../shared/components/form/input-select-inline.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { PageTable } from '../shared/components/page-table.component';
import { setPage } from '../store/actions/app.action';
import { setLastPage } from '../store/actions/app.action';
import { showError, showSuccess, showWarning } from '../store/actions/message.action';
import { setUsers } from '../store/actions/notification.action';
import { setCompany, initializeCompany } from '../store/actions/users.action';
import Config from '../config';

class ConnectedUsersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            actionOptions: [],
            active: 'all',
            activeTypes: [],
            availableDepartments: [],
            availableLocales: Config.locales.LIST,
            checked: [],
            company: [],
            dialog: false,
            dialogInvite: false,
            dialogUser: [],
            filteredDepartments: null,
            filtredUsers: [],
            invited: 'all',
            invitedTypes: [],
            superadmin: false,
            users: [],
            userFilter: {
                active: 'all',
                name: '',
                departments: [],
                invited: 'all',
                locale: ''
            }
        };

        this.getUsers = this.getUsers.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.handleActionList = this.handleActionList.bind(this);
        this.handleChangeActive = this.handleChangeActive.bind(this);
        this.handleChangeInvited = this.handleChangeInvited.bind(this);
        this.handleCheckAction = this.handleCheckAction.bind(this);
        this.handleDialogOnHide = this.handleDialogOnHide.bind(this);
        this.handleDeleteUserOnSubmit = this.handleDeleteUserOnSubmit.bind(this);
        this.handleFilterDepartments = this.handleFilterDepartments.bind(this);
        this.handleFilterLocales = this.handleFilterLocales.bind(this);
        this.handleFilterUsers = this.handleFilterUsers.bind(this);
        this.handleFindUsers = this.handleFindUsers.bind(this);
        this.handleInviteUsersOnSubmit = this.handleInviteUsersOnSubmit.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    async componentDidMount() {
        this.props.setPage(Config.page.USERS);
        const { id } = this.props.match.params;

        let actionOptions = [];
        let activeTypes = Config.active.TYPE.map(item => {
            return { ...item, label: this.props.intl.formatMessage({ id: item.label }) };
        });
        let availableDepartments = [];
        let availableLocales = [];
        let company = [];
        let departments = [];
        let invitedTypes = Config.invited.TYPE.map(item => {
            return { ...item, label: this.props.intl.formatMessage({ id: item.label }) };
        });
        let superadmin = false;
        let users = [];

        if (id) {
            if (this.props.store.user.role !== Config.app.role.SUPER_ADMIN) {
                this.props.showError({ summary: 'app.error.forbidden' });
                history.push('/');
            }

            superadmin = true;
            const responseCompany = await http.get(`/manager/companies/${id}`);

            if (!responseCompany.ok) {
                this.props.showError(responseCompany.error);
            } else {
                company = responseCompany.data.company;
                this.props.setCompany(company);
            }

        } else {
            this.props.initializeCompany();
            company = this.props.store.company;
        }

        users = await this.getUsers(company.id);

        // Get list of available departments of company
        const responseDepartment = await http.get(`/manager/companies/${company.id}/departments`);
        if (!responseDepartment.ok) {
            this.props.showError(responseDepartment.error);
        } else {
            departments = responseDepartment.data.departments;

            availableDepartments = departments.map(item => {
                return { label: item.name, value: item.name };
            });
        }

        availableLocales = this.state.availableLocales.map(item => {
            return { label: this.props.intl.formatMessage({ id: item.label }), value: item.value };
        });

        actionOptions = [
            { label: this.props.intl.formatMessage({ id: 'app.invite' }), value: 'invite' },
            { label: this.props.intl.formatMessage({ id: 'app.notification' }), value: 'notification' }
        ];

        availableDepartments.sort(sortLabel);

        this.setState({ actionOptions, activeTypes, availableDepartments, availableLocales,
            company, filtredUsers: users, invitedTypes, users, superadmin });
    }

    async getUsers(companyId, userFilter = []) {
        let users = [];

        let filter = '';
        if (userFilter.department) {
            filter = `?department=${userFilter.department}`;
        }

        const response = await http.get(`/manager/companies/${companyId}/users${filter}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            users = response.data.users.map(item => {
                const departments = item.departments;
                const departmentsList = departments.map(departmentItem => {
                    return departmentItem.name;
                });

                return { ...item, departments: departmentsList.toString().replace(/,/g, ', '), };
            });
        }

        return users;
    }

    handleAction(action, data) {
        if (!this.state.superadmin) {
            this.props.setLastPage(`/users`);
        } else {
            this.props.setLastPage(`/companies/${this.state.company.id}/users`);
        }

        switch (action) {
            case 'add':
                history.push(`/users/new`);
                break;
            case Config.action.DELETE:
                this.setState({ dialog: true, dialogUser: data });
                break;
            case Config.action.IMPORT:
                history.push(`/users/upload`);
                break;
            case 'modify':
                history.push(`/users/modify/${data.id}`);
                break;
            default:
        }
    }

    /**
     * Hide user delete
     */
    handleActionList(event) {
        switch (event.target.value) {
            case 'invite':
                this.setState({ dialogInvite: true });
                break;
            case 'notification':
                if (this.state.checked.length === 0) {
                    this.props.showError({ summary: 'app.error.no.user.selected' });
                } else {
                    this.props.setUsers(this.state.checked);
                    this.props.setLastPage(`/users`);
                    history.push(`/users/notification`);
                }
                break;
            default:
        }
    }

    /**
     * After each field key press, update state of the value
     *
     * @param {*} event 
     */
    async handleChangeActive(event) {
        if (event.target.value) {
            let userFilter = this.state.userFilter;
            userFilter.active = event.target.value;
            this.handleFilterUsers(userFilter, this.state.users);
            this.setState({ active: event.target.value });
        }
    }

    /**
     * After each field key press, update state of the value
     *
     * @param {*} event 
     */
    async handleChangeInvited(event) {
        if (event.target.value) {
            let userFilter = this.state.userFilter;
            userFilter.invited = event.target.value;
            this.handleFilterUsers(userFilter, this.state.users);
            this.setState({ invited: event.target.value });
        }
    }

    /**
     * Hide user delete
     */
    handleCheckAction(event) {
        let checked = this.state.checked;
        if (event.value === 'all') {
            if (event.checked) {
                let filtredUsers = this.state.filtredUsers;
                checked = filtredUsers.map(item => {
                    return item.id;
                });
            } else {
                checked = [];
            }
            this.setState({ checked: checked });
        } else {
            const index = checked.indexOf(event.value);
            if (event.checked) {
                if (index === -1) {
                    checked.push(event.value);
                    this.setState({ checked: checked });
                }
            } else {
                if (index !== -1) {
                    checked.splice(index, 1);
                    this.setState({ checked: checked });
                }
            }
        }
    }

    /**
     * Hide user delete
     */
    handleDialogOnHide() {
        this.setState({ dialog: false, dialogUser: [], dialogInvite: false, checked: [] });
    }

    /**
     * Submit user delete
     */
    async handleDeleteUserOnSubmit() {
        let userFilter = this.state.userFilter;
        let users = this.state.users;
        const response = await http.del(`/manager/companies/${this.state.company.id}/users/${this.state.dialogUser.id}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            this.props.showSuccess({ summary: 'app.user.deleted' });
            const index = await users.map(function (e) { return e.id; }).indexOf(this.state.dialogUser.id);
            users.splice(index, 1);
        }
        this.setState({ users: users, dialog: false, dialogUser: [] });
        this.handleFilterUsers(userFilter, users);
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} userFilter
     * @param {*} departments
     */
    async handleFilterUsers(userFilter, users) {
        const name = userFilter.name.toLowerCase();
        const departments = userFilter.departments.toString().toLowerCase();
        const results = await users.filter((user) => {
            const searchKey = user.firstname + ' ' + user.lastname;
            return (!name || searchKey.toLowerCase().includes(name)) &&
                (!departments || user.departments.toLowerCase().includes(departments)) &&
                (userFilter.active === 'all' ||
                    (userFilter.active === 'ok' && user.active === 1) ||
                    (userFilter.active === 'not' && user.active === 0)) &&
                (userFilter.invited === 'all' ||
                    (userFilter.invited === 'done' && user.invited === 'done') ||
                    (userFilter.invited === 'new' && user.invited === 'new') ||
                    (userFilter.invited === 'waiting' && user.invited === 'waiting')) &&
                (userFilter.locale === '' || user.locale === userFilter.locale);
        });
        this.setState({ filtredUsers: results, userFilter: userFilter });
    }

    /**
     * Filter departments
     * 
     * @param {*} event 
     */
    handleFilterDepartments(event) {
        let userFilter = this.state.userFilter;
        let filterDepartment = [];
        if (event.target.value === 'all') {
            filterDepartment = [];
        } else {
            filterDepartment = [event.target.value];
        }
        userFilter.departments = filterDepartment;
        this.handleFilterUsers(userFilter, this.state.users);
    }

    /**
     * Filter locales
     * 
     * @param {*} event 
     */
    handleFilterLocales(event) {
        let userFilter = this.state.userFilter;
        let locale = '';
        if (event.target.value === 'all') {
            locale = '';
        } else {
            locale = event.target.value;
        }
        userFilter.locale = locale;
        this.handleFilterUsers(userFilter, this.state.users);
    }

    /**
      * Find users
      * 
      * @param {*} event 
      */
    async handleFindUsers(event) {
        let userFilter = this.state.userFilter;
        userFilter.name = event.value;
        this.handleFilterUsers(userFilter, this.state.users);
    }

    /**
     * Submit user invite
     */
    async handleInviteUsersOnSubmit() {
        const data = {
            users: this.state.checked
        };
        const response = await http.post(`/manager/companies/${this.state.company.id}/users/invite`, data);
        if (!response.ok) {
            this.props.showError(response.error);
            this.setState({ dialogInvite: false });
        } else {
            this.props.showSuccess({ summary: 'app.process.in.progress' });
            this.setState({ dialogInvite: false, checked: [] });
        }
    }

    /**
      * Refresh users
      * 
      * @param {*} event 
      */
    async handleRefresh() {
        const { company, userFilter } = this.state;
        const users = await this.getUsers(company.id, userFilter);
        this.setState({ users: users });
        this.handleFilterUsers(userFilter, users);
    }

    render() {
        const styleHeaderContainer = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        };

        const styleHeaderSelection = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            flexWrap: 'wrap'
        };

        const styleHeaderActions = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        };

        const styleTableHeaderContainer = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        }

        const pageHeader = (
            <div style={styleHeaderContainer}>
                <div style={styleHeaderSelection}>
                    <InputSelect name="department" label="app.department" onChange={this.handleFilterDepartments} all={true}
                        options={this.state.availableDepartments} value={this.state.departmentValue} />

                    <InputSelect name="locale" label="app.language" onChange={this.handleFilterLocales} all={true}
                        options={this.state.availableLocales} value={this.state.localeValue} />

                    <InputAutoComplete field="name" label="app.find" size="60"
                        onChange={this.handleFindUsers} value={this.state.userFilter.name} />

                    <div style={{ marginBottom: '.5em' }}>
                        <InputSelectInline name="invited"
                            options={this.state.invitedTypes} value={this.state.invited}
                            onChange={this.handleChangeInvited} />
                    </div>

                    <div style={{ marginLeft: '1em', marginBottom: '.5em' }}>
                        <InputSelectInline name="active"
                            options={this.state.activeTypes} value={this.state.active}
                            onChange={this.handleChangeActive} />
                    </div>
                </div>
                <div style={styleHeaderActions}>
                    <div style={{ marginRight: '.5em' }}>
                        <ButtonRounded label="app.create.user" onClick={() => this.handleAction(Config.action.ADD)}
                            styles={this.props.store.style} kind="expandedPrimary" />
                    </div>
                    <div>
                        <ButtonRounded label="app.import.users" onClick={() => this.handleAction(Config.action.IMPORT)}
                            styles={this.props.store.style} kind="expandedSecondary" />
                    </div>
                </div>
            </div>
        );

        const invitedColors = {
            new: this.props.store.style.color.danger.base,
            waiting: '#f5a623',
            done: this.props.store.style.color.primary.base,
        };

        // Column list
        const cols = [
            { field: 'check', header: 'check', disabled: 'free' },
            { field: 'lastname', header: 'app.name' },
            { field: 'firstname', header: 'app.firstname' },
            { field: 'departments', header: 'app.departments' },
            { field: 'function', header: 'app.function.short' },
            { field: 'active', header: 'app.active', icon: 'fa fa-check', switch: true, disabled: 'free' },
            { field: 'invited', header: 'app.invited', icon: 'fa fa-envelope', colors: invitedColors, disabled: 'free' },
            { field: 'email', header: 'app.email' },
            { field: 'lastLogin', header: 'app.lastLogin' },
            { field: 'action', header: 'action' }
        ];

        // Body buttons list
        const actionButtons = [
            { action: Config.action.MODIFY, icon: 'fa fa-pen', disabled: 'protected' },
            { action: Config.action.DELETE, icon: 'fa fa-trash', disabled: 'protected' }
        ];

        return (
            <Page header={pageHeader} title={this.state.superadmin ? this.state.company.name : ''}>
                <PageCard>
                    <div style={styleTableHeaderContainer}>
                        <div style={{ width: '12rem' }}>
                            <InputSelect name="action" onChange={this.handleActionList} placeholder={this.props.intl.formatMessage({ id: 'app.with.selection' })}
                                options={this.state.actionOptions} value="" />
                        </div>
                        <div>
                            <ButtonRoundedIcon icon="fa fa-sync-alt" onClick={() => this.handleRefresh()}
                                styles={this.props.store.style} kind="secondary" />
                        </div>
                    </div>
                    <PageTable cols={cols} values={this.state.filtredUsers} actionButtons={actionButtons}
                        onClick={this.handleAction} styles={this.props.store.style}
                        onCheck={this.handleCheckAction} checked={this.state.checked} />
                </PageCard>
                <Dialog visible={this.state.dialog}
                    text={this.props.intl.formatMessage({ id: 'app.dialog.delete.user' }, { firstname: this.state.dialogUser.firstname, lastname: this.state.dialogUser.lastname })}
                    onHide={this.handleDialogOnHide} onSubmit={this.handleDeleteUserOnSubmit} />
                <Dialog visible={this.state.dialogInvite}
                    text={this.props.intl.formatMessage({ id: 'app.dialog.invite.users' }, { count: this.state.checked.length })}
                    onHide={this.handleDialogOnHide} onSubmit={this.handleInviteUsersOnSubmit} />
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            style: state.style,
            user: state.user,
            users: state.users
        }
    };
};

const mapDispatchToProps = {
    setPage,
    setLastPage,
    setUsers,
    showError, showSuccess, showWarning,
    setCompany, initializeCompany
};

const UsersPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedUsersPage);

UsersPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(UsersPage);
