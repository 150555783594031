import { initialState, SET_STYLE } from '../actions/style.action';

const style = (state = initialState, action) => {
    switch (action.type) {
        case SET_STYLE:
            return action.state;
        default:
            return state
    }
}

export default style;