import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';

export class ConnectedCompanyBox extends Component {
    render() {
        const styleContainer = { 
            base: {
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '1.5rem'
            }
        };

        const styleLogo = {
            base: {
                height: '6rem'
            }
        };

        const styleInfo = {
            base: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                marginLeft: '1rem'
            }
        };

        const styleLink = {
            base: {
                color: '#007ad9'
            }
        };
        return (
            <div style={[styleContainer.base]}>
                {this.props.company.logo && (<div><img style={[styleLogo.base]} src={process.env.REACT_APP_IMAGES_DIRECTORY + this.props.company.logo} alt={'Logo'} /></div>)}
                <div style={[styleInfo.base]}>
                    <div>{this.props.company.name}</div>
                    {this.props.company.website && (<div><a href={'http://' + this.props.company.website} target="_blank" rel="noopener noreferrer" style={[styleLink.base]}>{this.props.company.website}</a></div>)}
                </div>
            </div>
        )
    }
}

ConnectedCompanyBox = Radium(ConnectedCompanyBox);

const mapStateToProps = state => {
    return { 
        store: {
            style: state.style.companyBox
        }
    };
};

const CompanyBox = connect(mapStateToProps, null)(ConnectedCompanyBox);

export default CompanyBox;