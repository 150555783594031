import React from 'react';
import { IntlMessage } from '../intl-message.component';
import { MultiSelect } from 'primereact/multiselect';

export const InputMultiSelect = (props) => (
    <div className="form-group" style={props.styles ? props.styles : []}>
        {props.label && (<label><IntlMessage id={props.label} /></label>)}
        <MultiSelect value={props.values} options={props.options} onChange={props.onChange.bind(this)} filter={true} style={{ width: '100%' }} />
        {props.submitted && props.mandatory && (!props.values || (props.values.length === 0)) &&
            <div className="help-block">*<IntlMessage id={props.alert ? props.alert : 'default'} defaultMessage="Veuillez sélectionner une option" /></div>
        }
    </div>
)