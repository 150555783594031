import {
    initialState,
    DEPARTMENT_MODIFY_INITIALIZE,
    DEPARTMENT_MODIFY_SET_FILTER_NAME
} from '../actions/department-modify.action';

const departmentModify = (state = initialState, action) => {
    switch (action.type) {
        case DEPARTMENT_MODIFY_INITIALIZE:
            return initialState;
        case DEPARTMENT_MODIFY_SET_FILTER_NAME:
            return { ...state, filterName: action.filterName };
        default:
            return state
    }
}

export default departmentModify;