import React, { Component } from "react";
import { connect } from "react-redux";
import { httpService } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { ButtonRoundedIcon } from '../shared/components/button-rounded-icon.component';
import CircularRate from '../shared/components/circular-rate.component';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

class ConnectedQuizStatisticsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ranking: [],
            statistics: []
        };

        this.handleExport = this.handleExport.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.params;
        const company = this.props.store.company;

        let ranking = [];
        let statistics = [];

        //Get ranking of a quiz
        const getRanking = new Promise(function (resolve, reject) {
            httpService.get(`/manager/quizzes/${id}/companies/${company.id}/ranking`)
                .then(response => {
                    if (response.ok) {
                        resolve(response.data.ranking);
                    } else {
                        reject(ranking);
                    }
                });
        });

        //Get Statistics of a quiz
        const getStatistics = new Promise(function (resolve, reject) {
            httpService.get(`/manager/quizzes/${id}/companies/${company.id}/statistics`)
                .then(response => {
                    if (response.ok) {
                        const data = response.data;

                        statistics = {
                            participationRate: (100 * (data.activePlayersCount / data.totalPlayersCount)),
                            participationText: `${data.activePlayersCount} / ${data.totalPlayersCount}`,
                            averageCompletion: (100 * (data.totalResultsCount / data.questionsCount) / data.activePlayersCount),
                            averageAccuracy: (100 * data.accuracyRate)
                        }

                        resolve(statistics);
                    } else {
                        reject(statistics);
                    }
                });
        });

        Promise.all([getRanking, getStatistics]).then(values => {
            this.setState({
                ranking: values[0],
                statistics: values[1]
            });
        });
    }

    /**
     * CSV export
     */
    handleExport() {
        this.dt.exportCSV();
    }

    render() {
        const intl = this.props.intl;

        const styleHeaderContainer = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            lineHeight: '2rem'
        }

        const header = <div style={styleHeaderContainer}>
            <div className="p-clearfix" style={{ lineHeight: '2rem', textAlign: 'center' }}>{intl.formatMessage({ id: 'app.players' }).toUpperCase() + ' : ' + this.state.ranking.length}</div>
            <div>
                <ButtonRoundedIcon icon="fa fa-external-link-alt" onClick={this.handleExport}
                    styles={this.props.store.style} kind="secondary" />
            </div>
        </div>;

        const styleContainerStatistics = {
            padding: '2em 0 2rem 0'
        };

        const styleContainerProgressBars = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            alignContent: 'stretch',
            alignItems: 'flex-end',
            margin: '0 0 3rem 0'
        };

        const styleContainerRanking = {
        };

        return (
            <div style={styleContainerStatistics}>
                <div style={styleContainerProgressBars}>
                    <CircularRate name={intl.formatMessage({ id: 'app.quiz.statistics.contribution' })} rate={this.state.statistics.participationRate ? Number(this.state.statistics.participationRate) : 0} styles={this.props.store.style} text={this.state.statistics.participationText} />
                    <CircularRate name={intl.formatMessage({ id: 'app.quiz.statistics.completion' })} rate={this.state.statistics.averageCompletion ? Number(this.state.statistics.averageCompletion) : 0} styles={this.props.store.style} />
                    <CircularRate name={intl.formatMessage({ id: 'app.quiz.statistics.correct' })} rate={this.state.statistics.averageAccuracy ? Number(this.state.statistics.averageAccuracy) : 0} styles={this.props.store.style} />
                </div>
                <div style={styleContainerRanking}>
                    <DataTable value={this.state.ranking} paginator={true} rows={30} scrollable={true} responsive={true} ref={(el) => { this.dt = el; }}
                        header={header} footer={this.state.ranking.length === 0 ? (<div style={{ textAlign: 'left' }}>{intl.formatMessage({ id: 'app.no.data' }).toUpperCase()}</div>) : null}>
                        <Column field="position" header="#" sortable={true} style={{ width: '3rem', textAlign: 'center', paddingLeft: '.2rem', paddingRight: '.2rem' }} />
                        <Column field="name" header={intl.formatMessage({ id: 'app.name' })} sortable={true} filter={true} filterMatchMode="contains" style={{ width: '12rem' }} />
                        <Column field="bestScore" header={intl.formatMessage({ id: 'app.best.score' })} sortable={true} style={{ width: '9rem', textAlign: 'center', paddingLeft: '.1rem', paddingRight: '.1rem' }} />
                        <Column field="winLost" header={intl.formatMessage({ id: 'app.players.win.lost' })} sortable={true} style={{ width: '5rem', textAlign: 'center', paddingLeft: '.1rem', paddingRight: '.1rem' }} />
                        <Column field="totalCoins" header={intl.formatMessage({ id: 'app.total.coins' })} sortable={true} style={{ width: '9rem', textAlign: 'center', paddingLeft: '.2rem', paddingRight: '.2rem' }} />
                        <Column field="playedNumber" header={intl.formatMessage({ id: 'app.played' })} sortable={true} style={{ width: '6rem', textAlign: 'center', paddingLeft: '.1rem', paddingRight: '.1rem' }} />
                        <Column field="level" header={intl.formatMessage({ id: 'app.level' })} sortable={true} style={{ width: '6rem', textAlign: 'center', paddingLeft: '.2rem', paddingRight: '.2rem' }} />
                        <Column field="completionRate" header={intl.formatMessage({ id: 'app.completion.rate' })} sortable={true} style={{ width: '9rem', textAlign: 'center', paddingLeft: '.1rem', paddingRight: '.1rem' }} />
                        <Column field="correctRate" header={intl.formatMessage({ id: 'app.correct.answer.rate' })} sortable={true} style={{ width: '9rem', textAlign: 'center', paddingLeft: '.1rem', paddingRight: '.1rem' }} />
                    </DataTable>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            quizManager: state.quizManager,
            style: state.style,
        }
    };
};

const QuizStatisticsPage = connect(mapStateToProps, null)(ConnectedQuizStatisticsPage);

QuizStatisticsPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizStatisticsPage);