export const DEPARTMENT_MODIFY_INITIALIZE = '[Department modify] Initialize';
export const DEPARTMENT_MODIFY_SET_FILTER_NAME = '[Department modify] Set filter name';

export const initialState = {
    filterName: ''
}

export const initializeDepartmentModify = () => {
    return { type: DEPARTMENT_MODIFY_INITIALIZE, departmentCreate: initialState }
}

export const setFilterName = name => {
    return { type: DEPARTMENT_MODIFY_SET_FILTER_NAME, filterName: name }
}
