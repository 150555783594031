import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Button } from '../shared/components/button.component';
import ImageUpload from '../shared/components/form/image-upload.component';
import InputSwitch from '../shared/components/form/input-switch.component';
import { InputMultiSelect } from '../shared/components/form/input-multiselect.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { PageSubTitle } from '../shared/components/page/page-sub-title.component';
import QuizDetail from './components/quiz-detail.component';
import { setPage } from '../store/actions/app.action';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';

class ConnectedQuizCreatePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            submitDisable: false,
            availableThemes: [],
            themes: [],
            pictureFile: [],
            logoFile: [],
            quiz: {
                title: [],
                description: [],
                picture: '',
                logo: '',
                published: false,
                public: false,
                locales: [Config.app.DEFAULT_LOCALE]
            },
            dirty: false,
            picture: null,
            logo: null,
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleChangeMultiSelect = this.handleChangeMultiSelect.bind(this);
        this.handleChangePicture = this.handleChangePicture.bind(this);
        this.handleChangeLogo = this.handleChangeLogo.bind(this);
        this.handleChangePublished = this.handleChangePublished.bind(this);
        this.handleChangePublic = this.handleChangePublic.bind(this);
        this.handleChangeQuiz = this.handleChangeQuiz.bind(this);
        this.handleClickPicture = this.handleClickPicture.bind(this);
        this.handleClickLogo = this.handleClickLogo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.setPage(Config.page.quiz.CREATE);

        // Get list of available themes of company
        http.get(`/manager/players/${this.props.store.player.id}/companies/${this.props.store.company.id}/themes`)
            .then(response => {
                if (response.ok) {
                    const themes = response.data.themes;
                    const availableThemes = themes.map(item => {
                        return { label: item.title, value: item.id };
                    });
                    this.setState({ availableThemes: availableThemes });
                }
            });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push('/quizzes');
                break;
            default:
        }
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChangeLocales(event) {
        let quiz = this.state.quiz;
        if (event.value.length > 0) {
            quiz.locales = event.value;
        }
        this.setState({ quiz: quiz });
    }

    /**
     * Set state values of question
     * 
     * @param {*} quiz 
     */
    handleClickPicture() {
        let quiz = this.state.quiz;
        quiz.picture = '';
        this.setState({ dirty: false, picture: null, quiz: quiz });
    }

    /**
     * Set state values for the logo
     * @param {*} quiz
     */
    handleClickLogo() {
        let quiz = this.state.quiz;
        quiz.logo = '';
        this.setState({ dirty: false, logo: null, quiz: quiz});
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChangeMultiSelect(event) {
        this.setState({ themes: event.value });
    }

    /**
     * Set picture file
     * 
     * @param {*} files 
     */
    handleChangePicture(files) {
        const pictureFile = files[0];
        if (pictureFile) {
            let quiz = this.state.quiz;
            quiz.picture = pictureFile.name;
            this.setState({ pictureFile: pictureFile, quiz: quiz, dirty: true, picture: URL.createObjectURL(pictureFile) });
        }
    }

    /**
     * Set logo file
     * @param {*} quiz 
     */
    handleChangeLogo(files){
        const logoFile = files[0];
        if(logoFile){
            let quiz = this.state.quiz;
            quiz.logo = logoFile.name;
            this.setState({ logoFile: logoFile, quiz: quiz, dirty: true, logo: URL.createObjectURL(logoFile) });
        }
    }

    /**
     * Set state values of question
     * 
     * @param {*} quiz 
     */
    handleChangeQuiz(quiz) {
        this.setState({ quiz: quiz });
    }

    /**
     * Set state published value of question
     * 
     * @param {*} event 
     */
    handleChangePublished(event) {
        let quiz = this.state.quiz;
        quiz.published = event.value;
        this.setState({ quiz: quiz });
    }

    /**
     * Set state public value of question
     * 
     * @param {*} event 
     */
    handleChangePublic(event) {
        let quiz = this.state.quiz;
        quiz.public = event.value;
        this.setState({ quiz: quiz });
    }

    /**
     * Submit quiz
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, submitDisable: true });
        const { quiz, pictureFile, logoFile, themes } = this.state;

        let themesOK = true;
        // On oblige à lier à une thème
        /*if (this.props.store.player.manager
            && !this.props.store.player.admin
            && themes.length === 0) {
            themesOK = false;
        }*/
        if (themes.length === 0) {
            themesOK = false;
        }

        // Title and theme are mandatory
        if (Object.keys(quiz.title).length > 0 && themesOK ) {

            // Send picture to API, response is an anonimised reference name
            let pictureName = quiz.picture;
            let pictureOK = true;
            
            if (pictureFile && pictureFile.name) {
                let dataPicture = new FormData();
                dataPicture.append('image', pictureFile);
                const responsePicture = await http.postFile('/upload/image', dataPicture);
                if (!responsePicture.ok) {
                    pictureOK = false;
                    this.props.showError(responsePicture.error, ['app.picture.not.uploaded']);
                } else {
                    pictureName = responsePicture.data.image;
                }
            }

            let logoName = quiz.logo;
            let logoOk = true;
            //Same as the picture but for the logo
            if(logoFile && logoFile.name){
                let dataLogo = new FormData();
                dataLogo.append('image', logoFile);
                const responseLogo = await http.postFile('/upload/image', dataLogo);

                if (!responseLogo.ok) {
                    logoOk = false;
                    this.props.showError(responseLogo.error, ['app.picture.not.uploaded']);
                } else {
                    logoName = responseLogo.data.image;
                }
            }

            if (pictureOK && logoOk) {
                const data = {
                    quiz: { ...quiz, picture: pictureName, logo: logoName, locales: quiz.locales.toString() },
                    themes: themes
                };
                console.log(data);

                const response = await http.post(`/manager/quizzes/company/${this.props.store.company.id}`, data);
                if (!response.ok) {
                    this.props.showError(response.error, ['app.quiz.not.created']);
                } else {
                    history.push(`/quizzes/${response.data.quiz.id}/manager`);
                }
            }
        }

        this.setState({ submitDisable: false });
    }

    render() {

        const styleMultiSelect = {
            marginBottom: '.3em',
            width: '100%'
        };

        const styleParameters = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-end'
        };

        const styleLogo = {
            marginBottom: '1.5em'
        };

        const dynamicDetails = this.state.quiz.locales.map((locale) => {
            return <QuizDetail key={locale} lang={locale} quiz={this.state.quiz}
                onChange={this.handleChangeQuiz}
                submitted={this.state.submitted} styles={this.props.store.style} />
        });
        
        return (
            <Page>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.quiz.form' })}
                    onClick={this.handleAction} back={Config.action.BACK}>

                    <PageSubTitle title={this.props.intl.formatMessage({ id: 'app.publication.parameters' })} />

                    <div className="row">
                        <div className="col-6">
                            <div style={styleParameters}>
                                <InputMultiSelect name="themes" label="app.theme" values={this.state.themes} options={this.state.availableThemes}
                                    onChange={this.handleChangeMultiSelect} submitted={this.state.submitted} mandatory={true}
                                    styles={styleMultiSelect} />
                                <InputSwitch label={this.state.quiz.published? "app.published" : "app.draft"} checked={this.state.quiz.published} onChange={this.handleChangePublished} />
                                <InputSwitch label="app.public" checked={this.state.quiz.public} onChange={this.handleChangePublic} />
                            </div>
                            <div className="col-6" style={styleLogo}>
                                <ImageUpload name="logo" imageLabel="app.choose.image.label.quiz"
                                    styles={this.props.store.style} kind="primary" onClick={this.handleClickLogo}
                                    onChange={this.handleChangeLogo} value={this.state.logo} format="small-square" label="app.choose.logo.title"/>
                            </div>
                        </div>
                        <div className="col-6">
                            <ImageUpload name="picture" imageLabel="app.choose.image.label"
                                styles={this.props.store.style} kind="primary" onClick={this.handleClickPicture}
                                onChange={this.handleChangePicture} value={this.state.picture} label="app.choose.image.title"/>
                        </div>
                    </div>

                    <InputMultiSelectInline name="locales" label="app.language" kind="quiz"
                        options={Config.locales.LIST} value={this.state.quiz.locales}
                        onChange={this.handleChangeLocales} />

                    {dynamicDetails}

                    <div className="float-right">
                        <Button label="app.save" onClick={(e) => this.handleSubmit(e)}
                            styles={this.props.store.style} kind="primary" 
                            disabled={this.state.submitDisable} />
                    </div>

                </PageCard>
            </Page >
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            player: state.player,
            style: state.style
        }
    };
};

const mapDispatchToProps = {
    setPage,
    showError, showSuccess
};

const QuizCreatePage = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuizCreatePage);

QuizCreatePage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizCreatePage);
