import React, { Component } from 'react';
import { ButtonRoundedIcon } from '../../shared/components/button-rounded-icon.component';
import { InputTextArea } from '../../shared/components/form/input-text-area.component';
import Config from '../../config';

export class Answer extends Component {

    constructor(props) {
        super(props);
        this.refAnswer = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        let value = this.refAnswer.current.value;
        value = value.replace(/\n/g, "");
        this.props.onChange(this.props.name, value);
    }

    render() {
        const styleContainer = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        };

        const styleInputText = {
            flexGrow: '2'
        };

        const styleButton = {
        };

        let kind = 'light';
        if (this.props.active) {
            kind = 'base';
        }

        return (
            <div style={styleContainer} >
                <div style={styleInputText}>
                    <InputTextArea name={this.props.name} label={this.props.label} ref={this.refAnswer}
                        maxLength={Config.inputText.APP_ANSWER_MAX_LENGTH} rows="3" placeholder={Config.inputText.APP_ANSWER_MAX_LENGTH}
                        value={this.props.answer} onChange={this.handleChange}
                        submitted={this.props.submitted} mandatory="true" />
                </div>
                <div style={styleButton}>
                    <ButtonRoundedIcon icon={this.props.active ? "fa fa-check" : "fa fa-times"} onClick={() => this.props.onClick(this.props.name)}
                        kind={kind} styles={this.props.styles} />
                </div>
            </div>
        );
    }
}
