import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';
import { SelectButton } from 'primereact/selectbutton';

class ConnectedInputSelectInline extends Component {

    render() {
        const style = this.props.store.style.InputSelectInline;

        const styleLabel = {
            display: 'flex',
            margin: '0'
        }

        const styleLabelContent = {
            margin: 'auto 0'
        }

        return (
            <div style={[style.container[this.props.kind]]}>
                {this.props.label && !this.props.noIntlLabel && (<div className="row">
                    <label className="col-sm-3" style={[styleLabel]}><div style={[styleLabelContent]}>{this.props.intl.formatMessage({ id: this.props.label })}</div></label>
                    <div className="col-sm-9">
                        <SelectButton name={this.props.name} value={this.props.value} options={this.props.options} onChange={this.props.onChange.bind(this)}></SelectButton>
                        {this.props.submitted && this.props.mandatory && (!this.props.value || (this.props.value === "null")) &&
                            <div className="help-block">*{this.props.intl.formatMessage({ id: this.props.alert ? this.props.alert : 'app.mandatory.field' })}</div>
                        }
                    </div>
                </div>)}
                {this.props.label && this.props.noIntlLabel && (<div className="row">
                    <label className="col-sm-3" style={[styleLabel]}><div style={[styleLabelContent]}>{this.props.label}</div></label>
                    <div className="col-sm-9">
                        <SelectButton name={this.props.name} value={this.props.value} options={this.props.options} onChange={this.props.onChange.bind(this)}></SelectButton>
                        {this.props.submitted && this.props.mandatory && (!this.props.value || (this.props.value === "null")) &&
                            <div className="help-block">*{this.props.intl.formatMessage({ id: this.props.alert ? this.props.alert : 'app.mandatory.field' })}</div>
                        }
                    </div>
                </div>)}
                {!this.props.label && (<div>
                    <SelectButton name={this.props.name} value={this.props.value} options={this.props.options} onChange={this.props.onChange.bind(this)}></SelectButton>
                    {this.props.submitted && this.props.mandatory && (!this.props.value || (this.props.value === "null")) &&
                        <div className="help-block">*{this.props.intl.formatMessage({ id: this.props.alert ? this.props.alert : 'app.mandatory.field' })}</div>
                    }
                </div>)}
            </div>
        )
    }
}

ConnectedInputSelectInline = Radium(ConnectedInputSelectInline);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const InputSelectInline = connect(mapStateToProps, null)(ConnectedInputSelectInline);

InputSelectInline.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(InputSelectInline);