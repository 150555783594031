import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';


import { ButtonRoundedIcon } from '../../shared/components/button-rounded-icon.component';
import Quiz from './quiz.component';
import { Shelf } from '../../shared/components/shelf.component';
import Config from '../../config';

class ConnectedShelfTheme extends Component {

    render() {
        const departmentLength = this.props.theme.departments.length;
        const themePublic = this.props.theme.public;
        const themePublished = this.props.theme.published;

        const styleDepartment = {
            base: {
                backgroundColor: this.props.store.style.color.danger.base,
                color: this.props.store.style.color.danger.opposite,
                borderRadius: '3px',
                fontStyle: 'italic',
                marginLeft: '.5rem',
                paddingLeft: '.3rem',
                paddingRight: '.3rem',
                textTransform: 'lowercase'
            }
        }

        const styleHeader = {
            base: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }
        };

        const styleTitle = {
            base: {
                textTransform: 'uppercase'
            }
        };

        const styleButton = {
            base: {
            }
        };

        const styleContainer = {
            base: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
            }
        };

        const stylePublic = {
            base: {
                backgroundColor: this.props.store.style.color.primary.base,
                color: this.props.store.style.color.primary.opposite,
                borderRadius: '3px',
                fontStyle: 'italic',
                marginLeft: '.5rem',
                paddingLeft: '.3rem',
                paddingRight: '.3rem',
                textTransform: 'lowercase'
            }
        }

        const stylePublished = {
            base: {
                backgroundColor: this.props.store.style.color.light.base,
                color: this.props.store.style.color.light.opposite,
                borderRadius: '3px',
                fontStyle: 'italic',
                marginLeft: '.5rem',
                paddingLeft: '.3rem',
                paddingRight: '.3rem',
                textTransform: 'lowercase'
            }
        }

        const dynamicQuizzes = this.props.theme.quizzes.map((quiz, index) => {
            let action = Config.action.QUIZ;
            if (this.props.companyId && this.props.companyId !== quiz.companyId) {
                action = Config.action.QUIZ_PUBLIC;
            }
            return <Quiz key={index} quiz={quiz} action={action}
                onClick={this.props.onClickQuiz} kind="primary" />
        });

        return (
            <Shelf>
                <div style={[styleHeader.base]} >
                    <h5 style={[styleTitle.base]}>
                        <span>{this.props.theme.title}</span>
                        {themePublic ? (<span style={[stylePublic.base]}>{this.props.intl.formatMessage({ id: 'app.public' })}</span>) : ''}
                        {!themePublic && departmentLength === 0 ? (<span style={[styleDepartment.base]}>{this.props.intl.formatMessage({ id: 'app.department' })}</span>) : ''}
                        {!themePublished ? (<span style={[stylePublished.base]}>{this.props.intl.formatMessage({ id: 'app.draft' })}</span>) : ''}
                    </h5>
                    <div style={[styleButton.base]}>
                        <ButtonRoundedIcon icon="fa fa-trophy" onClick={(e) => this.props.onClick(Config.action.RANKING, { id: this.props.theme.id })}
                            styles={this.props.store.style} kind="secondary" />
                        <ButtonRoundedIcon icon="fa fa-pen" onClick={(e) => this.props.onClick(Config.action.MODIFY, { id: this.props.theme.id })}
                            styles={this.props.store.style} kind="secondary" />
                    </div>
                </div>
                <div style={[styleContainer.base]} >
                    {dynamicQuizzes}
                </div>
            </Shelf>
        );
    }
}

ConnectedShelfTheme = Radium(ConnectedShelfTheme);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const ShelfTheme = connect(mapStateToProps, null)(ConnectedShelfTheme);

ShelfTheme.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ShelfTheme);