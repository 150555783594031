export const APP_SET_LAST_PAGE = '[App] Set last page';
export const APP_SET_LOCALE = '[App] Set locale';
export const APP_SET_PAGE = '[App] Set page';
export const APP_SET_SIDE_MENU = '[App] Set side menu';

export const initialState = {
    lastPage: '',
    locale: 'fr',
    page: '',
    sideMenu: 'theme'
}

export const sideMenu = {
    COMPANIES: 'companies',
    COMPANY: 'company',
    DEPARTMENT: 'department',
    GROUP: 'group',
    QUESTION: 'question',
    QUIZ: 'quiz',
    THEME: 'theme',
    USER: 'user',
}

export const setLastPage = lastPage => {
    return {type: APP_SET_LAST_PAGE, lastPage: lastPage}
}

export const setLocale = locale => {
    return {type: APP_SET_LOCALE, locale: locale}
}

export const setPage = page => {
    return {type: APP_SET_PAGE, page: page}
}

export const setSideMenu = sideMenu => {
    return {type: APP_SET_SIDE_MENU, sideMenu: sideMenu}
}