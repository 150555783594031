import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';
import { sortLabel } from "../core/helpers/tools.helper";

import ButtonRounded from '../shared/components/button-rounded.component';
import { InputAutoComplete } from '../shared/components/form/input-autocomplete.component';
import InputSelect from '../shared/components/form/input-select.component';
import { Page } from '../shared/components/page/page.component';
import { sideMenu, setPage, setLastPage, setSideMenu } from '../store/actions/app.action';
import { setThemeFilter, setDepartmentValue } from '../store/actions/themes.action';
import { showError, showWarning } from '../store/actions/message.action';
import ShelfTheme from './components/shelf-theme.component';
import Config from '../config';

class ConnectedThemesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filteredDepartments: null,
            filtredThemes: [],
            themeFilter: {
                title: '',
                departments: []
            },
            companyId: '',
            themes: [],
            department: '',
            departmentFilter: [],
            departmentValue: '',
            departments: []
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChangeTheme = this.handleChangeTheme.bind(this);
        this.handleFilterThemes = this.handleFilterThemes.bind(this);
        this.handleFilterDepartments = this.handleFilterDepartments.bind(this);
        this.handleFindThemes = this.handleFindThemes.bind(this);
    }

    async componentDidMount() {
        const { store: { app } } = this.props;

        this.props.setPage(Config.page.THEMES);
        if (app.sideMenu !== sideMenu.THEME) {
            this.props.setSideMenu(sideMenu.THEME);
        }

            let companyId = '';
        let departments = [];
        let departmentFilter = [];
        let filtredThemes = [];
        let themes = [];

        const { id } = this.props.match.params;
        if (id) {
            companyId = id;
        } else {
            companyId = this.props.store.company.id;
        }

        const themeFilter = this.props.store.themes.themeFilter;
        const departmentValue = this.props.store.themes.departmentValue;

        themes = await this.getThemes(companyId, themeFilter);
        filtredThemes = themes;

        // Get list of available departments of company
        const responseDepartment = await http.get(`/manager/companies/${companyId}/departments`);
        if (!responseDepartment.ok) {
            this.props.showError(responseDepartment.error);
        } else {
            departments = responseDepartment.data.departments;
            departmentFilter = departments.map(item => {
                return { label: item.name, value: item.id };
            });

            if (themeFilter.title !== '' || themeFilter.departments.length !== 0) {
                await this.handleFilterThemes(themeFilter, themes);
                filtredThemes = this.state.filtredThemes;
            }
        }

        departmentFilter.sort(sortLabel);

        this.setState({
            companyId, themes, filtredThemes, departments, departmentFilter,
            themeFilter, departmentValue
        });
    }

    handleAction(action, data) {
        switch (action) {
            case 'add':
                if (this.state.departments.length !== 0) {
                    history.push('/themes/new');
                } else {
                    this.props.showError({ summary: 'app.theme.no.department' });
                }
                break;
            case Config.action.IMPORT:
                this.props.showWarning({ summary: Config.http.CODE_405 });
                break;
            case 'modify':
                history.push('/themes/modify/' + data.id);
                break;
            case Config.action.QUIZ:
                history.push('/quizzes/' + data.id + '/manager');
                break;
            case Config.action.QUIZ_PUBLIC:
                history.push('/quizzes/' + data.id + '/public');
                break;
            case Config.action.RANKING:
                this.props.setLastPage(`/themes`);
                history.push(`/themes/ranking/${data.id}`);
                break;
            case 'delete':
                history.push('/themes/delete/' + data.id);
                break;
            default:
        }
    }

    async getThemes(companyId, themeFilter = []) {
        let filter = '';
        let themes = [];

        if (themeFilter.departments.length > 0) {
            filter = `?department=${themeFilter.departments.toString()}`;
        }

        const response = await http.get(`/manager/themes/company/${companyId}${filter}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            themes = response.data.themes;
        }

        themes.sort(function (a, b) {
            if (a.title < b.title)
                return -1;
            if (a.title > b.title)
                return 1;
            return 0;
        });

        return themes;
    }

    /**
      * Change theme
      * 
      * @param {*} event 
      */
    async handleChangeTheme(theme) {
        const themes = this.state.themes;
        const filtredThemes = this.state.filtredThemes;

        const data = { theme: { published: theme.published } };

        const response = await http.patch(`/manager/themes/${theme.id}`, data)
        if (!response.ok) {
            this.props.showError(response.error, ['app.theme.not.updated']);
        } else {
            const index = themes.map(function (e) { return e.id; }).indexOf(parseInt(theme.id, 10));
            themes[index] = theme;

            const indexFiltred = filtredThemes.map(function (e) { return e.id; }).indexOf(parseInt(theme.id, 10));
            if (indexFiltred !== -1) {
                filtredThemes[indexFiltred] = theme
            }

            this.setState({ themes: themes, filtredThemes: filtredThemes });
        }
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} themeFilter
     */
    async handleFilterThemes(themeFilter) {
        this.props.setThemeFilter(themeFilter);
        const results = await this.state.themes.filter((theme) => {
            return theme.title.toLowerCase().includes(themeFilter.title.toLowerCase());
        });
        this.setState({ filtredThemes: results, themeFilter: themeFilter });
    }

    /**
     * Filter departments
     * 
     * @param {*} event 
     */
    async handleFilterDepartments(event) {
        const departmentValue = event.target.value;
        let filterDepartment = [];
        let themeFilter = this.state.themeFilter;
        let themes = [];

        this.props.setDepartmentValue(departmentValue);

        if (departmentValue === 'all') {
            filterDepartment = [];
        } else {
            filterDepartment = [departmentValue];
        }

        themeFilter.departments = filterDepartment;
        themes = await this.getThemes(this.state.companyId, themeFilter);
        this.setState({ departmentValue: departmentValue, themes: themes });
        this.handleFilterThemes(themeFilter);
    }

    /**
      * Find themes
      * 
      * @param {*} event 
      */
    async handleFindThemes(event) {
        let themeFilter = this.state.themeFilter;
        themeFilter.title = event.value;
        this.handleFilterThemes(themeFilter);
    }

    render() {
        const styleHeaderContainer = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between'
        };

        const styleHeaderSelection = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            flexWrap: 'wrap'
        };

        const styleHeaderActions = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        };

        const pageHeader = (
            <div style={styleHeaderContainer}>
                <div style={styleHeaderSelection}>
                    <InputSelect name="name" label="app.department" onChange={this.handleFilterDepartments} all={true}
                        options={this.state.departmentFilter} value={this.state.departmentValue} />

                    <InputAutoComplete field="title" label="app.find" size={60}
                        onChange={this.handleFindThemes} value={this.state.themeFilter.title} />
                </div>
                <div style={styleHeaderActions}>
                    <div style={{ marginRight: '.5em' }}>
                        <ButtonRounded label="app.create.theme" onClick={() => this.handleAction(Config.action.ADD)}
                            styles={this.props.store.style} kind="expandedPrimary"
                            disabled={this.state.companyId !== this.props.store.company.id} />
                    </div>
                </div>
            </div>
        );

        const dynamicThemes = this.state.filtredThemes.map((theme, index) => {
            return <ShelfTheme key={index} theme={theme} companyId={this.state.companyId}
                onClick={this.handleAction} onClickQuiz={this.handleAction} />
        });

        return (
            <Page header={pageHeader} >
                {dynamicThemes}
            </Page>
        );
    }
}

const mapStateToProps = ({ app, company, style, themes }) => {
    return { store: { app, company, style, themes } };
};

const mapDispatchToProps = {
    setPage, setLastPage, setSideMenu,
    setThemeFilter, setDepartmentValue,
    showError, showWarning
};

const ThemesPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedThemesPage);

ThemesPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ThemesPage);
