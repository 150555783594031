const Config = {
    action: {
        ADD: 'add',
        BACK: 'back',
        DELETE: 'delete',
        DEPARTMENT: 'department',
        GROUP: 'group',
        IMPORT: 'import',
        MANAGER: 'manager',
        MODIFY: 'modify',
        QUIZ: 'quiz',
        QUIZ_PUBLIC: 'quiz_public',
        RANKING: 'ranking',
        THEME: 'theme',
        USER: 'user',
        RESTORE: 'restore'
    },
    active: {
        TYPE: [
            { label: 'app.active.all', value: 'all' },
            { label: 'app.active.ok', value: 'ok' },
            { label: 'app.active.not', value: 'not' }
        ]
    },
    app: {
        role: {
            SUPER_ADMIN: 'superadmin',
            ADMIN: 'admin',
            USER: 'user'
        },
        DEFAULT_LOCALE: 'fr'
    },
    button: {
        rounded: {
            DEFAULT_FA: 'fa fa-mouse-pointer'
        }
    },
    color: {
        DARK: '#cbcbcb',
        DARK_PRIMARY_BACK: '#3a3f51',
        DARK_PRIMARY_BORDER: '#3a3f51',
        DARK_PRIMARY_COLOR: '#ffffff',
        HEADER_PRIMARY_BACK: '#e0178e',
        HEADER_PRIMARY_BORDER: '#e0178e',
        HEADER_PRIMARY_COLOR: '#ffffff',
        HEADER_SECONDARY_BACK: '#f2f2f2',
        HEADER_SECONDARY_BORDER: '#e0178e',
        HEADER_SECONDARY_COLOR: '#e0178e',
        PRIMARY_BACK: '#392C70',
        PRIMARY_BORDER: '#392C70',
        PRIMARY_COLOR: '#ffffff'
    },
    company: {
        role: {
            ADMIN: 'admin',
            OPTIONS: [
                { label: 'app.company.role.user', value: 'user' },
                { label: 'app.company.role.admin', value: 'admin' }
            ],
            USER: 'user'
        }
    },
    department: {
        role: {
            OPTIONS: [
                { label: 'app.department.role.user', value: 'user' },
                { label: 'app.department.role.admin', value: 'admin' }
            ]
        }
    },
    invited: {
        TYPE: [
            { label: 'app.invited.all', value: 'all' },
            { label: 'app.invited.done', value: 'done' },
            { label: 'app.invited.waiting', value: 'waiting' },
            { label: 'app.invited.new', value: 'new' }
        ]
    },
    inputText: {
        APP_ANSWER_MAX_LENGTH: 60,
        APP_NOTIFICATION_MAX_LENGTH: 140,
        APP_STATEMENT_MAX_LENGTH: 120,
        APP_QUESTION_COMMENT_MAX_LENGTH: 500,
        DEFAULT_MAX_LENGTH: 255
    },
    http: {
        CODE_204: 'app.error.no.content',
        CODE_400: 'app.error.bad.request',
        CODE_405: 'app.error.method.not.allowed',
        CODE_422: 'app.error.unprocessable.entity',
        CODE_500: 'app.error.internal.server.error',
        CODE_503: 'app.error.service.unavailable'
    },
    locales: {
        ICON: {
            fr: 'fr',
            nl: 'nl',
            de: 'de',
            en: 'gb',
            es: 'es'
        },
        LIST: [
            { label: 'app.locale.fr', value: 'fr' },
            { label: 'app.locale.nl', value: 'nl' },
            { label: 'app.locale.en', value: 'en' },
            { label: 'app.locale.es', value: 'es' }
        ],
        LABELS: {
            fr: 'app.locale.fr',
            nl: 'app.locale.nl',
            en: 'app.locale.en',
            es: 'app.locale.es'
        },
        VALUES: 'en, es, fr, nl'
    },
    page: {
        INIT: '/',
        QUIZZES: '/quizzes',
        quiz: {
            CREATE: '/quizzes/new',
            UPLOAD: '/quizzes/upload'
        },
        THEMES: '/themes',
        USERS: '/users'
    },
    question: {
        DEFAULT_TIMER: 10,
        DEFAULT_TIMER_MIN: 10,
        DEFAULT_TIMER_MAX: 30,
        EXTEND: 25, 
        TYPE: [
            { label: 'app.questions.activeTypes.active', value: 'active' },
            { label: 'app.questions.activeTypes.trash', value: 'trash'},
            { label: 'app.questions.activeTypes.all', value: 'all'}
        ],
    },
    quiz: {
        DEFAULT_TIMER: 10,
        DEFAULT_TIMER_MIN: 10,
        DEFAULT_TIMER_MAX: 30,
        MIN_QUESTION: 30,
        TYPE: [
            { label: 'app.quiz.unshared', value: 'unshared' },
            { label: 'app.quiz.shared', value: 'shared' }
        ],
    },
    showMessage: {
        life: 2000
    },
}

export default Config;