import React, { Component } from 'react';
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { injectIntl, intlShape } from 'react-intl';
import { httpService as http } from "../core/services/http.service";

import { Button } from '../shared/components/button.component';
import CompanyBox from '../shared/components/company-box.component';
import { InputMultiSelect } from '../shared/components/form/input-multiselect.component';
import { PageSubTitle } from '../shared/components/page/page-sub-title.component';
import StaticText from '../shared/components/form/static-text.component';
import StaticTextArea from '../shared/components/form/static-text-area.component';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';

class ConnectedQuizPublicDetailsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            submitDisable: false,
            locales: '',
            quiz: {
                id: '',
                title: '',
                description: '',
                picture: '',
                company: {
                    id: '',
                    name: '',
                    description: '',
                    logo: '',
                    website: ''
                }
            },
            themes: [],
            themesOptions: [],
            themesValues: []
        }

        this.handleAction = this.handleAction.bind(this);
        this.handleChangeMultiSelect = this.handleChangeMultiSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.params;
        let quiz = [];
        let quizThemes = [];
        let themes = [];
        let themesOptions = [];
        let themesValues = [];

        // Get quiz entity
        const response = await http.get(`/manager/quizzes/${id}/public?company=${this.props.store.company.id}`);
        if (response.ok) {
            const locales = response.data.quiz.locales.split(',').map(item => {
                return this.props.intl.formatMessage({ id: 'app.locale.' + item });
            });
            quiz = {
                ...response.data.quiz,
                picture: response.data.quiz.picture ? process.env.REACT_APP_IMAGES_DIRECTORY + response.data.quiz.picture : null,
                locales: locales.toString().replace(/,/g, ', ')
            };
            quizThemes = response.data.themes.map(item => {
                return item.id;
            });
        }

        // Get list of available themes of company
        const responseThemes = await http.get(`/manager/players/${this.props.store.player.id}/companies/${this.props.store.company.id}/themes`);
        if (responseThemes.ok) {
            themesOptions = responseThemes.data.themes.map(item => {
                if (quizThemes.includes(item.id)) {
                    themesValues.push(item.id);
                }
                themes.push({ id: item.id, value: quizThemes.includes(item.id) });
                return { label: item.title, value: item.id };
            });
        }

        this.setState({ quiz: quiz, themes: themes, themesOptions: themesOptions, themesValues: themesValues });
    }

    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push('/quizzes');
                break;
            default:
        }
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChangeMultiSelect(event) {
        const value = event.value;
        const themes = this.state.themes.map(item => {
            const include = value.includes(item.id);
            return { ...item, value: include };
        });

        this.setState({ themes: themes, themesValues: value });
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitDisable: true });
        const { quiz, themes } = this.state;

        // Themes list is mandatory
        if (Object.keys(themes).length > 0) {
            const data = { themes: themes };
            const response = await http.put(`/manager/quizzes/${quiz.id}/public/themes?company=${this.props.store.company.id}`, data);
            if (!response.ok) {
                this.props.showError(response.error, ['app.themes.not.updated']);
            } else {
                history.push('/quizzes');
            }
        }

        this.setState({ submitDisable: false });
    }

    render() {
        const stylePictureContainer = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }

        const stylePicture = {
            border: '1px solid #cbcbcb',
            height: '12em',
            borderRadius: 0,
            marginTop: '0'
        }

        const styleButtonActions = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
        };

        return (
            <div>
                <div className="row">
                    <div className="col-6">
                        <StaticTextArea label="app.description" value={this.state.quiz.description} />

                        <StaticText label="app.language" value={this.state.quiz.locales} />

                        <PageSubTitle title={this.props.intl.formatMessage({ id: 'app.proposed.by' })} />

                        <CompanyBox name="company" company={this.state.quiz.company} />

                        <PageSubTitle title={this.props.intl.formatMessage({ id: 'app.add.quiz.to.my.theme' })} />

                        <InputMultiSelect name="themes" values={this.state.themesValues} options={this.state.themesOptions}
                            onChange={this.handleChangeMultiSelect} />
                    </div>
                    <div className="col-6">
                        <div style={stylePictureContainer}>
                            <img style={stylePicture} src={this.state.quiz.picture} alt={this.state.quiz.picture} />
                        </div>
                    </div>
                </div>
                <div style={styleButtonActions}>
                    <div>
                        <Button label="app.save" onClick={(e) => this.handleSubmit(e)}
                            styles={this.props.store.style} kind="primary" disabled={this.state.submitDisable ? this.state.submitDisable : false} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            player: state.player,
            style: state.style,
        }
    };
};

const mapDispatchToProps = {
    showError,
    showSuccess
};

const QuizPublicDetailsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuizPublicDetailsPage);

QuizPublicDetailsPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizPublicDetailsPage);