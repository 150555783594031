import React, { Component } from 'react';
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';


import ButtonRounded from '../shared/components/button-rounded.component';
import Dialog from '../shared/components/dialog.component';
import { InputAutoComplete } from '../shared/components/form/input-autocomplete.component';
import InputSelect from '../shared/components/form/input-select.component';
import { PickList } from 'primereact/picklist';
import Question from '../question/components/question.component';
import { setQuiz } from '../store/actions/question-create.action';
import { setQuestionsNumber } from '../store/actions/quiz-manager.action';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';

class ConnectedQuizModifyPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            availableFilter: {
                statement: '',
                quizzes: [],
                themes: [],
                active: 'active',
            },
            dialog: false,
            dialogQuestion: [],
            availableFilterQuizzes: [],
            availableFilterThemes: [],
            availableQuestions: [],
            availableThemes: [],
            filtredQuestions: [],
            filtredQuizQuestions: [],
            quizQuestions: [],
            quizzes: [],
            quizId: null
        }

        this.handleAction = this.handleAction.bind(this);
        this.handleActionCreateQuestion = this.handleActionCreateQuestion.bind(this);

        this.handleAvailableFilterFind = this.handleAvailableFilterFind.bind(this);
        this.handleAvailableFilterQuizzes = this.handleAvailableFilterQuizzes.bind(this);
        this.handleAvailableFilterThemes = this.handleAvailableFilterThemes.bind(this);

        this.handleDeleteQuestionOnHide = this.handleDeleteQuestionOnHide.bind(this);
        this.handleDeleteQuestionOnSubmit = this.handleDeleteQuestionOnSubmit.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.questionTemplate = this.questionTemplate.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.params;
        let availableFilterQuizzes = [];
        let availableQuestions = [];
        let quizQuestions = [];
        let quizzes = [];

        const questionsResponse = await http.get(`/manager/quizzes/${id}/questions`);
        if (questionsResponse.ok) {
            quizQuestions = questionsResponse.data.quiz.questions;
        }

        const companyQuestions = await this.getQuestions(this.props.store.company.id, this.state.availableFilter);
        availableQuestions = await companyQuestions.filter((item) => {
            let index = quizQuestions.map(function (e) { return e.id; }).indexOf(item.id);
            return index === -1;
        });

        const responseQuizzes = await http.get(`/manager/quizzes/company/${this.props.store.company.id}`);
        if (!responseQuizzes.ok) {
            this.props.showError(responseQuizzes.error);
        } else {
            quizzes = responseQuizzes.data.quizzes;
            availableFilterQuizzes = quizzes.map(item => {
                return { label: item.title, value: item.id };
            });
        }

        const themes = await this.getThemes(this.props.store.company.id);
        const availableQuizThemes = themes.map(item => {
            return { label: item.title, value: item.id };
        });

        this.setState({
            quizQuestions: quizQuestions,
            filtredQuizQuestions: quizQuestions,
            availableQuestions: availableQuestions,
            filtredQuestions: availableQuestions,
            availableQuizThemes: availableQuizThemes,
            availableThemes: themes,
            availableFilterThemes: availableQuizThemes,
            quizzes: quizzes,
            availableFilterQuizzes: availableFilterQuizzes,
            quizId: id
        });

        this.props.setQuestionsNumber(quizQuestions.length);
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.MODIFY:
                history.push(`/questions/modify/${data.id}`);
                break;
            case Config.action.DELETE:
                this.setState({ dialog: true, dialogQuestion: data });
                break;
            case Config.action.IMPORT:
                history.push(`/quizzes/${this.state.quizId}/upload`);
                break;
            default:
        }
    }

    async getQuestions(companyId, questionFilter = []) {
        let questions = [];

        let filter = '';
        if (questionFilter.quizzes.length > 0) {
            filter = `?quiz=${questionFilter.quizzes.toString()}`;
        } else if (questionFilter.themes.length > 0) {
            filter = `?theme=${questionFilter.themes.toString()}`;
        }

        if(questionFilter.active !== ''){
            let startFilter = (filter !== '') ? '&' : '?'
            filter = filter + `${startFilter}status=${questionFilter.active}`;
        }
        
        const response = await http.get(`/manager/questions/company/${companyId}${filter}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            questions = response.data.questions;
        }

        return questions;
    }

    async getThemes(companyId) {
        let themes = [];

        const response = await http.get(`/manager/players/${this.props.store.player.id}/companies/${companyId}/themes`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            themes = response.data.themes;
        }

        return themes;
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleActionCreateQuestion(event) {
        this.props.setQuiz(this.props.params.id);
        this.props.handleAction(Config.action.ADD);
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} name
     * @param {*} value 
     */
    async handleAvailableFilter(availableFilter, questions) {
        const results = await questions.filter((question) => {
            return question.statement.toLowerCase().includes(availableFilter.statement.toLowerCase());
        });

        const filtredQuestions = await results.filter((item) => {
            let index = this.state.quizQuestions.map(function (e) { return e.id; }).indexOf(item.id);
            return index === -1;
        });

        this.setState({ filtredQuestions: filtredQuestions, availableFilter: availableFilter });
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleAvailableFilterFind(event) {
        let availableFilter = this.state.availableFilter;
        availableFilter.statement = event.value;
        this.handleAvailableFilter(availableFilter, this.state.availableQuestions);
    }

    /**
     * Filter available quizzes
     * 
     * @param {*} event 
     */
    async handleAvailableFilterQuizzes(event) {
        const quizValue = event.target.value;
        let availableFilter = this.state.availableFilter;

        if (quizValue !== 'all') {
            availableFilter.quizzes = [quizValue];
        } else {
            availableFilter.quizzes = [];
        }
        const availableQuestions = await this.getQuestions(this.props.store.company.id, availableFilter);

        this.setState({ availableQuestions: availableQuestions });
        this.handleAvailableFilter(availableFilter, availableQuestions);
    }

    /**
     * Filter available themes
     * Change available quizzes
     * 
     * @param {*} event 
     */
    async handleAvailableFilterThemes(event) {
        const themeValue = event.target.value;
        let availableFilterQuizzes = [];

        let availableFilter = this.state.availableFilter;
        availableFilter.themes = [themeValue];
        availableFilter.quizzes = [];

        const questions = await this.getQuestions(this.props.store.company.id, availableFilter);

        if (themeValue !== 'all' && themeValue !== 'none') {
            const themes = this.state.availableThemes;
            let index = await themes.map(function (e) { return e.id; }).indexOf(parseInt(themeValue, 10));
            const quizzes = themes[index].quizzes;
            availableFilterQuizzes = quizzes.map(item => {
                return { label: item.title, value: item.id };
            });
        } else if (themeValue === 'all') {
            availableFilterQuizzes = this.state.quizzes.map(item => {
                return { label: item.title, value: item.id };
            });
        } else if (themeValue === 'none') {
            availableFilterQuizzes = [];
        };

        this.setState({ availableFilterQuizzes: availableFilterQuizzes, availableQuestions: questions });
        this.handleAvailableFilter(availableFilter, questions);
    }

    /**
     * Hide question delete
     */
    handleDeleteQuestionOnHide() {
        this.setState({ dialog: false, dialogQuestion: [] });
    }

    /**
     * Submit question delete
     */
    async handleDeleteQuestionOnSubmit() {
        const response = await http.del(`/manager/questions/${this.state.dialogQuestion.id}`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            this.props.showSuccess({ summary: 'app.question.deleted' });
            http.get(`/manager/questions/company/${this.props.store.company.id}`)
                .then(response => {
                    if (response.ok) {
                        const questions = response.data.questions;
                        this.setState({ questions: questions, filtredQuestions: questions });
                        this.handleFilterQuestions(this.state.questionFilter, questions);
                    }
                });
        }
        this.setState({ dialog: false, dialogQuestion: [] });
    }

    async handleSubmit(event) {
        event.preventDefault();

        // Get questions list
        const questionsResponse = await http.put(`/manager/quizzes/${this.props.params.id}/questions`,
            { questions: this.state.quizQuestions });
        let quizQuestions = [];
        if (!questionsResponse.ok) {
            this.props.showError(questionsResponse.error);
        } else {
            quizQuestions = questionsResponse.data.quiz.questions;
            this.setState({ quizQuestions: quizQuestions });
            this.props.showSuccess({ summary: 'app.questions.list.updated' });
            this.props.setQuestionsNumber(quizQuestions.length);
        }

    }

    /**
     * Event called when a question move to another column
     * 
     * @param {*} event 
     */
    onChange(event) {
        // Todo : ajouter et supprimer des questions de 
        const filtredQuestions = event.source;
        const filtredQuizQuestions = event.target;
        //const stateFiltredQuizQuestion = this.state.filtredQuizQuestions;
        this.setState({
            filtredQuestions: filtredQuestions,
            filtredQuizQuestions: filtredQuizQuestions,
            quizQuestions: filtredQuizQuestions
        });
        this.props.setQuestionsNumber(filtredQuizQuestions.length);
    }

    /**
     * Questions template of pick list column
     * 
     * @param {*} question 
     */
    questionTemplate(question) {
        return (
            <Question key={question.id} question={question} onClick={this.handleAction} styles={this.props.store.style} kind="quiz" delete={false} />
        );
    }

    render() {

        const { intl } = this.props;
        const {
            availableFilterThemes,
            availableFilterQuizzes//, selectedFilterQuizzes
        } = this.state;

        const pickListHeight = `71em`;

        const stylePickListHeader = {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '.3em'
        };

        const stylePickListHeaderLeft = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
            width: '48%'
        };

        const stylePickListHeaderCenter = {
            width: '3.5%'
        };

        const stylePickListHeaderRight = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '48%'
        };

        const stylePickListFilter = {
            ...stylePickListHeader
        };

        const stylePickListFilterLeft = {
            ...stylePickListHeaderLeft,
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
        };

        const stylePickListFilterCenter = {
            ...stylePickListHeaderCenter
        };

        const stylePickListFilterRight = {
            ...stylePickListHeaderRight,
            //justifyContent: 'flex-start',
            flexWrap: 'wrap'
        };

        //let questionsHeader = `${intl.formatMessage({ id: 'app.questions' })}`;
        //questionsHeader = `${questionsHeader} - (${this.state.quizQuestions.length}/${Config.quiz.MIN_QUESTION}`;
        //questionsHeader = `${questionsHeader} ${intl.formatMessage({ id: 'app.selected.questions' }).toLowerCase()})`;

        return (
            <div>
                <div style={stylePickListHeader}>
                    <div style={stylePickListHeaderLeft}>
                        <InputSelect name="theme" label="app.theme" onChange={this.handleAvailableFilterThemes} all={true} none={true}
                            options={availableFilterThemes} />

                        <InputSelect name="quiz" label="app.quiz" onChange={this.handleAvailableFilterQuizzes} all={true} none={true}
                            options={availableFilterQuizzes} />
                    </div>
                    <div style={stylePickListHeaderCenter}></div>
                    <div style={stylePickListHeaderRight}>

                    </div>
                </div>
                <div style={stylePickListFilter}>
                    <div style={stylePickListFilterLeft}>
                        <InputAutoComplete name="statement" field="statement" label="app.find" size="60"
                            onChange={this.handleAvailableFilterFind} value={this.state.availableFilter.statement} />
                    </div>
                    <div style={stylePickListFilterCenter}></div>
                    <div style={stylePickListFilterRight}>
                        <div>
                            <span style={{ paddingRight: '.5rem' }}>
                                <ButtonRounded label="app.create.question" onClick={this.handleActionCreateQuestion}
                                    styles={this.props.store.style} kind="secondary" />
                            </span>
                            <ButtonRounded label="app.import.questions" onClick={() => this.handleAction(Config.action.IMPORT)}
                                styles={this.props.store.style} kind="secondary" />
                        </div>

                        <ButtonRounded label="app.save.questions" onClick={this.handleSubmit}
                            styles={this.props.store.style} kind="primary" />
                    </div>
                </div>
                <div>
                    <PickList source={this.state.filtredQuestions} target={this.state.filtredQuizQuestions}
                        itemTemplate={this.questionTemplate} onChange={this.onChange}
                        sourceStyle={{ 'height': pickListHeight }} targetStyle={{ 'height': pickListHeight }}
                        showSourceControls={false} showTargetControls={false} responsive={true}
                        sourceHeader={intl.formatMessage({ id: 'app.available.questions' })}
                        targetHeader={intl.formatMessage({ id: 'app.selected.questions' }) + ' : ' + this.state.quizQuestions.length.toString()} />
                </div>

                <Dialog visible={this.state.dialog}
                    text={this.props.intl.formatMessage({ id: 'app.dialog.delete.question' })}
                    onHide={this.handleDeleteQuestionOnHide} onSubmit={this.handleDeleteQuestionOnSubmit} />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            player: state.player,
            style: state.style,
        }
    };
};

const mapDispatchToProps = {
    setQuiz,
    setQuestionsNumber,
    showError, showSuccess,
};

const QuizModifyPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuizModifyPage);

QuizModifyPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizModifyPage);