import React, { Component } from "react";
import { connect } from "react-redux";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';
import { InputAutoComplete } from '../shared/components/form/input-autocomplete.component';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { setQuestionsNumber } from '../store/actions/quiz-manager.action';
import Config from '../config';

class ConnectedQuizQuestionsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            questions: [],
            filteredQuestions: [],
            questionFilter: {
                statement: ''
            }
        }

        this.statementTemplate = this.statementTemplate.bind(this);
        this.handleFindQuestions = this.handleFindQuestions.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.params;
        const company = this.props.store.company;
        let questions = [];

        // Get questions
        const responseQuestions = await http.get(`/manager/quizzes/${id}/companies/${company.id}/questions/statistics`);
        if (responseQuestions.ok) {
            questions = responseQuestions.data.questions;
            this.props.setQuestionsNumber(questions.length);
        }

        this.setState({ questions: questions, filteredQuestions: questions });
    }

    handleFindQuestions(event) {

        if (event.value !== undefined) {
            let questionFilter = this.state.questionFilter;
            questionFilter.statement = event.value;

            var results = this.state.questions.filter((question) => {
                return question.statement[this.props.store.user.locale].toLowerCase().includes(questionFilter.statement.toLowerCase());
            });

            this.setState({ filteredQuestions: results, questionFilter: questionFilter });
        }
    }


    statementTemplate(rowData, column) {
        const styleLocale = {
            marginLeft: '.3rem'
        }
        const statementKeys = Object.keys(rowData.statement);
        const statement = rowData.statement[this.props.store.user.locale] ? rowData.statement[this.props.store.user.locale] : rowData.statement[statementKeys[0]];

        const locales = rowData.locales.split(',');
        const dynamicLocales = locales.map((item, index) => {
            return <i key={item} className={`flag-icon flag-icon-${Config.locales.ICON[item]}`} style={styleLocale} title={item} id={item}></i>;
        });
        return <div>{statement} {dynamicLocales}</div>;
    }

    render() {
        const { intl } = this.props;
        const header = <div className="p-clearfix" style={{ lineHeight: '2rem', textAlign: 'center' }}>{intl.formatMessage({ id: 'app.quiz.questions' }).toUpperCase() + ' : ' + this.state.questions.length}</div>;

        const styleContainerRanking = {
        };

        const statementFilter = <InputAutoComplete field="statement" style={{ width: '100%' }} inputStyle={{ width: '100%' }}
            onChange={this.handleFindQuestions} value={this.state.questionFilter.statement} />

        return (
            <div>
                <div style={styleContainerRanking}>
                    <DataTable value={this.state.filteredQuestions} paginator={true} rows={30}
                        header={header} footer={this.state.filteredQuestions.length === 0 ? (<div style={{ textAlign: 'left' }}>{intl.formatMessage({ id: 'app.no.data' }).toUpperCase()}</div>) : null}>
                        <Column field="statement" header={intl.formatMessage({ id: 'app.statement' })} body={this.statementTemplate} sortable={true} filter={true} filterElement={statementFilter} />
                        <Column field="playersRate" header={intl.formatMessage({ id: 'app.players.rate' })} sortable={true} style={{ width: '9rem', textAlign: 'center', paddingLeft: '.2rem', paddingRight: '.2rem' }} />
                        <Column field="responseNumber" header={intl.formatMessage({ id: 'app.players' })} sortable={true} style={{ width: '9rem', textAlign: 'center', paddingLeft: '.2rem', paddingRight: '.2rem' }} />
                        <Column field="correctRate" header={intl.formatMessage({ id: 'app.correct.answer.rate' })} sortable={true} style={{ width: '9rem', textAlign: 'center', paddingLeft: '.2rem', paddingRight: '.2rem' }} />
                    </DataTable>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            style: state.style,
            user: state.user
        }
    };
};

const mapDispatchToProps = {
    setQuestionsNumber
};

const QuizQuestionsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuizQuestionsPage);

QuizQuestionsPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizQuestionsPage);