import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { InputText } from '../../shared/components/form/input-text.component';
import { Panel } from 'primereact/panel';
import Config from '../../config';

class NotificationDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: []
        };

        this.handleChangeMessage = this.handleChangeMessage.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            message: nextProps.message
        };
    }

    handleChangeMessage(event) {
        let message = this.state.message;
        message = { ...message, [this.props.lang]: event.target.value };
        this.setState({ message: message });
        this.props.onChange(message);
    }

    render() {
        const lang = this.props.lang;
        const message = this.state.message;

        const styleContainer = {
        };

        return (
            <Panel header={this.props.intl.formatMessage({ id: 'app.locale.' + this.props.lang })} style={{ marginTop: '2em', marginBottom: '2em' }} toggleable={true} collapsed={false}>
                <div style={styleContainer} >
                    <div className="row">
                        <div className="col-12">
                            <InputText name="message" label="app.message"
                                maxLength={Config.inputText.APP_NOTIFICATION_MAX_LENGTH} placeholder={Config.inputText.APP_NOTIFICATION_MAX_LENGTH}
                                value={message ? message[lang] : ''}
                                onChange={this.handleChangeMessage} submitted={this.props.submitted} mandatory="true" />
                        </div>
                    </div>
                </div>
            </Panel>
        );
    }
}

NotificationDetail.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(NotificationDetail);