import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';
import { InputSwitch as PrimeSwitch } from 'primereact/inputswitch';

class ConnectedInputSwitch extends Component {

    render() {
        const common = this.props.store.style.common;
        const style = this.props.store.style.inputSwitch;

        let styleContainer = {
            base: {
                ...common,
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 .5em'
            }
        }

        let styleLabel = {
            base: {
                minWidth: '5rem',
                marginRight: '.5em',
                marginBottom: '0'
            }
        }

        let styleComponent = {
            base: {
                marginTop: 'auto',
                marginBottom: 'auto',
                marginRight: '.5em',
                height: '1em'
            }
        }

        if (this.props.styles) {
            styleLabel = { ...styleLabel, ...this.props.styles.inputSwitch.label };
            styleComponent = { ...styleComponent, ...this.props.styles.inputSwitch.component };
        }

        return (
            <div style={[styleContainer.base, style.container[this.props.kind]]} >
                <label style={[styleLabel.base, style.label[this.props.kind]]}>{this.props.intl.formatMessage({ id: this.props.label })}</label>
                <PrimeSwitch styles={[styleComponent.base, style.component[this.props.kind]]}
                    checked={this.props.checked} onChange={this.props.onChange.bind(this)} />
            </div>
        )
    }
}

ConnectedInputSwitch = Radium(ConnectedInputSwitch);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const InputSwitch = connect(mapStateToProps, null)(ConnectedInputSwitch);

InputSwitch.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(InputSwitch);