import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';

import InputSwitch from '../../shared/components/form/input-switch.component';
import Config from '../../config';

class ConnectedQuiz extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quiz: [],
        };

        this.handleChangePublished = this.handleChangePublished.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            quiz: nextProps.quiz
        };
    }

    /**
     * Set state values of quiz
     *
     * @param {*} event
     */
    handleChangePublished(event) {
        let quiz = this.state.quiz;
        quiz.published = event.value;
        this.setState({ quiz: quiz });
        this.props.onChange(quiz);
    }

    render() {
        const quiz = this.state.quiz;
        const { store } = this.props;

        const styleContainer = {
            base: {
                background: 'transparent',
                border: `1px solid  ${store.style.color.shadow.base}`,
                borderRadius: '3px',
                color: '#000000',
                fontSize: '.8rem',
                margin: '0 1em 1em 0',
                padding: '0',
                width: '16rem',
                height: '12rem'
            }
        };

        const styleLocale = {
            marginRight: '.5rem'
        };

        const styleLocales = {
            paddingRight:'.3rem',
            paddingLeft:'.3rem'
        };

        const styleQuiz = {
            base: {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '9rem',
                zIndex: '20',
                ':hover': {
                    cursor: 'pointer'
                }
            }
        };

        const styleQuizText = {
            base: {
                fontWeight: 'bold',
                textAlign: 'center',
                zIndex: '30'
            },
            title: {
                fontSize: '1.8em'
            }
        };

        const styleQuizImage = {
            base: {
                backgroundImage: quiz.picture ? `url('${process.env.REACT_APP_IMAGES_DIRECTORY + quiz.picture}')` : '',
                backgroundSize: 'cover',
                borderRadius: '2px 2px 0 0',
                height: '9rem',
                opacity: '.5',
                position: 'absolute',
                width: '16rem',
                zIndex: '10',
                ':hover': {
                    cursor: 'pointer'
                }
            }
        };

        const styleStatus = {
            marginTop: '.2em',
            zIndex: '30'
        };

        const styleSwitch = {
            inputSwitch: {
                label: {
                    quiz: {
                        fontSize: '.9em',
                        fontWeight: 'bold',
                        zIndex: '30'
                    }
                },
                component: {
                    quiz: {
                        zIndex: '30'
                    }
                }
            }
        };

        const locales = this.props.quiz.locales.split(',');
        const dynamicLocales = locales.map((item, index) => {
            return <i className={`flag-icon flag-icon-${Config.locales.ICON[item]}`} style={styleLocale} title={item} id={item} key={item}></i>
        });

        return (
            <div style={[styleContainer.base]} >
                <div key="image" style={[styleQuizImage.base]} onClick={(e) => this.props.onClick(Config.action.MODIFY, { id: this.props.quiz.id })}></div>
                <div key="title" style={[styleQuiz.base]} onClick={(e) => this.props.onClick(Config.action.MODIFY, { id: this.props.quiz.id })}>
                    <div style={[styleQuizText.base, styleQuizText.title]}>{quiz.title}</div>
                    <div style={[styleQuizText.base]}>{quiz.questionsNumber} <i className="fa fa-question-circle"></i></div>
                </div>
                <div style={[styleStatus]} >
                    <div><InputSwitch label={quiz.published ? "app.published" : "app.draft"} checked={quiz.published} onChange={this.handleChangePublished} styles={styleSwitch} kind="quiz"  /></div>
                </div>
                <div style={styleLocales}>{dynamicLocales}</div>
            </div >
        );
    }
}

ConnectedQuiz = Radium(ConnectedQuiz);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const Quiz = connect(mapStateToProps, null)(ConnectedQuiz);

Quiz.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Quiz);
