import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';

class ConnectedInputFile extends Component {

    render() {

        let styleContainer = {
            base: {
                display: 'flex',
                flexDirection: 'column',
                padding: '0 .5em'
            }
        }

        return (
            <div style={[styleContainer.base]} >
                <input type="file" onChange={(e) => this.props.onChange(e.target.files)} accept={this.props.accept ? this.props.accept : ''} />
            </div>
        )
    }
}

ConnectedInputFile = Radium(ConnectedInputFile);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const InputFile = connect(mapStateToProps, null)(ConnectedInputFile);

InputFile.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(InputFile);