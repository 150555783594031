import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import CompanyAdmins from './components/company-admin.component';
import Dialog from '../shared/components/dialog.component';
import { Page } from '../shared/components/page/page.component';
import PageTitle from '../shared/components/page/page-title.component';
import ShelfDepartment from './components/shelf-department.component';
import { ShelfNoDepartment } from "./components/shelf-no-department.component";
import { setLastPage } from '../store/actions/app.action';
import { showError, showSuccess } from '../store/actions/message.action';
import UserIcon from '../shared/components/user-icon.component';
import UserIconNew from '../shared/components/user-icon-new.component';
import Config from '../config';

class ConnectedDepartmentsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            company: [],
            admins: [],
            departments: [],
            dialog: false,
            superadmin: false,
            toDeleteDepartmentManager: [],
            toDeleteDepartment: [],
        };
        
        this.handleAction = this.handleAction.bind(this);
        this.handleDepartmentDeleteManagerOnClick = this.handleDepartmentDeleteManagerOnClick.bind(this);
        this.handleDepartmentDeleteManagerOnHide = this.handleDepartmentDeleteManagerOnHide.bind(this);
        this.handleDepartmentDeleteManagerOnSubmit = this.handleDepartmentDeleteManagerOnSubmit.bind(this);
    }

    async componentDidMount() {
        let admins = [];
        let companyId = '';
        let company = [];
        let departments = [];

        const { id } = this.props.match.params;
        if (id) {
            companyId = id;
            const response = await http.get(`/manager/companies/${companyId}`);
            if (response.ok) {
                company = response.data.company;
            }
        } else {
            companyId = this.props.store.company.id;
            company = this.props.store.company;
        }

        // Get company admins
        const responseAdmins = await http.get(`/manager/companies/${companyId}/admins`);
        if (!responseAdmins.ok) {
            this.props.showError(responseAdmins.error);
        } else {
            admins = responseAdmins.data.users;
        }

        // Get company player departments
        const responseDepartments = await http.get(`/manager/companies/${companyId}/departments`);
        if (!responseDepartments.ok) {
            this.props.showError(responseDepartments.error);
        } else {
            departments = responseDepartments.data.departments;
        }

        this.setState({
            companyId: companyId,
            company: company,
            admins: admins,
            departments: departments
        });
    }

    /**
 * Action to chaining to next page
 * 
 * @param {*} action 
 * @param {*} data 
 */
    handleAction(action, data) {
        switch (action) {
            case Config.action.ADD:
                history.push(`/departments/new`);
                break;
            case Config.action.ADMIN:
                history.push(`/users/`);
                break;
            case Config.action.MODIFY:
                history.push(`/departments/modify/${data.id}`);
                break;
            case Config.action.RANKING:
                this.props.setLastPage(`/departments`);
                history.push(`/departments/ranking/${data.id}`);
                break;
            default:
        }
    }

    /**
     * Remove manager department
     * 
     * @param {*} user 
     * @param {*} department 
     */
    handleDepartmentDeleteManagerOnClick(user, department) {
        this.setState({ toDeleteDepartmentManager: user, toDeleteDepartment: department });
        this.setState({ dialog: true });
    }

    /**
     * Hide remove manager modal
     */
    handleDepartmentDeleteManagerOnHide() {
        this.setState({ dialog: false, toDeleteDepartmentManager: [], toDeleteDepartment: [] });
    }

    /**
     * Submit remove manager
     */
    async handleDepartmentDeleteManagerOnSubmit() {
        const { toDeleteDepartmentManager, toDeleteDepartment } = this.state;
        let departments = [];

        // Delete department admin
        const response = await http.del(`/manager/departments/${toDeleteDepartment.id}/admins/${toDeleteDepartmentManager.id}`);
        if (!response.ok) {
            this.props.showError(response.error);
        }

        // Update departments
        const responseDepartments = await http.get('/manager/companies/' + this.props.store.company.id + '/departments');
        if (!responseDepartments.ok) {
            this.props.showError(responseDepartments.error);
        } else {
            departments = responseDepartments.data.departments;
        }

        this.setState({ departments: departments, dialog: false, toDeleteDepartmentManager: [], toDeleteDepartment: [] });
    }

    render() {

        const pageTitle = this.props.intl.formatMessage({ id: 'app.admin' }) +
            " - " + this.state.company.name;

        const dynamicAdminIcons = this.state.admins.map((val, i) => {
            return <UserIcon key={i} user={val} />
        });

        const dynamicDepartments = this.state.departments.map((val, i) => {
            return <ShelfDepartment key={i} department={val} admin={this.props.store.player.admin} onClick={this.handleAction} />
        });

        // TODO: no
        return (
            <Page title={pageTitle}>
                <CompanyAdmins>
                    {dynamicAdminIcons}
                    {this.props.store.player.admin && (<UserIconNew onClick={this.handleAction} />)}
                </CompanyAdmins>
                <PageTitle title={this.props.intl.formatMessage({ id: 'app.managers' }) + ' - ' + this.props.intl.formatMessage({ id: 'app.departments' })}
                    action={this.props.store.player.admin ? Config.action.ADD : ''} onClick={this.handleAction} />
                {this.state.departments.length === 0 && (
                    <ShelfNoDepartment onClick={this.handleAction} />
                )}
                {this.state.departments.length > 0 && (<div>{dynamicDepartments}</div>)}

                <Dialog visible={this.state.dialog}
                    text={this.props.intl.formatMessage({ id: 'app.dialog.delete.department.admin' }, { firstname: this.state.toDeleteDepartmentManager.firstname, lastname: this.state.toDeleteDepartmentManager.lastname })}
                    onHide={this.handleDepartmentDeleteManagerOnHide} onSubmit={this.handleDepartmentDeleteManagerOnSubmit} />
            </Page >
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            player: state.player,
            style: state.style,
            user: state.user
        }
    };
};

const mapDispatchToProps = {
    setLastPage,
    showError, showSuccess
};

const DepartmentsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedDepartmentsPage);

DepartmentsPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(DepartmentsPage);
