import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Button } from '../shared/components/button.component';
import InputSwitch from '../shared/components/form/input-switch.component';
import { InputMultiSelect } from '../shared/components/form/input-multiselect.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import ImageUpload from '../shared/components/form/image-upload.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import ThemeDetail from './components/theme-detail.component';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';
import { InputText } from "../shared/components/form/input-text.component";

class ConnectedThemeCreatePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            submitDisable: false,
            availableDepartments: [],
            departments: [],
            theme: {
                title: [],
                description: [],
                picture: null,
                color: null,
                published: true,
                locales: [Config.app.DEFAULT_LOCALE]
            },
            pictureFile: null,
            picture: null,
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleChangeMultiSelect = this.handleChangeMultiSelect.bind(this);
        this.handleChangePublished = this.handleChangePublished.bind(this);
        this.handleChangeThemeColor = this.handleChangeThemeColor.bind(this);
        this.handleChangeTheme = this.handleChangeTheme.bind(this);
        this.handleChangePicture = this.handleChangePicture.bind(this);
        this.handleClickPicture = this.handleClickPicture.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        let availableDepartments = [];
        // Get list of available departments of company for user
        const response = await http.get(`/manager/players/${this.props.store.player.id}/companies/${this.props.store.company.id}/managedDepartments`);
        if (response.ok) {
            const departments = response.data.departments;
            availableDepartments = departments.map(item => {
                return { label: item.name, value: item.id };
            });
        }

        this.setState({ availableDepartments: availableDepartments });
    }

    /**
     * Action to chaining to next page
     *
     * @param {*} action
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push('/themes');
                break;
            default:
        }
    }

    /**
     * After each field key press, update state of the value
     *
     * @param {*} event
     */
    handleChangeLocales(event) {
        let theme = this.state.theme;
        if (event.value.length > 0) {
            theme.locales = event.value;
        }
        this.setState({ theme: theme });
    }

    /**
     * After each field key press, update state of the value
     *
     * @param {*} event
     */
    handleChangeMultiSelect(event) {
        this.setState({ departments: event.value });
    }

    /**
     * Set state published value of theme
     *
     * @param {*} event
     */
    handleChangePublished(event) {
        let theme = this.state.theme;
        theme.published = event.value;
        this.setState({ theme: theme });
    }

    handleChangeThemeColor(event) {
        let theme = this.state.theme;
        theme.color = event.target.value;
        this.setState({ theme: theme });
    }

    /**
     * Set state values of theme
     *
     * @param {*} theme
     */
    handleChangeTheme(theme) {
        this.setState({ theme: theme });
    }

    handleClickPicture() {
        this.setState({
            dirty: false,
            picture: null,
            pictureFile: null,
            theme: { ...theme, picture: null }
        });
    }

    handleChangePicture(files) {
        const pictureFile = files[0];
        if (pictureFile) {
            let theme = this.state.theme;
            theme.picture = pictureFile.name;
            this.setState({ pictureFile, theme, dirty: true, picture: URL.createObjectURL(pictureFile) });
        }
    }

    /**
     * Submit theme
     *
     * @param {*} event
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, submitDisable: true });
        const { theme, pictureFile, departments } = this.state;

        let departmentsOK = true;
        // On oblige à lier à un département
        /*if (this.props.store.player.manager
            && !this.props.store.player.admin
            && departments.length === 0) {
            departmentsOK = false;
        }*/
        if (departments.length === 0) {
            departmentsOK = false;
        }

        if (Object.keys(theme.title).length > 0 && departmentsOK) {
            // Send picture to API, response is an anonimised reference name
            let pictureName = theme.picture;
            let pictureOK = true;

            if (pictureFile && pictureFile.name) {
                let dataPicture = new FormData();
                dataPicture.append('image', pictureFile);
                const responsePicture = await http.postFile('/upload/image', dataPicture);
                if (!responsePicture.ok) {
                    pictureOK = false;
                    this.props.showError(responsePicture.error, ['app.picture.not.uploaded']);
                } else {
                    pictureName = responsePicture.data.image;
                }
            }

            if (pictureOK) {
                const data = {
                    theme: { ...theme, picture: pictureName, locales: theme.locales.toString() },
                    departments: departments
                };

                const response = await http.post(`/manager/themes/company/${this.props.store.company.id}`, data);
                if (!response.ok) {
                    this.props.showError(response.error, ['app.theme.not.created']);
                } else {
                    history.push(`/themes`);
                }
            }
        }

        this.setState({ submitDisable: false });
    }

    render() {

        const styleMultiSelect = {
            marginBottom: '0',
            width: '100%'
        };

        const styleParameters = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginBottom: '.3rem'
        }

        const dynamicDetails = this.state.theme.locales.map((locale) => {
            return <ThemeDetail key={locale} lang={locale} theme={this.state.theme}
                onChange={this.handleChangeTheme}
                submitted={this.state.submitted} styles={this.props.store.style} />
        });

        return (
            <Page>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.new.theme' })}
                    onClick={this.handleAction} back={Config.action.BACK}>

                    <div className="row">
                        <div className="col-6">
                            <div style={styleParameters}>
                                <InputMultiSelect name="department" label="app.department" values={this.state.departments} options={this.state.availableDepartments}
                                    onChange={this.handleChangeMultiSelect} submitted={this.state.submitted} mandatory={true}
                                    styles={styleMultiSelect} />
                            </div>
                            <div style={styleParameters}>
                                <InputSwitch label={this.state.theme.published ? "app.published" : "app.draft"} kind="theme"
                                    checked={this.state.theme.published} onChange={this.handleChangePublished} />
                            </div>
                            <div style={styleParameters}>
                                <InputText label={"app.color"} kind="theme"
                                    inputType={'color'} value={this.state.theme.color}
                                    inputStyle={{ padding: 0 }}
                                    onChange={this.handleChangeThemeColor} />
                            </div>
                            <div style={styleParameters}>
                                <InputMultiSelectInline name="locales" label="app.language" kind="theme"
                                    options={Config.locales.LIST} value={this.state.theme.locales}
                                    onChange={this.handleChangeLocales} />
                            </div>
                        </div>
                        <div className="col-6">
                            <ImageUpload name="picture" imageLabel="app.choose.image.label"
                                styles={this.props.store.style} kind="primary" onClick={this.handleClickPicture}
                                onChange={this.handleChangePicture} value={this.state.picture} label="app.choose.image.title"/>
                        </div>
                    </div>

                    {dynamicDetails}

                    <div className="float-right">
                        <Button label="app.save" onClick={(e) => this.handleSubmit(e)}
                            styles={this.props.store.style} kind="primary" />
                    </div>

                </PageCard>
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            player: state.player,
            style: state.style
        }
    };
};

const mapDispatchToProps = {
    showError, showSuccess
};

const ThemeCreatePage = connect(mapStateToProps, mapDispatchToProps)(ConnectedThemeCreatePage);

ThemeCreatePage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ThemeCreatePage);
