import React from 'react';
import { IntlMessage } from '../intl-message.component';
import Config from '../../../config';

export const InputPassword = React.forwardRef((props, ref) => (
    <div className="form-group">
        <label><IntlMessage id={props.label} /></label>
        <input ref={ref} type="password" className="form-control ui-corner-all" id={props.name} 
            name={props.name} value={props.value} placeholder={props.placeholder} 
            maxlength={props.maxLength ? props.maxLength : Config.inputText.DEFAULT_MAX_LENGTH} 
            onChange={props.onChange.bind(this)}
            disabled={props.disabled ? props.disabled : false} ></input>
        {props.submitted && props.mandatory && !props.value &&
            <div className="help-block"><IntlMessage id="app.mandatory.field" /></div>
        }
    </div>
));