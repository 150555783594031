
export const SET_STYLE = '[Style] Set style';

const Color = {
    initial: {
        base: '#3a3f51',
        hover: '#2e3240',
        opposite: '#ffffff'
    },
    primary: {
        base: '#04b76b',
        hover: '#039255',
        opposite: '#ffffff'
    },
    danger: {
        base: '#ff5e6d',
        hover: '#cc4b57',
        opposite: '#ffffff'
    },
    light: {
        base: '#dedede',
        hover: '#b1b1b1',
        opposite: '#000000'
    },
    flash: {
        base: '#e0178e',
        hover: '#b31271',
        opposite: '#ffffff'
    },
    shadow: {
        base: '#e0e0e0',
        hover: '#b3b3b3',
        opposite: '#cbcbcb'
    }
}

export const initialState = {
    color: Color,
    common: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
    },
    button: {
        danger: {
            background: Color.danger.base,
            border: `1px solid ${Color.danger.base}`,
            color: Color.danger.opposite,
            minWidth: '8em',
            ':hover': { 
                background: Color.danger.hover,
                border: `1px solid ${Color.danger.hover}`
            }
        },
        primary: {
            background: Color.primary.base,
            border: `1px solid ${Color.primary.base}`,
            color: Color.primary.opposite,
            minWidth: '8em',
            ':hover': { 
                background: Color.primary.hover,
                border: `1px solid ${Color.primary.hover}`
            }
        },
        secondary: {
            background: 'transparent',
            border: `1px solid ${Color.primary.base}`,
            color: Color.primary.base,
            minWidth: '8em',
            ':hover': { 
                background: Color.primary.base,
                border: `1px solid ${Color.primary.base}`,
                color: Color.primary.opposite,
            }
        },
        secondarySmall: {
            background: 'transparent',
            border: `1px solid ${Color.primary.base}`,
            color: Color.primary.base,
            fontSize: '.8em',
            height: '2em',
            padding: '5px 8px 5px 8px',
            ':hover': { 
                background: Color.primary.base,
                border: `1px solid ${Color.primary.base}`,
                color: Color.primary.opposite,
            }
        },
        expandedPrimary: {
            background: Color.primary.base,
            border: `1px solid ${Color.primary.base}`,
            color: Color.primary.opposite,
            width: '12em',
            ':hover': { 
                background: Color.primary.hover,
                border: `1px solid ${Color.primary.hover}`
            }
        },
        expandedSecondary: {
            background: 'transparent',
            border: `1px solid ${Color.primary.base}`,
            color: Color.primary.base,
            width: '12em',
            ':hover': { 
                background: Color.primary.base,
                border: `1px solid ${Color.primary.base}`,
                color: Color.primary.opposite,
            }
        },
        extend: {
            background: 'transparent',
            border: `1px solid ${Color.primary.base}`,
            color: Color.primary.base,
            width: '100%',
            ':hover': { 
                background: Color.primary.base,
                border: `1px solid ${Color.primary.base}`,
                color: Color.primary.opposite,
            }
        }
    },
    buttonIcon: {
        base: {
            background: Color.initial.base,
            border: `1px solid ${Color.initial.base}`,
            color: Color.initial.opposite,
            height: '1.8rem',
            marginLeft: '.2rem',
            width: '1.8rem'
        },
        danger: {
            background: Color.danger.base,
            border: `1px solid ${Color.danger.base}`,
            color: Color.danger.opposite,
            ':hover': { 
                background: Color.danger.hover,
                border: `1px solid ${Color.danger.hover}`
            }
        },
        dangerSecondary: {
            background: 'transparent',
            border: `1px solid ${Color.danger.base}`,
            color: Color.danger.base,
            ':hover': { 
                background: Color.danger.base,
                border: `1px solid ${Color.danger.base}`,
                color: Color.danger.opposite
            }
        },
        dangerSmall: {
            background: Color.danger.base,
            border: `1px solid ${Color.danger.base}`,
            color: Color.danger.opposite,
            height: '1rem',
            marginLeft: '.2rem',
            width: '1rem'
        },
        primary: {
            background: Color.primary.base,
            border: `1px solid ${Color.primary.base}`,
            color: '#ffffff',
            ':hover': { 
                background: Color.primary.hover,
                border: `1px solid ${Color.primary.hover}`
            }
        },
        secondary: {
            background: 'transparent',
            border: `1px solid ${Color.primary.base}`,
            color: Color.primary.base,
            ':hover': { 
                background: Color.primary.base,
                border: `1px solid ${Color.primary.base}`,
                color: Color.primary.opposite,
            }
        },
        light: {
            background: Color.light.base,
            border: `1px solid ${Color.light.base}`,
            color: Color.light.opposite,
            ':hover': { 
                background: Color.light.hover,
                border: `1px solid ${Color.light.hover}`
            }
        }
    },
    CircularProgressBar: {
        secondary: {
            path: {
                stroke: Color.primary.base
            },
            text: {
                'text-align': 'center',
                fill: Color.primary.base,
                fontSize: '16px'
            }
        },
        dangerSecondary: {
            path: {
                stroke: Color.danger.base
            },
            text: {
                'text-align': 'center',
                fill: Color.danger.base,
                fontSize: '16px'
            },
        }
    },
    icon: {
        fontSize: '.8em'
    },
    question: {
        container: {
            question: {
                background: 'transparent',
                border: '1px solid #e0e0e0',
                color: '#000000'
            },
            quiz: {
                margin: '.2em .5em .2em .5em',
                fontSize: '.9em'
            },
            closed: {
                border: '1px solid lightgray',
                color: '#000000',
                background: '#e0e0e0',
                WebkitFilter: 'grayscale(100%)',
                filter: 'grayscale(100%)',
                fontStyle: 'italic',

            }
        },
    },
    sideMenuItem: {
        container: {
            active: {
                background: Color.flash.base,
                color: Color.flash.opposite,
                ':hover': {
                    background: Color.flash.base,
                    color: Color.flash.opposite,
                }
            }
        }
    },
    imageUpload: {
        image: {
            container: {
                backgroundColor: Color.shadow.base,
                border: `1px dashed ${Color.shadow.opposite}`
            }
        }
    },
    inputSwitch: {
        component: {},
        container: {
            question: {
                padding: '0 .5em .5em .5em',
            },
            theme: {
                padding: '0',
            },
        },
        label: {}
    },
    InputMultiSelectInline: {
        container: {
            quiz: {
                marginBottom: '2rem'
            },
            question: {
                marginBottom: '2rem'
            },
            theme: {
                marginBottom: '1.7rem',
                width: '100%'
            }
        }
    },
    InputSelectInline: {
        container: {
            profil: {
                marginBottom: '2em'
            }
        }
    },
    theme: {
        background: 'transparent',
        border: '1px solid #e0e0e0',
        color: '#000000'
    },
}

export const setStyle = style => {
    return { type: SET_STYLE, style: style }
}
