import React, { Component } from 'react';
import ButtonRounded from '../../shared/components/button-rounded.component';

export class QuestionExtend extends Component {

    render() {
        return (
            <ButtonRounded label="app.question.extend" onClick={this.props.onClick}
                                    styles={this.props.styles} kind="extend" />
        );
    }
}
