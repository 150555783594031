import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Button } from '../shared/components/button.component';
import { Form } from '../shared/components/form/form.component';
import ImageUpload from '../shared/components/form/image-upload.component';
import { InputAutoComplete } from '../shared/components/form/input-autocomplete.component';
import InputDatetime2 from '../shared/components/form/input-datetime2.component';
import { InputText } from '../shared/components/form/input-text.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { PageSubTitle } from '../shared/components/page/page-sub-title.component';
import { PickList } from 'primereact/picklist';
import { setLastPage } from '../store/actions/app.action';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';
import './group-create-page.component.css';

class ConnectedGroupCreatePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            submitDisable: false,
            group: {
                name: '',
                description: '',
                picture: '',
                beginsTo: null,
                closeTo: null,
            },
            picture: null,
            pictureFile: [],
            sourceUsers: [],
            targetUsers: [],
            users: [],
            user: null
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeUsers = this.handleChangeUsers.bind(this);
        this.handleChangePicture = this.handleChangePicture.bind(this);
        this.handleClickPicture = this.handleClickPicture.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.pickListOnChange = this.pickListOnChange.bind(this);
    }

    async componentDidMount() {
        const { store: { company } } = this.props;
        let users = [];

        // Get company users
        const response = await http.get(`/manager/companies/${company.id}/users`);
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            users = response.data.users;
        }

        this.setState({ sourceUsers: users, users });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push('/groups');
                break;
            default:
        }
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChange(event) {
        const { name, value } = event.target;
        const group = this.state.group;
        group[name] = value;
        this.setState({ group });
    }

    /**
     * Set picture file
     * 
     * @param {*} files 
     */
    handleChangePicture(files) {
        const pictureFile = files[0];
        if (pictureFile) {
            let group = this.state.group;
            group.picture = pictureFile.name;
            this.setState({
                dirty: true,
                group,
                pictureFile,
                picture: URL.createObjectURL(pictureFile)
            });
        }
    }

    /**
      * Change users
      * 
      * @param {*} event 
      */
    handleChangeUsers(event) {
        const { targetUsers, users } = this.state;
        const { value } = event;
        const sourceUsers = users.filter(user => {
            const index = targetUsers.map(function (e) { return e.id; }).indexOf(parseInt(user.id, 10));
            const name = user.firstname.toLowerCase() + user.lastname.toLowerCase();
            return name.includes(value.toLowerCase()) && index === -1;
        });
        this.setState({ sourceUsers, user: value });
    }

    /**
     * Set state values of picture
     */
    handleClickPicture() {
        let group = this.state.group;
        group.picture = '';
        this.setState({ dirty: false, group, picture: null });
    }

    /**
     * Submit group
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();

        const { store: { company } } = this.props;
        const { group, pictureFile, targetUsers } = this.state;
        this.setState({ submitted: true, submitDisable: true });

        if (Object.keys(group.name).length > 0) {

            // Send picture to API, response is an anonimised reference name
            let pictureName = group.picture;
            let pictureOK = true;
            if (pictureFile.name) {
                let dataPicture = new FormData();
                dataPicture.append('image', pictureFile);
                const responsePicture = await http.postFile('/upload/image', dataPicture);
                if (!responsePicture.ok) {
                    pictureOK = false;
                    this.props.showError(responsePicture.error, ['app.picture.not.uploaded']);
                } else {
                    pictureName = responsePicture.data.image
                }
            }

            if (pictureOK) {
                const data = {
                    company: company.id,
                    group: { ...group, picture: pictureName },
                    managers: targetUsers,
                };

                const response = await http.post(`/manager/groups`, data);
                if (!response.ok) {
                    this.props.showError(response.error, ['app.group.not.created']);
                } else {
                    this.props.initializeDepartmentCreate();
                    history.push(`/groups`);
                };
            }
        };

        this.setState({ submitDisable: false });
    }

    /**
     * Event called when a user move to another column
     * 
     * @param {*} event 
     */
    pickListOnChange(event) {
        this.setState({
            sourceUsers: event.source,
            targetUsers: event.target
        });
    }

    /**
     * Questions template of pick list column
     * 
     * @param {*} user 
     */
    pickListUserTemplate(user) {
        return (
            <div>{user.firstname} {user.lastname}</div>
        );
    }

    render() {
        const {
            intl: { formatMessage },
            store: {
                style
            },
        } = this.props;

        const { submitted, submitDisable, group, picture, sourceUsers, targetUsers, user } = this.state;

        const styleAutoComplete = {
            marginBottom: '.5em'
        };

        return (
            <Page>
                <PageCard title={formatMessage({ id: 'app.new.group' })}
                    onClick={this.handleAction} back={Config.action.BACK} >
                    <Form onSubmit={this.handleSubmit}>

                        <div className="o-group-create-info row">
                            <div className="col-6">
                                <InputText label='app.group.name' mandatory='true' name='name'
                                    onChange={this.handleChange} submitted={submitted} value={group.name} />

                                <InputText name="description" label="app.group.description" value={group.description}
                                    onChange={this.handleChange} />

                                <div className="row">
                                    <div className="col-6">
                                        <InputDatetime2
                                            name='beginsTo'
                                            label={formatMessage({ id: 'app.begins.date' })}
                                            styles={style}
                                            value={group.beginsTo}
                                            onChange={this.handleChange}
                                            disabled={submitDisable}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <InputDatetime2
                                            name='closeTo'
                                            label={formatMessage({ id: 'app.close.date' })}
                                            styles={style}
                                            value={group.closeTo}
                                            onChange={this.handleChange}
                                            disabled={submitDisable}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <ImageUpload kind='primary' name='picture'
                                    onChange={this.handleChangePicture}
                                    onClick={this.handleClickPicture}
                                    value={picture} styles={style} />
                            </div>
                        </div>

                        <PageSubTitle title={formatMessage({ id: 'app.add.administrators' })} />

                        <div className='o-group-create-picklist-filter'>
                            <div className='o-group-create-picklist-filter-left'>
                                <InputAutoComplete field='user' label='app.find.user'
                                    onChange={this.handleChangeUsers}
                                    styles={styleAutoComplete} value={user} />
                            </div>
                            <div className='o-group-create-picklist-filter-center'></div>
                            <div className='o-group-create-picklist-filter-right'>
                            </div>
                        </div>

                        <div className='opp-picklist'>
                            <PickList itemTemplate={this.pickListUserTemplate}
                                onChange={this.pickListOnChange}
                                responsive={true}
                                showSourceControls={false} showTargetControls={false}
                                source={sourceUsers}
                                sourceHeader={formatMessage({ id: 'app.users.availables' })}
                                sourceStyle={{ 'height': '30em' }}
                                target={targetUsers}
                                targetHeader={formatMessage({ id: 'app.users.selected' })}
                                targetStyle={{ 'height': '30em' }} />
                        </div>

                        <div className='.o-group-create-button-action'>
                            <div>
                                <Button disabled={submitDisable ? submitDisable : false}
                                    kind='primary' label="app.save" onClick={this.handleSubmit}
                                    styles={style} />
                            </div>
                        </div>
                    </Form>
                </PageCard>
            </Page >
        );
    }
}

const mapStateToProps = ({ app, company, group, style }) => {
    return { store: { app, company, group, style } };
};

const mapDispatchToProps = {
    setLastPage,
    showError, showSuccess
};

const GroupCreatePage = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupCreatePage);

GroupCreatePage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(GroupCreatePage);
