import React, { Component } from "react";
import { connect } from "react-redux";
import Radium from 'radium';
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';


import ButtonRounded from '../shared/components/button-rounded.component';
import InputFile from '../shared/components/form/input-file.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { PageSubTitle } from '../shared/components/page/page-sub-title.component';
import { showError, showSuccess, showWarning } from '../store/actions/message.action';
import Config from '../config';

class ConnectedUserUploadPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            company: [],
            errors: [],
            error: '',
            file: [],
            lastPage: '',
            submitted: false,
            submitDisable: true,
            valids: []
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        let company = [];
        let superadmin = false;

        if (this.props.store.users.company.length !== 0) {
            if (this.props.store.user.role !== Config.app.role.SUPER_ADMIN) {
                this.props.showError({ summary: 'app.error.forbidden' });
                history.push('/');
            }
            superadmin = true;
            company = this.props.store.users.company;
        } else {
            superadmin = false;
            company = this.props.store.company
        }

        this.setState({
            company: company,
            lastPage: this.props.store.app.lastPage,
            superadmin: superadmin
        });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.state.lastPage);
                break;
            default:
        }
    }

    /**
     * Set file
     * 
     * @param {*} files 
     */
    handleChangeFile(files) {
        const file = files[0];
        if (file) {
            this.setState({ file: file, submitDisable: false });
        }
    }

    /**
     * Submit user
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, submitDisable: true });
        if (this.state.file) {
            let data = new FormData();
            data.append('file', this.state.file);
            const response = await http.postFile(`/manager/companies/${this.state.company.id}/users/upload`, data);
            if (!response.ok) {
                this.props.showError(response.error);
            } else {
                if (0 === response.data.errors.length) {
                    this.props.showSuccess({ summary: 'app.file.uploaded' });
                    history.push(this.state.lastPage);
                } else if (0 < response.data.valids.length) {
                    this.props.showWarning({ summary: 'app.file.uploaded.with.errors' });
                    this.setState({ valids: response.data.valids, errors: response.data.errors });
                } else {
                    this.props.showError({ summary: 'app.file.not.uploaded' });
                    this.setState({ errors: response.data.errors });
                }
            }
        }
        this.setState({ submitDisable: false });
    }

    render() {

        const styleSpecification = {
            marginBottom: '1rem'
        };

        const styleSpecificationItem = {
            marginBottom: '1rem'
        };

        const styleSpecificationSample = {
            fontFamily: 'Courier New'
        };

        const styleHeader = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '1em',
            marginBottom: '.3em'
        };

        const styleErrors = {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem'
        };

        const styleError = {
            display: 'flex',
            flexDirection: 'row'
        };

        const styleAttribut = {
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '1em'
        };

        const dynamicErrors = this.state.errors.map((error, index) => {
            return (
                <div style={styleError} key={index}>
                    <div style={styleAttribut}>{this.props.intl.formatMessage({ id: 'app.upload.line' })} {error.id} :</div>
                    {error.name && (<div style={styleAttribut}>{error.name}</div>)}
                    {error.firstname && (<div style={styleAttribut}>{error.firstname}</div>)}
                    {error.email && (<div style={styleAttribut}>{error.email}</div>)}
                    {error.lang && (<div style={styleAttribut}>{error.lang}</div>)}
                    {error.function && (<div style={styleAttribut}>{error.function}</div>)}
                    {error.department && (<div style={styleAttribut}>{error.department}</div>)}
                </div>
            );
        });

        return (
            <Page title={this.state.superadmin ? this.state.company.name : ''}>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.upload.users' })} onClick={this.handleAction} back={Config.action.BACK}>
                    <div className="card">
                        <div className="card-body">
                            <div style={styleSpecification}>
                                <PageSubTitle title={this.props.intl.formatMessage({ id: 'app.user.upload.how.to.import' })} />
                                <div style={styleSpecificationItem}>{this.props.intl.formatMessage({ id: 'app.user.upload.spec01' })}</div>
                                <PageSubTitle title={this.props.intl.formatMessage({ id: 'app.user.upload.columns' })} />
                                <div style={styleSpecificationItem}>{this.props.intl.formatMessage({ id: 'app.user.upload.spec02' })}</div>
                                <PageSubTitle title={this.props.intl.formatMessage({ id: 'app.user.upload.comments' })} />
                                <div style={styleSpecificationItem}>{this.props.intl.formatMessage({ id: 'app.user.upload.spec03' }) + ' ' + Config.locales.VALUES + '.'}</div>
                                <div style={styleSpecificationItem}>{this.props.intl.formatMessage({ id: 'app.user.upload.spec04' })}</div>
                                <div style={styleSpecificationItem}>{this.props.intl.formatMessage({ id: 'app.user.upload.spec05' })}</div>
                                <div style={styleSpecificationItem}>{this.props.intl.formatMessage({ id: 'app.user.upload.spec06' })}</div>
                                <div style={styleSpecificationItem}>{this.props.intl.formatMessage({ id: 'app.user.upload.spec07' })}</div>
                                <div style={styleSpecificationItem}>{this.props.intl.formatMessage({ id: 'app.user.upload.spec08' })}</div>
                                <div style={styleSpecificationItem}>{this.props.intl.formatMessage({ id: 'app.upload.users.limit' })} {this.state.company.uploadUserLimit}</div>
                                <PageSubTitle title={this.props.intl.formatMessage({ id: 'app.user.upload.sample' })} />
                                <div style={styleSpecificationSample}>
                                    <div>Sinclair;Jeffrey;jeffrey@mail.xy;en;JO01;Team leader</div>
                                    <div>Ivanova;Susan;susan@mail.xy;en;JO01;Crew member</div>
                                    <div>Garibaldi;Michael;michael@mail.xy;en;JO01/SEC;Security</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={styleHeader}>
                        <InputFile name="file" label="app.file.choose" onChange={this.handleChangeFile} accept=".csv" />
                        <ButtonRounded label="app.file.upload" onClick={this.handleSubmit}
                            disabled={this.state.submitDisable} kind="primary" />
                    </div>
                    {this.state.errors.length > 0 && (
                        <div style={styleErrors}>
                            {dynamicErrors}
                        </div>)}
                </PageCard>
            </Page>
        );
    }
}

ConnectedUserUploadPage = Radium(ConnectedUserUploadPage);

const mapStateToProps = state => {
    return {
        store: {
            app: state.app,
            company: state.company,
            user: state.user,
            users: state.users
        }
    };
};

const mapDispatchToProps = {
    showError, showSuccess, showWarning
};

const UserUploadPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserUploadPage);

UserUploadPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(UserUploadPage);
