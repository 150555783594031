import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';

import { ButtonRoundedIcon } from '../../shared/components/button-rounded-icon.component';
import UserBox from '../../shared/components/user-box.component';
import { Shelf } from '../../shared/components/shelf.component';
import Config from '../../config';

class ConnectedShelfDepartment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false
        };
    }

    render() {

        const styleButton = {
            base: {
            }
        };

        const styleHeaderContainer = {
            base: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingLeft: '.5em'
            }
        }

        const styleUserContainer = {
            base: {
                display: 'flex',
                flexDirection: 'row'
            }
        };

        const dynamicAdminsIcons = this.props.department.admins.map((val, i) => {
            return <UserBox key={i} user={val} department={this.props.department} />
        });

        return (
            <Shelf>
                <div style={[styleHeaderContainer.base]}>
                    <h5>{this.props.department.name}</h5>
                    <div style={[styleButton.base]}>
                        <ButtonRoundedIcon icon="fa fa-trophy" onClick={(e) => this.props.onClick(Config.action.RANKING, { id: this.props.department.id })}
                            styles={this.props.store.style} kind="secondary" />
                        <ButtonRoundedIcon icon="fa fa-pen" onClick={(e) => this.props.onClick(Config.action.MODIFY, { id: this.props.department.id })}
                            styles={this.props.store.style} kind="secondary" />
                    </div>
                </div>
                <div style={[styleUserContainer.base]}>
                    {dynamicAdminsIcons}
                </div>
            </Shelf>
        );
    }
}

ConnectedShelfDepartment = Radium(ConnectedShelfDepartment);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const ShelfDepartment = connect(mapStateToProps, null)(ConnectedShelfDepartment);

export default ShelfDepartment;