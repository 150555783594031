export const ADD_PLAYER = '[Player] Add player';
export const INITIALIZE_PLAYER = '[Player] Initialize player';

export const initialState = {
     id: null,
     userId: null,
     admin: false,
     manager: false,
     firstQuiz: false,
     quizDone: 0,
     duelDone: 0,
     duelWin: 0,
     duelLost: 0,
     duelNull: 0,
}

export const addPlayer = player => {
    return {type: ADD_PLAYER, player: player}
}

export const initializePlayer = () => {
    return {type: INITIALIZE_PLAYER, player: initialState}
}