import React, { Component } from 'react';
import PageTitle from './page-title.component';

export class Page extends Component {
    render() {
        return (
            <div>
                {this.props.title && (<PageTitle title={this.props.title} />)}
                {this.props.header}
                <div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}