import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';
import { SelectButton } from 'primereact/selectbutton';

class ConnectedInputMultiSelectInline extends Component {

    constructor(props) {
        super(props);

        this.state = {
            options: []
        }
    }

    componentDidMount() {
        const options = this.props.options.map(item => {
            return { value: item.value, label: this.props.intl.formatMessage({ id: item.label }) };
        });
        
        this.setState({options: options});
    }

    render() {
        const common = this.props.store.style.common;
        const style = this.props.store.style.InputMultiSelectInline;

        let styleContainer = {
            base: {
                ...common
            }
        }

        const styleLabel = {
            display: 'flex',
            margin: '0'
        }

        const styleLabelContent = {
            margin: 'auto 0'
        }

        return (
            <div className="row" style={[styleContainer, style.container[this.props.kind]]}>
                { 'label' in this.props && (<label className="col-sm-2" style={[styleLabel]}>
                    <div style={[styleLabelContent]}>{this.props.intl.formatMessage({ id: this.props.label })}</div>
                    </label>) }
                <div className="col-sm-10">
                    <SelectButton name={this.props.name} multiple={true} value={this.props.value} options={this.state.options} onChange={this.props.onChange.bind(this)}></SelectButton>
                    {this.props.submitted && this.props.mandatory && (!this.props.value || (this.props.value === "null")) &&
                        <div className="help-block">*{this.props.intl.formatMessage({ id: this.props.alert ? this.props.alert : 'app.mandatory.field' })}</div>
                    }
                </div>
            </div>
        )
    }
}

ConnectedInputMultiSelectInline = Radium(ConnectedInputMultiSelectInline);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const InputMultiSelectInline = connect(mapStateToProps, null)(ConnectedInputMultiSelectInline);

InputMultiSelectInline.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(InputMultiSelectInline);