import React, { Component } from "react";
import { connect } from "react-redux";
import Radium from 'radium';
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';


import ButtonRounded from '../shared/components/button-rounded.component';
import InputFile from '../shared/components/form/input-file.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { PageSubTitle } from '../shared/components/page/page-sub-title.component';
import { setPage } from '../store/actions/app.action';
import { showError, showSuccess, showWarning } from '../store/actions/message.action';
import Config from '../config';

class ConnectedQuizUploadPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            error: '',
            file: [],
            quiz: {
                title: {}
            },
            submitted: false,
            submitDisable: true,
            valids: []
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        let quiz = [];

        this.props.setPage('/quizzes/' + id + '/upload');

        // Get quiz entity
        const response = await http.get(`/manager/quizzes/${id}`);
        if (response.ok) {
            quiz = response.data.quiz;
        }

        this.setState({
            quiz: quiz
        });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            default:
        }
    }

    /**
     * Set file
     * 
     * @param {*} files 
     */
    handleChangeFile(files) {
        const file = files[0];
        if (file) {
            this.setState({ file: file, submitDisable: false });
        }
    }

    /**
     * Submit user
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true, submitDisable: true });
        if (this.state.file) {
            let data = new FormData();
            data.append('file', this.state.file);
            const response = await http.postFile(`/manager/quizzes/${this.state.quiz.id}/questions/upload`, data);
            if (!response.ok) {
                this.props.showError(response.error);
            } else {
                if (0 === response.data.errors.length) {
                    this.props.showSuccess({ summary: 'app.file.uploaded' });
                    history.push(this.props.store.app.lastPage);
                } else if (0 < response.data.valids.length) {
                    this.props.showWarning({ summary: 'app.file.uploaded.with.errors' });
                    this.setState({ valids: response.data.valids, errors: response.data.errors });
                } else {
                    this.props.showError({ summary: 'app.file.not.uploaded' });
                    this.setState({ errors: response.data.errors });
                }
            }
        }
        this.setState({ submitDisable: false });
    }

    render() {
        const { intl } = this.props;
        const { quiz } = this.state;
        const lang = this.props.store.user.locale;

        const styleSpecification = {
            marginBottom: '1rem'
        };

        const styleSpecificationItem = {
            marginBottom: '1rem'
        };

        const styleSpecificationSample = {
            fontFamily: 'Courier New',
            whiteSpace: 'nowrap',
            overflow: 'scroll'
        };

        const styleHeader = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '1em',
            marginBottom: '.3em'
        };

        const styleErrors = {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem'
        };

        const styleError = {
            display: 'flex',
            flexDirection: 'row'
        };

        const styleAttribut = {
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '1em'
        };

        const dynamicErrors = this.state.errors.map((error, index) => {
            return (
                <div style={styleError} key={index}>
                    <div style={styleAttribut}>{this.props.intl.formatMessage({ id: 'app.upload.line' })} {error.id} :</div>
                    {error.lang && (<div style={styleAttribut}>{error.lang}</div>)}
                    {error.statement && (<div style={styleAttribut}>{error.statement}</div>)}
                    {error.answer && (<div style={styleAttribut}>{error.answer}</div>)}
                    {error.comment && (<div style={styleAttribut}>{error.comment}</div>)}
                </div>
            );
        });

        const quizTitle = quiz.title[lang] ? quiz.title[lang] : quiz.title[0];
        const title = intl.formatMessage({ id: 'app.upload.questions' }) + ' "' + quizTitle + '"';

        return (
            <Page>
                <PageCard title={title} onClick={this.handleAction} back={Config.action.BACK}>
                    <div className="card">
                        <div className="card-body">
                            <div style={styleSpecification}>
                                <PageSubTitle title={intl.formatMessage({ id: 'app.question.upload.how.to.import' })} />
                                <div style={styleSpecificationItem}>{intl.formatMessage({ id: 'app.question.upload.spec01' })}</div>
                                <PageSubTitle title={intl.formatMessage({ id: 'app.question.upload.columns' })} />
                                <div style={styleSpecificationItem}>{intl.formatMessage({ id: 'app.question.upload.spec02' })}</div>
                                <div style={styleSpecificationItem}>{intl.formatMessage({ id: 'app.question.upload.spec03' })}</div>
                                <PageSubTitle title={intl.formatMessage({ id: 'app.question.upload.comments' })} />
                                <div style={styleSpecificationItem}>{intl.formatMessage({ id: 'app.question.upload.spec04' })}</div>
                                <div style={styleSpecificationItem}>{intl.formatMessage({ id: 'app.question.upload.spec05' }) + ' ' + Config.locales.VALUES + '.'}</div>
                                <div style={styleSpecificationItem}>{intl.formatMessage({ id: 'app.question.upload.spec06' })}</div>
                                <div style={styleSpecificationItem}>{intl.formatMessage({ id: 'app.upload.questions.limit' })} {this.props.store.company.uploadQuestionLimit}</div>
                                <PageSubTitle title={intl.formatMessage({ id: 'app.question.upload.sample' })} />
                                <div style={styleSpecificationSample}>
                                    <div>"fr";"Qu'elle est la réponse à la grande qustion sur la vie l'univers et le reste ?";"42";"Aucune";"13";"7";"Dans
                                        l'œuvre de science-fiction de Douglas Adams ""Le Guide du voyageur galactique"", la question ultime sur le sens de la vie est posée. Une réponse est proposée : le nombre 42,
                                        mais le problème est que personne n'a jamais su la question précise. Dans l'histoire, la réponse est cherchée par le super-ordinateur Pensées Profondes";"en";"What
                                        is the ultimate answer to life, the universe, and everything ?";"42","Any";"13";"7";"""The Hitchhiker's Guide to the Galaxy""" is a comic science fiction series created by
                                        Douglas Adams. In the radio series and the first novel, a group of hyper-intelligent pan-dimensional beings demand to learn the ""Answer to the Ultimate Question of Life,
                                        The Universe, and Everything""" from the supercomputer Deep Thought, specially built for this purpose. It takes Deep Thought 7½ million years to compute and check the answer,
                                        which turns out to be 42. Deep Thought points out that the answer seems meaningless because the beings who instructed it never actually knew what the Question was."</div>
                                    <div>"fr";"Le 4 mai est la journée ?";"Des 3 autres réponses";"Star Wars";"Mondiale du jardinage nu";"Internationale des pompiers";"Source https://www.journee-mondiale.com/les-journees-mondiales.htm [2019]";"en";"What
                                        is the ultimate answer to life, the universe, and everything ?";"The other 3 responses","Star Wars";"World day of naked gardening";"International firefighters
                                        day";"Source https://www.journee-mondiale.com/les-journees-mondiales.htm [2019]"</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={styleHeader}>
                        <InputFile name="file" label="app.file.choose" onChange={this.handleChangeFile} accept=".csv" />
                        <ButtonRounded label="app.file.upload" onClick={this.handleSubmit}
                            disabled={this.state.submitDisable} kind="primary" />
                    </div>
                    {this.state.errors.length > 0 && (
                        <div style={styleErrors}>
                            {dynamicErrors}
                        </div>)}
                </PageCard>
            </Page>
        );
    }
}

ConnectedQuizUploadPage = Radium(ConnectedQuizUploadPage);

const mapStateToProps = state => {
    return {
        store: {
            app: state.app,
            company: state.company,
            user: state.user
        }
    };
};

const mapDispatchToProps = {
    setPage,
    showError, showSuccess, showWarning
};

const QuizUploadPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuizUploadPage);

QuizUploadPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizUploadPage);
