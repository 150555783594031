export const QUESTION_CREATE_INITIALIZE_QUIZ = '[Question create] Initialize quiz';
export const QUESTION_CREATE_SET_QUIZ = '[Question create] Set quiz';

export const initialState = {
    quiz: ''
}

export const initializeQuiz = () => {
    return { type: QUESTION_CREATE_INITIALIZE_QUIZ, quiz: initialState.quiz }
}

export const setQuiz = quiz => {
    return { type: QUESTION_CREATE_SET_QUIZ, quiz: quiz }
}
