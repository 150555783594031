import {initialState, ADD_PLAYER, INITIALIZE_PLAYER} from '../actions/player.action';

const player = (state = initialState, action) => {
    switch (action.type) {
        case ADD_PLAYER:
            return action.player
        case INITIALIZE_PLAYER:
            return initialState
        default:
            return state
    }
}

export default player;