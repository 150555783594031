import {
    initialState,
    DEPARTMENT_CREATE_ADD_SELECTED_USER,
    DEPARTMENT_CREATE_INITIALIZE,
    DEPARTMENT_CREATE_SET_FILTER_NAME,
    DEPARTMENT_CREATE_SET_FILTRED_USERS,
    DEPARTMENT_CREATE_SET_SELECTED_USERS,
    DEPARTMENT_CREATE_SET_NAME,
    DEPARTMENT_CREATE_SET_REFERENCE
} from '../actions/department-create.action';

const departmentCreate = (state = initialState, action) => {
    switch (action.type) {
        case DEPARTMENT_CREATE_ADD_SELECTED_USER:
            return { ...state, selectedUsers: [ ...state.selectedUsers, action.selectedUser ] };
        case DEPARTMENT_CREATE_INITIALIZE:
            return initialState;
        case DEPARTMENT_CREATE_SET_FILTER_NAME:
            return { ...state, filterName: action.filterName };
        case DEPARTMENT_CREATE_SET_FILTRED_USERS:
            return { ...state, filtredUsers: action.filtredUsers };
        case DEPARTMENT_CREATE_SET_SELECTED_USERS:
            return { ...state, selectedUsers: action.selectedUsers };
        case DEPARTMENT_CREATE_SET_NAME:
            return { ...state, name: action.name };
        case DEPARTMENT_CREATE_SET_REFERENCE:
            return { ...state, name: action.reference };
        default:
            return state
    }
}

export default departmentCreate;