import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';
import Config from '../../config';

class ConnectedQuiz extends Component {

    render() {
        const quiz = this.props.quiz;
        const { store } = this.props;

        let styleCommon = { ...store.style.common };

        let styleContainer = {
            base: {
                background: 'transparent',
                border: `1px solid  ${store.style.color.shadow.base}`,
                borderRadius: '3px',
                color: '#000000',
                fontSize: '.8rem',
                margin: '0 1rem 1rem 0',
                padding: '0',
                width: '16rem',
                height: '10.3rem'
            }
        };

        const styleLocale = {
            marginRight: '.5rem'
        };

        const styleLocales = {
            paddingRight:'.3rem',
            paddingLeft:'.3rem'
        };

        let styleQuiz = {
            base: {
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '9rem',
                zIndex: '20',
                ':hover': {
                    cursor: 'pointer'
                }
            }
        };

        let styleQuizText = {
            base: {
                fontWeight: 'bold',
                textAlign: 'center',
                zIndex: '30'
            },
            title: {
                fontSize: '1.8em'
            }
        };

        let styleQuizImage = {
            base: {
                backgroundImage: quiz.picture ? `url('${process.env.REACT_APP_IMAGES_DIRECTORY + quiz.picture}')` : '',
                backgroundSize: 'cover',
                borderRadius: '2px 2px 0 0',
                height: '9rem',
                opacity: '.5',
                position: 'absolute',
                width: '16rem',
                zIndex: '10',
                ':hover': {
                    cursor: 'pointer'
                }
            }
        };

        const locales = this.props.quiz.locales.split(',');
        const dynamicLocales = locales.map((item, index) => {
            return <i className={`flag-icon flag-icon-${Config.locales.ICON[item]}`} style={styleLocale} title={item} id={item} key={item}></i>
        });

        return (
            <div style={[styleContainer.base, styleCommon, styleContainer[this.props.kind]]} >
                <div key="image" style={[styleQuizImage.base]} onClick={(e) => this.props.onClick(Config.action.QUIZ, { id: this.props.quiz.id })}></div>
                <div key="title" style={[styleQuiz.base]} onClick={(e) => this.props.onClick(this.props.action, { id: this.props.quiz.id })}>
                    <div style={[styleQuizText.base, styleQuizText.title]}>{quiz.title}</div>
                    <div style={[styleQuizText.base]}>{quiz.questionsNumber} <i className="fa fa-question-circle"></i></div>
                </div>
                <div style={styleLocales}>{dynamicLocales}</div>
            </div >
        );
    }
}

ConnectedQuiz = Radium(ConnectedQuiz);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const Quiz = connect(mapStateToProps, null)(ConnectedQuiz);

Quiz.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Quiz);
