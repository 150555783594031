import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';

import { ButtonRoundedIcon } from '../button-rounded-icon.component';

class ConnectedPageTitle extends Component {

    render() {
        return (
            <h4 className="opp-page-title">
                {this.props.title}
                {this.props.action && (<ButtonRoundedIcon icon="fa fa-plus" onClick={() => this.props.onClick(this.props.action)} styles={this.props.store.style} kind="primary" />)}
                {this.props.back && (<button type="button" className="btn btn-rounded btn-icon opp-btn-back float-right" onClick={() => this.props.onClick(this.props.back)} ><i className="fa fa-times menu-icon"></i></button>)}
            </h4>
        )
    }
}

ConnectedPageTitle = Radium(ConnectedPageTitle);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const PageTitle = connect(mapStateToProps, null)(ConnectedPageTitle);

export default PageTitle;