import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';
import { Checkbox } from 'primereact/checkbox';

class ConnectedInputCheckbox extends Component {
    render() {

        let styleCommon = { ...this.props.store.style.common };

        let styleContainer = {
            base: {
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                padding: '0 .5em'
            }
        }

        let styleLabel = {
            base: {
                marginRight: '.5em',
                marginLeft: '.5em'
            }
        }

        let styleComponent = {
            base: {
                marginRight: '.5em'
            }
        }

        return (
            <div style={[styleContainer.base]}>
                <label style={[styleLabel.base, styleCommon]}>{this.props.intl.formatMessage({ id: this.props.label })}</label>

                <Checkbox style={styleComponent.base}
                    inputId={this.props.name} value={this.props.checked} onChange={this.props.onChange.bind(this)} 
                    checked={this.props.checked} />
            </div>
        )
    }
}

ConnectedInputCheckbox = Radium(ConnectedInputCheckbox);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const InputCheckbox = connect(mapStateToProps, null)(ConnectedInputCheckbox);

InputCheckbox.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(InputCheckbox);