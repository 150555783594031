import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { PageCard } from '../shared/components/page/page-card.component';
import { Panel } from 'primereact/panel';
import QuizPublicDetailsPage from './quiz-public-details-page.component';
import QuizStatisticsPage from './quiz-statistics-page.component';
import QuestionPublic from './components/question-public.component';
import { QuestionExtend } from '../question/components/question-extend.component';
import { setPage } from '../store/actions/app.action';
import Config from '../config';

class ConnectedQuizPublicPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            displayExtendButton: true,
            displayNumber: Config.question.EXTEND,
            lastPage: '',
            questions: [],
            quiz: {
                id: '',
                title: '',
                description: '',
                picture: '',
                company: {
                    id: '',
                    name: '',
                    description: '',
                    logo: '',
                    website: ''
                }
            },
            themes: []
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleExtend = this.handleExtend.bind(this);
    }

    async componentDidMount() {
        this.props.setPage(Config.page.INIT);

        const { id } = this.props.match.params;
        this.setState({ lastPage: this.props.store.app.lastPage });
        let questions = [];
        let quiz = [];

        // Get quiz entity
        const response = await http.get(`/manager/quizzes/${id}/public`);
        if (response.ok) {
            quiz = response.data.quiz;
        }

        // Get questions
        const responseQuestions = await http.get(`/manager/quizzes/${id}/questions`);
        if (responseQuestions.ok) {
            questions = responseQuestions.data.quiz.questions;
        }

        this.setState({ quiz: quiz, questions: questions });
    }

    /**
      * Action to chaining to next page
      * 
      * @param {*} action 
      */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.state.lastPage);
                break;
            default:
        }
    }

    /**
     * Extend question list
     * 
     * @param {*} event 
     */
    handleExtend(event) {
        let displayExtendButton = this.state.displayExtendButton;
        let displayNumber = this.state.displayNumber + 25;
        if (displayNumber > this.state.questions.length) {
            displayExtendButton = false;
            displayNumber = this.state.questions.length;
        }
        this.setState({ displayExtendButton: displayExtendButton, displayNumber: displayNumber });
    }

    render() {
        const { intl } = this.props;
        const quiz = this.state.quiz;

        const dynamicQuestions = this.state.questions.map((question, index) => {
            return <QuestionPublic key={index} question={question} kind="question" />
        });

        return (
            <PageCard title={quiz.title} onClick={this.handleAction} back={Config.action.BACK}>
                <Panel header={intl.formatMessage({ id: 'app.quiz.informations' })} style={{ marginTop: '2em' }} toggleable={true}>
                    <QuizPublicDetailsPage handleAction={this.handleAction} params={this.props.match.params} />
                </Panel>

                <Panel id='statistics' header={intl.formatMessage({ id: 'app.quiz.statistics' })} style={{ marginTop: '2em' }}
                    toggleable={true}>
                    <QuizStatisticsPage params={this.props.match.params} />
                </Panel>

                <Panel header={this.props.intl.formatMessage({ id: 'app.quiz.questions' })} style={{ marginTop: '2em' }} toggleable={true}>
                    {dynamicQuestions.slice(0, this.state.displayNumber + 1)}
                    {this.state.displayExtendButton && this.state.questions.length > Config.question.EXTEND && (<QuestionExtend styles={this.props.store.style} onClick={this.handleExtend} />)}
                </Panel>

            </PageCard >
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            app: state.app
        }
    };
};

const mapDispatchToProps = {
    setPage
};

const QuizPublicPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuizPublicPage);

QuizPublicPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizPublicPage);
