import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';

class InputSelect extends Component {

    render() {
        const { intl } = this.props;

        const dynamicThemesOptions = (options) => {
            const htmlOptions = options.map((val, i) => {
                return <option key={i} value={val.value}>{val.label}</option>
            });

            return htmlOptions;
        };

        const styleContainer = {
            marginRight: '1em',
            marginBottom: '.5em'
        };

        const styleSelect = {
            fontFamily: 'PTSans, sans-serif',
            fontSize: '.8em',
            height: '2.5em',
            minWidth: '10em',
            padding: '.5em 0 .5em 1em'
        };

        return (
            <div className="form-group" style={styleContainer}>
                {this.props.label && (<div>
                    <label>{intl.formatMessage({ id: this.props.label })}</label>
                </div>)}
                <div>
                    <select className="form-control" name={this.props.name} value={this.props.value} placeholder={this.props.placeholder}
                        style={styleSelect} disabled={this.props.disabled}
                        onChange={this.props.onChange.bind(this)}>
                        {this.props.placeholder && (<option key="placeholder" value="" disabled selected>{this.props.placeholder}</option>)}
                        {this.props.all && !this.props.placeholder && (<option key="all" value="all" selected>{intl.formatMessage({ id: 'app.all' })}</option>)}
                        {this.props.all && this.props.placeholder && (<option key="all" value="all">{intl.formatMessage({ id: 'app.all' })}</option>)}
                        {this.props.none && (<option key="none" value="none">{intl.formatMessage({ id: 'app.none' })}</option>)}
                        {dynamicThemesOptions(this.props.options)}
                    </select>
                </div>
            </div>
        )
    }
}

InputSelect.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(InputSelect);