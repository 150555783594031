import React from 'react';
import { IntlMessage } from '../intl-message.component';

const containerClass = "form-group";
const containerStyle = {};

const buttonClass = "btn btn-primary mr-2";
const buttonStyle = {};

export const SubmitButton = (props) => (
    <div className={props.containerClass ? props.containerClass : containerClass}
        style={props.containerStyle ? props.containerStyle : containerStyle}>
            
        <button type="submit" className={props.buttonClass ? props.buttonClass : buttonClass}
            style={props.buttonStyle ? props.buttonStyle : buttonStyle}
            disabled={props.disabled ? props.disabled : false}>
            <IntlMessage id={props.label ? props.label : "app.save"} />
        </button>
    </div>
)