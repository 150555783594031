import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { PageCard } from '../shared/components/page/page-card.component';
import { Panel } from 'primereact/panel';
import QuizStatisticsPage from './quiz-statistics-page.component';
import QuizDetailsPage from './quiz-details-page.component';
import QuizModifyPage from './quiz-modify-page.component';
import QuizQuestionsPage from './quiz-questions-page.component';
import { setPage } from '../store/actions/app.action';
import { setDetailsCollapsed, setModifyCollapsed, setStatisticsCollapsed } from '../store/actions/quiz-manager.action';
import Config from '../config';

class ConnectedQuizManagerPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            detailsCollapsed: true,
            modifyCollapsed: true,
            statisticsCollapsed: false,
            quiz: {
                id: '',
                title: [],
                description: [],
                picture: '',
                published: false,
                public: false,
                themes: []
            }
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleCollapseDetails = this.handleCollapseDetails.bind(this);
        this.handleCollapseModify = this.handleCollapseModify.bind(this);
        this.handleCollapseStatistics = this.handleCollapseStatistics.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        const { app, quizManager } = this.props.store;

        this.props.setPage(`/quizzes/${id}/manager`);

        let quiz = [];
        let quizThemes = [];

        let detailsCollapsed = quizManager.detailsCollapsed;
        let modifyCollapsed = quizManager.modifyCollapsed;
        let statisticsCollapsed = quizManager.statisticsCollapsed;

        if (app.page === Config.page.quiz.CREATE) {
            detailsCollapsed = true;
            modifyCollapsed = false;
            statisticsCollapsed = true;

            this.props.setDetailsCollapsed(detailsCollapsed);
            this.props.setModifyCollapsed(modifyCollapsed);
            this.props.setStatisticsCollapsed(statisticsCollapsed);
        }

        // Get quiz entity
        const response = await http.get(`/manager/quizzes/${id}`);
        if (response.ok) {
            quiz = response.data.quiz;
            const themes = response.data.themes;
            quizThemes = themes.map(item => {
                return item.id;
            });
        }

        this.setState({
            detailsCollapsed: detailsCollapsed,
            modifyCollapsed: modifyCollapsed,
            statisticsCollapsed: statisticsCollapsed,
            quiz: { ...quiz, themes: quizThemes },
            picture: quiz.picture ? process.env.REACT_APP_IMAGES_DIRECTORY + quiz.picture : null
        });
    }

    /**
      * Action to chaining to next page
      * 
      * @param {*} action 
      */
    handleAction(action) {
        switch (action) {
            case Config.action.ADD:
                history.push(`/questions/new`);
                break;
            case Config.action.BACK:
                history.push(this.props.store.app.lastPage);
                break;
            case Config.action.DELETE:
                this.setState({ dialogue: true });
                break;
            default:
        }
    }

    handleCollapseDetails = () => {
        this.props.setDetailsCollapsed(!this.state.detailsCollapsed);
        this.setState({ detailsCollapsed: !this.state.detailsCollapsed });
    }

    handleCollapseModify = () => {
        this.props.setModifyCollapsed(!this.state.modifyCollapsed);
        this.setState({ modifyCollapsed: !this.state.modifyCollapsed });
    }

    handleCollapseStatistics = () => {
        this.props.setStatisticsCollapsed(!this.state.statisticsCollapsed);
        this.setState({ statisticsCollapsed: !this.state.statisticsCollapsed });
    }

    render() {
        const { intl } = this.props;
        const { quiz } = this.state;
        const lang = this.props.store.user.locale;
        const pickModifyPanelHeight = `80em`;

        return (
            <PageCard title={lang in quiz.title ? quiz.title[lang] : quiz.title[0]}
                onClick={this.handleAction} back={Config.action.BACK}>

                <Panel id='details' header={intl.formatMessage({ id: 'app.quiz.informations' })} style={{ marginTop: '2em' }}
                    toggleable={true} collapsed={this.state.detailsCollapsed} onToggle={this.handleCollapseDetails}>
                    <QuizDetailsPage params={this.props.match.params} handleAction={this.handleAction} />
                </Panel>

                <Panel id='statistics' header={intl.formatMessage({ id: 'app.quiz.statistics' })} style={{ marginTop: '2em' }}
                    toggleable={true} collapsed={this.state.statisticsCollapsed} onToggle={this.handleCollapseStatistics}>
                    <QuizStatisticsPage params={this.props.match.params} handleAction={this.handleAction} />
                    <QuizQuestionsPage params={this.props.match.params} handleAction={this.handleAction} />
                </Panel>

                <Panel id='modify' header={intl.formatMessage({ id: 'app.quiz.modify' })} style={{ marginTop: '2em', height: pickModifyPanelHeight }}
                    toggleable={true} collapsed={this.state.modifyCollapsed} onToggle={this.handleCollapseModify}>
                    <QuizModifyPage params={this.props.match.params} handleAction={this.handleAction} />
                </Panel>

            </PageCard >
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            app: state.app,
            quizManager: state.quizManager,
            user: state.user
        }
    };
};

const mapDispatchToProps = {
    setPage,
    setDetailsCollapsed, setModifyCollapsed, setStatisticsCollapsed
};

const QuizManagerPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuizManagerPage);

QuizManagerPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizManagerPage);
