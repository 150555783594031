import React, { Component } from 'react';
import PageTitle from './page-title.component';

export class PageCard extends Component {
    render() {
        return (
            <div className="card">
                <div className="card-body">
                    {this.props.title && (
                        <PageTitle title={this.props.title} action={this.props.action} back={this.props.back} onClick={this.props.onClick} />
                    )}
                    <div style={this.props.styles ? this.props.styles : []}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}