export const USER_ADD_USER = '[User] Add user';
export const USER_INITIALIZE_USER = '[User] Initialize user';
export const USER_UPDATE_USER = '[User] update user';

export const initialState = {
     id: null,
     username: '',
     firstname: '',
     lastname: '',
     email: '',
     enabled: null,
     roles: [],
     locale: '',
     companyAdmin: null
}

export const addUser = user => {
    return {type: USER_ADD_USER, user: user}
}

export const initializeUser = () => {
    return {type: USER_INITIALIZE_USER, user: initialState}
}

export const updateUser = user => {
    return {type: USER_UPDATE_USER, user: user}
}