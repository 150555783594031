import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import Dialog from '../shared/components/dialog.component';
import { Page } from '../shared/components/page/page.component';
import PageTitle from '../shared/components/page/page-title.component';
import ShelfGroup from './components/shelf-group.component';
import ShelfNoGroup from "./components/shelf-no-group.component";
import { setLastPage } from '../store/actions/app.action';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';

class ConnectedGroupsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            company: [],
            groups: [],
            dialog: false,
            superadmin: false,
            toDeleteGroupManager: [],
            toDeleteGroup: [],
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleGroupDeleteManagerOnClick = this.handleGroupDeleteManagerOnClick.bind(this);
        this.handleGroupDeleteManagerOnHide = this.handleGroupDeleteManagerOnHide.bind(this);
        this.handleGroupDeleteManagerOnSubmit = this.handleGroupDeleteManagerOnSubmit.bind(this);
    }

    async componentDidMount() {
        let companyId = '';
        let company = [];
        let groups = [];

        const { id } = this.props.match.params;
        if (id) {
            companyId = id;
            const response = await http.get(`/manager/companies/${companyId}`);
            if (response.ok) {
                company = response.data.company;
            }
        } else {
            companyId = this.props.store.company.id;
            company = this.props.store.company;
        }

        // Get company groups
        const responseGroups = await http.get(`/manager/groups?company=${companyId}`);
        if (!responseGroups.ok) {
            this.props.showError(responseGroups.error);
        } else {
            groups = responseGroups.data.groups;
        }

        this.setState({
            companyId: companyId,
            company: company,
            groups: groups
        });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     * @param {*} data 
     */
    handleAction(action, data) {
        switch (action) {
            case Config.action.ADD:
                history.push(`/groups/new`);
                break;
            case Config.action.MODIFY:
                this.props.setLastPage(`/groups`);
                history.push(`/groups/modify/${data.id}`);
                break;
            case Config.action.RANKING:
                this.props.setLastPage(`/groups`);
                history.push(`/groups/ranking/${data.id}`);
                break;
            default:
        }
    }

    /**
     * Remove manager group
     * 
     * @param {*} user 
     * @param {*} department 
     */
    handleGroupDeleteManagerOnClick(user, group) {
        this.setState({ toDeleteGroupManager: user, toDeleteGroup: group });
        this.setState({ dialog: true });
    }

    /**
     * Hide remove manager modal
     */
    handleGroupDeleteManagerOnHide() {
        this.setState({ dialog: false, toDeleteGroupManager: [], toDeleteGroup: [] });
    }

    /**
     * Submit remove manager
     */
    async handleGroupDeleteManagerOnSubmit() {
        const { toDeleteGroupManager, toDeleteGroup } = this.state;
        const { company } = this.store;
        let groups = [];

        // Delete group admin
        const response = await http.del(`/manager/groups/${toDeleteGroup.id}/admins/${toDeleteGroupManager.id}`);
        if (!response.ok) {
            this.props.showError(response.error);
        }

        // Update groups
        const responseGroups = await http.get(`/manager/companies/${company.id}/groups`);
        if (!responseGroups.ok) {
            this.props.showError(responseGroups.error);
        } else {
            groups = responseGroups.data.groups;
        }

        this.setState({ groups, dialog: false, toDeleteGroupManager: [], toDeleteGroup: [] });
    }

    render() {
        const { intl: { formatMessage }, store: { player } } = this.props;
        const { dialog, groups, toDeleteGroupManager } = this.state;

        const dynamicGroups = this.state.groups.map((val, i) => {
            return <ShelfGroup key={i} group={val} admin={player.admin} onClick={this.handleAction} />
        });

        return (
            <Page>
                <PageTitle title={formatMessage({ id: 'app.groups' })}
                    action={Config.action.ADD} onClick={this.handleAction} />
                {groups.length === 0 && (
                    <ShelfNoGroup onClick={this.handleAction} />
                )}
                {groups.length > 0 && (<div>{dynamicGroups}</div>)}

                <Dialog visible={dialog}
                    text={formatMessage(
                        { id: 'app.dialog.delete.department.admin' },
                        {
                            firstname: toDeleteGroupManager.firstname,
                            lastname: toDeleteGroupManager.lastname
                        }
                    )}
                    onHide={this.handleGroupDeleteManagerOnHide}
                    onSubmit={this.handleGroupDeleteManagerOnSubmit}
                />
            </Page >
        );
    }
}

const mapStateToProps = ({ company, player, style, user }) => {
    return { store: { company, player, style, user } };
};

const mapDispatchToProps = {
    setLastPage,
    showError, showSuccess
};

const GroupsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupsPage);

GroupsPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(GroupsPage);
