import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';

import { Link } from 'react-router-dom';
import { IntlMessage } from './intl-message.component';

export class ConnectedSideMenuItem extends Component {
    render() {
        const style = this.props.store.style.sideMenuItem;
        let styleItem = { 
            base: {
                marginBottom: '0'
            }
        };

        if (this.props.separation) {
            styleItem.base= { ...styleItem.base, borderBottom: '1px solid #c3c3c3' };
        }

        return (
            <li className="nav-item" style={[styleItem.base, style.container[this.props.kind]]} onClick={() => this.props.onClick(this.props.name)}>
                <Link className="nav-link" to={this.props.to}>
                    {this.props.icon && (<i className={this.props.icon} style= {{width: 20}}></i>)}
                    {!this.props.label && (<IntlMessage id={this.props.id} className="menu-title" />)}
                    {this.props.label && (<span className="menu-title">{this.props.label}</span>)}
                </Link>
            </li>
        )
    }
}

ConnectedSideMenuItem = Radium(ConnectedSideMenuItem);

const mapStateToProps = state => {
    return { 
        store: {
            style: state.style
        }
    };
};

const SideMenuItem = connect(mapStateToProps, null)(ConnectedSideMenuItem);

export default SideMenuItem;