import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';

import SideMenuItem from './sidemenu-item.component';
import { sideMenu, setSideMenu } from '../../store/actions/app.action';
import Config from '../../config';

export class ConnectedSideMenu extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    /**
     * Change activated item
     * 
     * @param {*} item 
     */
    handleClick(item) {
        this.props.setSideMenu(item);
    }

    render() {
        const { app, company, player, user } = this.props.store;

        const styleContainer = {
            base: {
                position: 'fixed'
            }
        }

        const groupModule = player.admin && company.groupModule;

        return (
            <div className="sidebar sidebar-offcanvas" id="sidebar" style={[styleContainer.base]}>
                <ul className="nav">
                    {/*<SideMenuItem id="app.dashboard" to="/" icon="fa fa-chart-line menu-icon" />*/}
                    <SideMenuItem id="app.themes" to="/themes" icon="fa fa-flag menu-icon"
                        name={sideMenu.THEME} kind={app.sideMenu === sideMenu.THEME ? 'active' : ''} onClick={this.handleClick} />
                    <SideMenuItem id="app.quizzes" to="/quizzes" icon="fa fa-brain menu-icon"
                        name={sideMenu.QUIZ} kind={app.sideMenu === sideMenu.QUIZ ? 'active' : ''} onClick={this.handleClick} />
                    <SideMenuItem id="app.questions" to="/questions" icon="fa fa-question-circle menu-icon"
                        name={sideMenu.QUESTION} kind={app.sideMenu === sideMenu.QUESTION ? 'active' : ''} onClick={this.handleClick} separation={true} />
                    {player.admin && (
                        <SideMenuItem id="app.company" to={'/companies/modify/' + company.id} icon="fa fa-building menu-icon"
                            name={sideMenu.COMPANY} kind={app.sideMenu === sideMenu.COMPANY ? 'active' : ''} onClick={this.handleClick} label={company.name} />
                    )}
                    <SideMenuItem id="app.departments" to="/departments" icon="fa fa-shield-alt menu-icon"
                        name={sideMenu.DEPARTMENT} kind={app.sideMenu === sideMenu.DEPARTMENT ? 'active' : ''} onClick={this.handleClick} />
                    <SideMenuItem id="app.users" to="/users" icon="fa fa-users menu-icon"
                        name={sideMenu.USER} kind={app.sideMenu === sideMenu.USER ? 'active' : ''} onClick={this.handleClick} separation={!groupModule} />
                    {groupModule && (
                        <SideMenuItem id="app.groups" to={'/groups'} icon="fa fa-calendar-alt menu-icon"
                            name={sideMenu.GROUP} kind={app.sideMenu === sideMenu.GROUP ? 'active' : ''} onClick={this.handleClick}
                            separation={groupModule} />
                    )}
                    {Config.app.role.SUPER_ADMIN === user.role && (
                        <SideMenuItem id="app.companies" to="/companies" icon="fa fa-industry menu-icon"
                            name={sideMenu.COMPANIES} kind={app.sideMenu === sideMenu.COMPANIES ? 'active' : ''} onClick={this.handleClick} separation={true} />
                    )}
                </ul>
            </div>
        );
    }
}

ConnectedSideMenu = Radium(ConnectedSideMenu);

const mapStateToProps = ({ app, company, player, user }) => {
    return { store: { app, company, player, user } };
};

const mapDispatchToProps = {
    setSideMenu
};

const SideMenu = connect(mapStateToProps, mapDispatchToProps)(ConnectedSideMenu);

export default SideMenu;