import React, { Component } from "react";
import CircularProgressbar from 'react-circular-progressbar';
import Radium from 'radium';

class CircularRate extends Component {

    render() {

        const styleProgressBarContainer = {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center'
        };

        const styleTitle = {
            marginBottom: '1em'
        }

        return (
            <div style={styleProgressBarContainer}>
                <h5 style={styleTitle}>{this.props.name}</h5>
                <CircularProgressbar
                    percentage={this.props.rate}
                    text={ this.props.text ? this.props.text
                        : `${this.props.rate.toFixed(0)}%`}
                    styles={this.props.rate >= 50 ? this.props.styles.CircularProgressBar.secondary : this.props.styles.CircularProgressBar.dangerSecondary}
                />
            </div>
        )
    }
}

export default Radium(CircularRate);