import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';

class ConnectedButton extends Component {

    render() {
        const style = this.props.store.style;

        let styleComponent = {
            base: {
                background: style.color.initial.base,
                border: `1px solid ${style.color.initial.base}`,
                color: style.color.initial.opposite,
                marginTop: '.5em',
                marginBottom: '.5em'
            }
        }

        return (
            <button type="button" className="btn btn-rounded"
                style={[styleComponent.base, style.button[this.props.kind]]}
                onClick={this.props.onClick.bind(this)} disabled={this.props.disabled ? this.props.disabled : false}>
                {this.props.intl.formatMessage({ id: this.props.label ? this.props.label : 'app.save' })}
            </button>);
    }
}

ConnectedButton = Radium(ConnectedButton);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const Button = connect(mapStateToProps, null)(ConnectedButton);

Button.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Button);