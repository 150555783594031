import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { Router, Route } from "react-router-dom";
import { historyHelper as history } from './core/helpers/history.helper';
import { PrivateRoute } from "./core/components/private-route.component";
import { injectIntl, intlShape } from 'react-intl';

import { resetMessage } from './store/actions/message.action';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Growl } from 'primereact/growl';
import 'react-circular-progressbar/dist/styles.css';

import './custom-template.css';
import './custom-opp.css';

import LoginPage from "./core/login-page.component";
import EmailValidationPage from "./core/email-validation-page.component";

//import DashboardPage from "./quiz/dashboard-page.component";
import CompaniesPage from "./company/companies-page.component";
import CompanyCreatePage from "./company/company-create-page.component";
import CompanyModifyPage from "./company/company-modify-page.component";

import DepartmentsPage from "./department/departments-page.component";
import DepartmentCreatePage from "./department/department-create-page.component";
import DepartmentModifyPage from "./department/department-modify-page.component";
import DepartmentRankingPage from "./department/department-ranking-page.component";

import GroupsPage from "./group/groups-page.component";
import GroupCreatePage from "./group/group-create-page.component";
import GroupModifyPage from "./group/group-modify-page.component";
import GroupRankingPage from "./group/group-ranking-page.component";

import QuestionsPage from "./question/questions-page.component";
import QuestionCreatePage from "./question/question-create-page.component";
import QuestionDeletePage from "./question/question-delete-page.component";
import QuestionModifyPage from "./question/question-modify-page.component";

import QuizzesPage from "./quiz/quizzes-page.component";
import QuizCreatePage from "./quiz/quiz-create-page.component";
import QuizDeletePage from "./quiz/quiz-delete-page.component";
import QuizManagerPage from "./quiz/quiz-manager-page.component";
import QuizPublicPage from "./quiz/quiz-public-page.component";
import QuizUploadPage from "./quiz/quiz-upload-page.component";

import RecoveryPage from "./core/recovery-page.component";

import ThemesPage from "./theme/themes-page.component";
import ThemeCreatePage from "./theme/theme-create-page.component";
import ThemeDeletePage from "./theme/theme-delete-page.component";
import ThemeModifyPage from "./theme/theme-modify-page.component";

import UsersPage from "./user/users-page.component";
import UserAccountPage from "./user/user-account-page.component";
import UserCreatePage from "./user/user-create-page.component";
import UserModifyPage from "./user/user-modify-page.component";
import UserNotificationPage from "./user/user-notification-page.component";
import UserUploadPage from "./user/user-upload-page.component";
import themeRankingPage from './theme/theme-ranking-page.component';

const routes = [

    { path: "/login", component: LoginPage },
    //{ path: "/", component: DashboardPage, private: true, exact: true },
    { path: "/", component: ThemesPage, private: true, exact: true },
    { path: "/companies", component: CompaniesPage, private: true, exact: true },
    { path: "/companies/new", component: CompanyCreatePage, private: true },
    { path: "/companies/modify/:id", component: CompanyModifyPage, private: true },
    { path: "/companies/:id/departments", component: DepartmentsPage, private: true, exact: true },
    { path: "/companies/:id/questions", component: QuestionsPage, private: true, exact: true },
    { path: "/companies/:id/quizzes", component: QuizzesPage, private: true, exact: true },
    { path: "/companies/:id/themes", component: ThemesPage, private: true, exact: true },
    { path: "/companies/:id/users", component: UsersPage, private: true, exact: true },
    { path: "/departments", component: DepartmentsPage, private: true, exact: true },
    { path: "/departments/new", component: DepartmentCreatePage, private: true },
    { path: "/departments/modify/:id", component: DepartmentModifyPage, private: true },
    { path: "/departments/ranking/:id", component: DepartmentRankingPage, private: true },
    { path: "/groups", component: GroupsPage, private: true, exact: true },
    { path: "/groups/new", component: GroupCreatePage, private: true },
    { path: "/groups/modify/:id", component: GroupModifyPage, private: true },
    { path: "/groups/ranking/:id", component: GroupRankingPage, private: true },
    { path: "/profile", component: UserAccountPage, private: true },
    { path: "/questions", component: QuestionsPage, private: true, exact: true },
    { path: "/questions/new", component: QuestionCreatePage, private: true },
    { path: "/questions/delete/:id", component: QuestionDeletePage, private: true },
    { path: "/questions/modify/:id", component: QuestionModifyPage, private: true },
    { path: "/quizzes", component: QuizzesPage, private: true, exact: true },
    { path: "/quizzes/new", component: QuizCreatePage, private: true },
    { path: "/quizzes/:id/delete", component: QuizDeletePage, private: true },
    { path: "/quizzes/:id/public", component: QuizPublicPage, private: true },
    { path: "/quizzes/:id/manager", component: QuizManagerPage, private: true },
    { path: "/quizzes/:id/upload", component: QuizUploadPage, private: true },
    { path: "/recovery", component: RecoveryPage },
    { path: "/themes", component: ThemesPage, private: true, exact: true },
    { path: "/themes/new", component: ThemeCreatePage, private: true },
    { path: "/themes/modify/:id", component: ThemeModifyPage, private: true },
    { path: "/themes/delete/:id", component: ThemeDeletePage, private: true },
    { path: "/themes/ranking/:id", component: themeRankingPage, private: true },
    { path: "/users", component: UsersPage, private: true, exact: true },
    { path: "/users/check/:token", component: EmailValidationPage },
    { path: "/users/new", component: UserCreatePage, private: true },
    { path: "/users/modify/:id", component: UserModifyPage, private: true },
    { path: "/users/notification", component: UserNotificationPage, private: true },
    { path: "/users/upload", component: UserUploadPage, private: true },
];

class ConnectedApp extends Component {

    constructor(props) {
        super(props);
        let path = window.location.href;
        path = path.replace(window.location.origin, '');
        if (!props.user.id && 
            !path.includes('/users/check') && 
            !path.includes('/recovery')) {
            history.push('/login');
        }
    }

    async componentWillReceiveProps(nextProps) {
        if (nextProps.showMessages.messages) {
            const messages = nextProps.showMessages.messages;
            if (messages.length > 0) {
                const showMessages = messages.map(item => {
                    const summary = this.props.intl.formatMessage({ id: item.summary });
                    // Translate all details
                    let details = [];
                    if (item.detail && item.detail.length > 0) {
                        details = item.detail.map(detailItem => {
                            const detail = this.props.intl.formatMessage({ id: detailItem });
                            return <div>{detail}</div>;
                        });
                    }

                    return { ...item, summary: summary, detail: details };
                });
                this.growl.show(showMessages);
                this.props.resetMessage();
            }
        }
    }

    render() {

        const styleContainer = {
            base: {
                height: '100%'
            }
        }

        return (
            <Router history={history}>
                <div style={[styleContainer.base]}>
                    <Growl ref={(el) => { this.growl = el; }} baseZIndex={1000}></Growl>
                    {routes.map((route, index) => (
                        route.private ? <PrivateRoute key={index} {...route} /> :
                            <Route key={index} {...route} />
                    ))}
                </div>
            </Router>
        );
    }
}

ConnectedApp = Radium(ConnectedApp);

const mapStateToProps = state => {
    return {
        showMessages: state.showMessages,
        user: state.user
    }
};

const mapDispatchToProps = {
    resetMessage
};

const App = connect(mapStateToProps, mapDispatchToProps)(ConnectedApp);

App.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(App);
