import { initialState, USER_ADD_USER, USER_INITIALIZE_USER, USER_UPDATE_USER } from '../actions/user.action';

const user = (state = initialState, action) => {
    switch (action.type) {
        case USER_ADD_USER:
            return action.user;
        case USER_INITIALIZE_USER:
            return initialState;
        case USER_UPDATE_USER:
            return { ...state, ...action.user };
        default:
            return state
    }
}

export default user;