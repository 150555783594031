import React from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'primereact/calendar';
import './input-datetime2.component.css';

let InputDatetime2 = ({ label, name, onChange, value, showTime }) => {
    return (
        <div className='o-input-datetime2 o-input-datetime2-container'>
            {label && <label className='o-input-datetime2-label'>{label}</label>}
            <div className='o-input-datetime2-value'>
                <Calendar
                    name={name}
                    id={name}
                    value={value}
                    dateFormat='dd/mm/yy'
                    showTime={showTime === undefined ? true : showTime}
                    onChange={e => onChange(e)}
                />
            </div>
        </div>
    );
};

InputDatetime2.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
};

export default InputDatetime2;
