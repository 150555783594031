import React, { Component } from "react";
import { connect } from "react-redux";
import Radium from 'radium';
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { ButtonRoundedIcon } from '../shared/components/button-rounded-icon.component';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';
import CircularRate from '../shared/components/circular-rate.component';

class ConnectedDepartmentRankingPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            company: [],
            department: {},
            error: '',
            lastPage: '',
            ranking: [],
            statistics: {},
            submitted: false,
            submitDisable: true
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleExport = this.handleExport.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        let company = [];
        let superadmin = false;
        let ranking = [];
        let department;
        let statistics;

        if (this.props.store.users.company.length !== 0) {
            if (this.props.store.user.role !== Config.app.role.SUPER_ADMIN) {
                this.props.showError({ summary: 'app.error.forbidden' });
                history.push('/');
            }
            superadmin = true;
            company = this.props.store.users.company;
        } else {
            superadmin = false;
            company = this.props.store.company
        }

        const response = await http.get(`/manager/companies/${company.id}/departments/${id}/ranking`);
        if (response.ok) {
            ranking = response.data.ranking;
        }

        // Get department
        const responseDepartment = await http.get(`/manager/departments/${id}`);
        if (!responseDepartment.ok) {
            this.props.showError(responseDepartment.error);
        } else {
            department = responseDepartment.data.department;
        }

        const responseStatistics = await http.get(`/manager/departments/${id}/statistics`);
        if (responseStatistics.ok) {
            statistics = responseStatistics.data
        }

        this.setState({
            company: company,
            department: department,
            lastPage: this.props.store.app.lastPage,
            ranking: ranking,
            statistics: statistics,
            superadmin: superadmin
        });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.state.lastPage);
                break;
            default:
        }
    }

    /**
     * Set state values of message
     * 
     * @param {*} message 
     */
    handleChangeMessage(message) {
        this.setState({ message: message });
    }

    /**
     * CSV export
     */
    handleExport() {
        this.dt.exportCSV();
    }

    render() {
        const intl = this.props.intl;

        const styleHeaderContainer = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            lineHeight: '2rem'
        }

        const header = <div style={styleHeaderContainer}>
            <div style={{flexGrow: '2'}}>{intl.formatMessage({ id: 'app.players' }).toUpperCase() + ' : ' + this.state.ranking.length}</div>
            <div>
                <ButtonRoundedIcon icon="fa fa-external-link-alt" onClick={this.handleExport}
                    styles={this.props.store.style} kind="secondary" />
            </div>
        </div>;

        const styleContainerRanking = {
        };

        const styleContainerProgressBars = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            alignContent: 'stretch',
            alignItems: 'flex-end',
            margin: '0 0 3rem 0'
        };

        return (
            <Page title={this.state.superadmin ? this.state.company.name : ''}>
                <PageCard title={`${intl.formatMessage({ id: 'app.department.ranking' })} : ${this.state.department.name}`} onClick={this.handleAction} back={Config.action.BACK}>
                    <div style={styleContainerProgressBars}>
                        <CircularRate name={intl.formatMessage({ id: 'app.quiz.statistics.contribution' })} rate={this.state.statistics.participationRate ? Number(this.state.statistics.participationRate) : 0} styles={this.props.store.style} text={this.state.statistics.participationText} />
                        <CircularRate name={intl.formatMessage({ id: 'app.quiz.statistics.completion' })} rate={this.state.statistics.averageCompletion ? Number(this.state.statistics.averageCompletion) : 0} styles={this.props.store.style} />
                        <CircularRate name={intl.formatMessage({ id: 'app.quiz.statistics.correct' })} rate={this.state.statistics.averageAccuracy ? Number(this.state.statistics.averageAccuracy) : 0} styles={this.props.store.style} />
                    </div>
                    <div style={styleContainerRanking}>
                        <DataTable value={this.state.ranking} paginator={true} rows={30} scrollable={true} responsive={true} ref={(el) => { this.dt = el; }}
                            header={header} footer={this.state.ranking.length === 0 ? (<div style={{ textAlign: 'left' }}>{intl.formatMessage({ id: 'app.no.data' }).toUpperCase()}</div>) : null}>
                            <Column field="position" header="#" sortable={true} style={{ width: '3rem', textAlign: 'center', paddingLeft: '.2rem', paddingRight: '.2rem' }} />
                            <Column field="name" header={intl.formatMessage({ id: 'app.name' })} sortable={true} filter={true} filterMatchMode="contains" style={{ width: '12rem'}} />
                            <Column field="averageScore" header={intl.formatMessage({ id: 'app.average.score' })} sortable={true} style={{ width: '9rem', textAlign: 'center', paddingLeft: '.2rem', paddingRight: '.2rem' }} />
                            <Column field="winLost" header={intl.formatMessage({ id: 'app.players.win.lost' })} sortable={true} style={{ width: '5rem', textAlign: 'center', paddingLeft: '.1rem', paddingRight: '.1rem' }} />
                            <Column field="totalCoins" header={intl.formatMessage({ id: 'app.total.coins' })} sortable={true} style={{ width: '9rem', textAlign: 'center', paddingLeft: '.2rem', paddingRight: '.2rem' }} />
                            <Column field="playedNumber" header={intl.formatMessage({ id: 'app.played' })} sortable={true} style={{ width: '6rem', textAlign: 'center', paddingLeft: '.1rem', paddingRight: '.1rem' }} />
                            <Column field="level" header={intl.formatMessage({ id: 'app.level' })} sortable={true} style={{ width: '6rem', textAlign: 'center', paddingLeft: '.2rem', paddingRight: '.2rem' }} />
                            <Column field="completionRate" header={intl.formatMessage({ id: 'app.completion.rate' })} sortable={true} style={{ width: '9rem', textAlign: 'center', paddingLeft: '.1rem', paddingRight: '.1rem' }} />
                            <Column field="correctRate" header={intl.formatMessage({ id: 'app.correct.answer.rate' })} sortable={true} style={{ width: '9rem', textAlign: 'center', paddingLeft: '.1rem', paddingRight: '.1rem' }} />
                        </DataTable>
                    </div>
                </PageCard>
            </Page>
        );
    }
}

ConnectedDepartmentRankingPage = Radium(ConnectedDepartmentRankingPage);

const mapStateToProps = state => {
    return {
        store: {
            app: state.app,
            company: state.company,
            style: state.style,
            user: state.user,
            users: state.users
        }
    };
};

const mapDispatchToProps = {
    showError, showSuccess
};

const DepartmentRankingPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedDepartmentRankingPage);

DepartmentRankingPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(DepartmentRankingPage);
