import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';

import { Link } from 'react-router-dom';

class ConnectedNav extends Component {

    render() {
        const store = this.props.store;

        const styleNavBar = {
            base: {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                position: 'fixed',
                top: '0',
                right: '0',
                left: '0',
                zIndex: '1030',
                fontWeight: '400',
                transition: 'background 0.25s ease',
                padding: '0'
            }
        }

        const styleNavBarBrand = {
            base: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#3a3f51',
                transition: 'width 0.25s ease, background 0.25s ease',
                width: '255px',
                height: '70px',
                textAlign: 'center'
            }
        }

        return (
            <nav className="navbar col-lg-12 col-12 default-layout-navbar" style={[styleNavBar.base]}>
                <div className="navbar-brand-wrapper" style={[styleNavBarBrand.base]}>
                    <Link className="navbar-brand brand-logo" to="/">
                        <img src="templates/default/images/logo-br.png" alt="logo" />
                    </Link>
                    <Link className="navbar-brand brand-logo-mini" to="/">
                        <img src="templates/default/images/logo-br.png" alt="logo" />
                    </Link>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-stretch">
                    <ul className="navbar-nav">
                        <li className="nav-item nav-search d-none d-md-flex">
                            {/* <div className="nav-link">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="fas fa-search"></i>
                                        </span>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Search" aria-label="Search" />
                                </div>
                            </div> */}
                        </li>
                    </ul>
                    <ul className="navbar-nav navbar-nav-right">
                        {/*<li className="nav-item dropdown">
                            <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="/" data-toggle="dropdown">
                                <i className="fas fa-bell mx-0"></i>
                                <span className="count" style={{ background: '#e0178e' }}>16</span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                                <a className="dropdown-item" href="#">
                                    <p className="mb-0 font-weight-normal float-left">You have 16 new notifications
                        </p>
                                    <span className="badge badge-pill badge-warning float-right">View all</span>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item preview-item" href="#">
                                    <div className="preview-thumbnail">
                                        <div className="preview-icon bg-danger">
                                            <i className="fas fa-exclamation-circle mx-0"></i>
                                        </div>
                                    </div>
                                    <div className="preview-item-content">
                                        <h6 className="preview-subject font-weight-medium">Application Error</h6>
                                        <p className="font-weight-light small-text">
                                            Just now
                        </p>
                                    </div>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item preview-item" href="#">
                                    <div className="preview-thumbnail">
                                        <div className="preview-icon bg-warning">
                                            <i className="fas fa-wrench mx-0"></i>
                                        </div>
                                    </div>
                                    <div className="preview-item-content">
                                        <h6 className="preview-subject font-weight-medium">Settings</h6>
                                        <p className="font-weight-light small-text">
                                            Private message
                        </p>
                                    </div>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item preview-item" href="#">
                                    <div className="preview-thumbnail">
                                        <div className="preview-icon bg-info">
                                            <i className="far fa-envelope mx-0"></i>
                                        </div>
                                    </div>
                                    <div className="preview-item-content">
                                        <h6 className="preview-subject font-weight-medium">New user registration</h6>
                                        <p className="font-weight-light small-text">
                                            2 days ago
                        </p>
                                    </div>
                                </a>
                            </div>
        </li>*/}
                        <li className="nav-item nav-profile dropdown">
                            <Link className="nav-link dropdown-toggle" to="/" data-toggle="dropdown" id="profileDropdown" aria-expanded="false">
                                {store.user.picture && (
                                    <img src={process.env.REACT_APP_IMAGES_DIRECTORY + store.user.picture} alt="account" />)}
                                {!store.user.picture && (
                                    <img src="templates/default/images/opp.png" alt="profile" />)}
                            </Link>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                                <Link className="dropdown-item" to="/profile">
                                    <i className="fas fa-user text-primary"></i>
                                    {this.props.intl.formatMessage({ id: 'app.user.account' })}
                                </Link>
                                <Link className="dropdown-item" to="/login">
                                    <i className="fas fa-power-off text-primary"></i>
                                    {this.props.intl.formatMessage({ id: 'app.logout' })}
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}

ConnectedNav = Radium(ConnectedNav);

const mapStateToProps = state => {
    return {
        store: {
            user: state.user
        }
    };
};

const Nav = connect(mapStateToProps, null)(ConnectedNav);

Nav.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Nav);