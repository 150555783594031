import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Form } from '../shared/components/form/form.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { showError, showSuccess } from '../store/actions/message.action';
import StaticText from '../shared/components/form/static-text.component';
import StaticTextArea from '../shared/components/form/static-text-area.component';
import { SubmitButton } from '../shared/components/form/submit-button.component';
import Config from '../config';

class ConnectedQuizDeletePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            submitDisable: false,
            id: '',
            availableThemes: [],
            themeId: '',
            title: '',
            description: '',
            themes: [],
            logo: [],
            picture: []
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ id: id });

        http.get(`/manager/quizzes/${id}`)
            .then(response => {
                if (response.ok) {
                    const { title, description, themes, logo, picture } = response.data.quiz;
                    const themesList = themes.map(item => {
                        return item.title;
                    });

                    this.setState({
                        title: title,
                        themes: themesList.toString().replace(',', ', '),
                        description: description,
                        logo: logo,
                        picture: picture,
                    });
                }
            });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push('/quizzes');
                break;
            default:
        }
    }

    /**
     * Submit delete quiz
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        const { id } = this.state;
        const response = await http.del(`/manager/quizzes/${id}`)
        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            history.push('/quizzes');
        }
    }

    render() {
        const { submitDisable, title, description, themes } = this.state;

        return (
            <Page>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.delete.quiz' })}
                    onClick={this.handleAction} back={Config.action.BACK}>
                    <Form onSubmit={this.handleSubmit}>
                        <StaticText label="app.title" value={title} />
                        <StaticTextArea label="app.description" value={description} />
                        <StaticText label="app.themes" value={themes} />
                        <SubmitButton disabled={submitDisable} label="app.delete" />
                    </Form>
                </PageCard>
            </Page>
        );
    }
}

const mapDispatchToProps = {
    showError, showSuccess
};

const QuizDeletePage = connect(null, mapDispatchToProps)(ConnectedQuizDeletePage);

QuizDeletePage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizDeletePage);
