import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Shelf } from '../../shared/components/shelf.component';
import Config from '../../config';

let ShelfNoGroup = ({ intl: { formatMessage }, onClick }) => {
    return (
        <Shelf>
            <div className="opp-shelf-no-department">
                <div className="opp-shelf-no-department-bloc">
                    <p>{formatMessage({ id: 'app.no.group.registered' })}</p>
                    <button type="button" 
                        onClick={() => onClick(Config.action.ADD)} className="btn btn-secondary btn-rounded btn-fw">
                        {formatMessage({ id: 'app.create.group' })}
                    </button>
                </div>
            </div>
        </Shelf>
    );
}

ShelfNoGroup.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ShelfNoGroup);
