import React from 'react';
import {Breadcrumb} from './breadcrumb.component';
import {IntlMessage} from './intl-message.component';

export const PageHeader = (props) => (
    <div className="page-header">
        <h3 className="page-title">
            <IntlMessage id={props.title} /></h3>
        <Breadcrumb second={props.second} third={props.third} />
    </div>
)