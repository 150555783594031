import { combineReducers } from 'redux';
import app from './app.reducer';
import company from './company.reducer';
import departmentCreate from './department-create.reducer';
import departmentModify from './department-modify.reducer';
import notification from './notification.reducer';
import player from './player.reducer';
import questionCreate from './question-create.reducer';
import questions from './questions.reducer';
import quizManager from './quiz-manager.reducer';
import quizzes from './quizzes.reducer';
import showMessages from './message.reducer';
import style from './style.reducer';
import themes from './themes.reducer';
import user from './user.reducer';
import users from './users.reducer';

export default combineReducers({
    app,
    company,
    departmentCreate,
    departmentModify,
    notification,
    player,
    questionCreate,
    questions,
    quizManager,
    quizzes,
    showMessages,
    style,
    themes,
    user,
    users
});