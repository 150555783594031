import React from 'react';
import {Link} from 'react-router-dom';
import {IntlMessage} from './intl-message.component';

export const Breadcrumb = (props) => (
    <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className="breadcrumb-item">
                <Link to="/">
                    <IntlMessage id="app.home" defaultMessage="Home" />
                </Link>
            </li>
            { props.second && !props.third && (
                <li className="breadcrumb-item active">
                    <IntlMessage id={props.second.id} defaultMessage={props.second.defaultMessage} />
                </li>
            )}
            { props.second && props.third && (
                <li className="breadcrumb-item">
                    <Link to={props.second.to}>
                        <IntlMessage id={props.second.id} defaultMessage={props.second.defaultMessage} />
                    </Link>
                </li>
            )}
            { props.third && (
                <li className="breadcrumb-item active">
                    <IntlMessage id={props.third.id} defaultMessage={props.third.defaultMessage} />
                </li>
            )}
        </ol>
    </nav>
)