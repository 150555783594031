import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import ImageUpload from '../../shared/components/form/image-upload.component';
import { InputText } from '../../shared/components/form/input-text.component';
import { InputTextArea } from '../../shared/components/form/input-text-area.component';
import { Panel } from 'primereact/panel';
import Config from '../../config';
import { InputMarkdown } from '../../shared/components/form/input-markdown.component';

class QuizDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dirty: false,
            picture: null,
            quiz: []
        };

        this.handleChangeTitle = this.handleChangeTitle.bind(this);
        this.handleChangePicture = this.handleChangePicture.bind(this);
        this.handleClickPicture = this.handleClickPicture.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            picture: (nextProps.quiz.picture && !prevState.dirty) ? process.env.REACT_APP_IMAGES_DIRECTORY + nextProps.quiz.picture : prevState.picture,
            quiz: nextProps.quiz
        };
    }

    handleChangeDescription(eventOrString) {
        let value = typeof eventOrString === 'string' ? eventOrString : eventOrString.target.value;

        let quiz = this.state.quiz;
        quiz.description = { ...quiz.description, [this.props.lang]: value };
        this.setState({ quiz: quiz });
        this.props.onChange(quiz);
    }

    handleChangePicture(files) {
        const pictureFile = files[0];
        this.setState({ dirty: true, picture: URL.createObjectURL(pictureFile) });
        this.props.onChangePicture(pictureFile);
    }

    handleChangeTitle(event) {
        let quiz = this.state.quiz;
        quiz.title = { ...quiz.title, [this.props.lang]: event.target.value };
        this.setState({ quiz: quiz });
        this.props.onChange(quiz);
    }

    handleClickPicture() {
        let quiz = this.state.quiz;
        quiz.picture = '';
        this.setState({ dirty: false, picture: null, quiz: quiz });
        this.props.onChange(quiz);
    }

    render() {
        const lang = this.props.lang;
        const quiz = this.state.quiz;
        const stylePanel = {
            marginBottom: '2em'
        }

        return (
            <Panel header={this.props.intl.formatMessage({ id: Config.locales.LABELS[lang] })}
                style={stylePanel} toggleable={true}>
                <div className="row">
                    <div className="col-6">
                        <InputText name="title" label="app.title"
                            value={quiz ?  quiz.title[lang] : ''}
                            onChange={this.handleChangeTitle} submitted={this.props.submitted} mandatory="true" />
                    </div>
                    <div className="col-6">
                        {this.props.image && <ImageUpload name="picture" format="square"
                            styles={this.props.styles} kind="primary" onClick={this.handleClickPicture}
                            onChange={this.handleChangePicture} value={this.state.picture}
                            imageLabel="app.choose.image.label.quiz" />}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <InputMarkdown name="description" label="app.description"
                            value={quiz ?  quiz.description[lang] : ''}
                            onChange={this.handleChangeDescription} submitted={this.props.submitted} />
                    </div>
                </div>
            </Panel>
        );
    }
}

QuizDetail.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuizDetail);
