export const INITIALIZE_COMPANY = '[Company] Initialize company';
export const SET_COMPANY = '[Company] Set company';

export const initialState = {
    id: null,
    name: '',
    description: '',
    picture: '',
    logo: '',
    website: '',
    owner: null,
    uploadQuestionLimit: null,
    uploadUserLimit: null,
    createdAt: null
}

export const initializeCompany = () => {
    return { type: INITIALIZE_COMPANY, company: initialState }
}

export const setCompany = company => {
    return { type: SET_COMPANY, company: company }
}