import React, { Component } from "react";
import { connect } from "react-redux";
import { httpService as http } from "../core/services/http.service";
import { historyHelper as history } from "../core/helpers/history.helper";
import { injectIntl, intlShape } from 'react-intl';

import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { PageTable } from '../shared/components/page-table.component';
import { setLastPage } from '../store/actions/app.action';
import { showError, showWarning } from '../store/actions/message.action';
import Config from '../config';

class ConnectedCompaniesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companies: []
        };

        this.handleAction = this.handleAction.bind(this);
    }

    componentDidMount() {
        http.get('/manager/companies')
            .then(response => {
                if (response.ok) {
                    this.setState({ companies: response.data.companies });
                }
            });
    }

    handleAction(action, data) {
        switch (action) {
            case Config.action.ADD:
                history.push(`/companies/new`);
                break;
            case Config.action.DEPARTMENT:
                this.props.setLastPage(`/companies`);
                history.push(`/companies/${data.id}/departments`);
                break;
            case Config.action.MODIFY:
                this.props.setLastPage(`/companies`);
                history.push(`/companies/modify/${data.id}`);
                break;
            case Config.action.QUESTION:
                history.push(`/companies/${data.id}/questions`);
                break;
            case Config.action.QUIZ:
                history.push(`/companies/${data.id}/quizzes`);
                break;
            case Config.action.THEME:
                history.push(`/companies/${data.id}/themes`);
                break;
            case Config.action.USER:
                history.push(`/companies/${data.id}/users`);
                break;
            default:
        }
    }

    render() {

        // Column list
        const cols = [
            { field: 'name', header: 'app.name' },
            { field: 'action', header: 'action' }
        ];

        // Top buttons list
        const topButtons = [
            { action: 'add', label: 'app.add', class: 'btn-primary' }
        ];

        // Body buttons list
        const actionButtons = [
            { action: Config.action.THEME, icon: 'fa fa-flag' },
            { action: Config.action.QUIZ, icon: 'fa fa-brain' },
            { action: Config.action.QUESTION, icon: 'fa fa-question-circle' },
            { action: Config.action.MODIFY, icon: 'fa fa-building' },
            { action: Config.action.DEPARTMENT, icon: 'fa fa-shield-alt' },
            { action: Config.action.USER, icon: 'fa fa-users' }
        ];

        return (
            <Page>
                <PageCard>
                    <PageTable cols={cols} values={this.state.companies}
                        topButtons={topButtons} actionButtons={actionButtons}
                        onClick={this.handleAction} styles={this.props.store.style} />
                </PageCard>
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            company: state.company,
            style: state.style
        }
    };
};

const mapDispatchToProps = {
    setLastPage,
    showError, showWarning
};

const CompaniesPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedCompaniesPage);

CompaniesPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(CompaniesPage);
