import React, { Component } from "react";
import { connect } from "react-redux";
import { historyHelper as history } from "../core/helpers/history.helper";
import { httpService as http } from "../core/services/http.service";
import { injectIntl, intlShape } from 'react-intl';

import { Button } from '../shared/components/button.component';
import Dialog from '../shared/components/dialog.component';
import ImageUpload from '../shared/components/form/image-upload.component';
import InputSwitch from '../shared/components/form/input-switch.component';
import { InputMultiSelect } from '../shared/components/form/input-multiselect.component';
import InputMultiSelectInline from '../shared/components/form/input-multiselect-inline.component';
import { InputText } from '../shared/components/form/input-text.component';
import { Page } from '../shared/components/page/page.component';
import { PageCard } from '../shared/components/page/page-card.component';
import { PageSubTitle } from '../shared/components/page/page-sub-title.component';
import QuestionDetail from './components/question-detail.component';
import { showError, showSuccess } from '../store/actions/message.action';
import Config from '../config';

class ConnectedQuestionModifyPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            submitDisable: false,
            lastPage: '',
            availableQuestionQuizzes: [],
            pictureFile: [],
            question: {
                id: '',
                statement: [],
                picture: '',
                answer1: [],
                answer2: [],
                answer3: [],
                answer4: [],
                correct: 1,
                published: true,
                locales: [Config.app.DEFAULT_LOCALE],
                timer: '',
            },
            quizzes: [],
            dirty: false,
            picture: null,
            dialogOpened: false
        };

        this.handleAction = this.handleAction.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeLocales = this.handleChangeLocales.bind(this);
        this.handleChangeMultiSelect = this.handleChangeMultiSelect.bind(this);
        this.handleChangePicture = this.handleChangePicture.bind(this);
        this.handleChangePublished = this.handleChangePublished.bind(this);
        this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
        this.handleClickPicture = this.handleClickPicture.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteOnHide = this.handleDeleteOnHide.bind(this);
        this.handleDeleteOnSubmit = this.handleDeleteOnSubmit.bind(this);
    }

    async componentDidMount() {
        const { id } = this.props.match.params;
        let question = [];
        let quizzes = [];
        let questionQuizzes = [];
        let availableQuestionQuizzes = [];

        // Get question entity
        const response = await http.get(`/manager/questions/${id}`);
        if (response.ok) {
            question = response.data.question;
            quizzes = response.data.quizzes.map(item => {
                return item.id;
            });
        }

        // Get list of available quizzes of company
        const responseQuizzes = await http.get(`/manager/quizzes/company/${this.props.store.company.id}`);
        if (responseQuizzes.ok) {
            availableQuestionQuizzes = responseQuizzes.data.quizzes.map(item => {
                return { label: item.title, value: item.id };
            });
            questionQuizzes = quizzes.filter(item => {
                const index = availableQuestionQuizzes.map(function (e) { return e.value; }).indexOf(item);
                return index !== -1;
            });
        }

        this.setState({
            lastPage: this.props.store.app.lastPage,
            question: { ...question, locales: question.locales.split(',') },
            quizzes: questionQuizzes,
            picture: question.picture ? process.env.REACT_APP_IMAGES_DIRECTORY + question.picture : null,
            availableQuestionQuizzes: availableQuestionQuizzes
        });
    }

    /**
     * Action to chaining to next page
     * 
     * @param {*} action 
     */
    handleAction(action) {
        switch (action) {
            case Config.action.BACK:
                history.push(this.state.lastPage);
                break;
            default:
        }
    }

    handleChange(event) {
        const { name, value } = event.target;
        let { question } = this.state;

        switch (name) {
            case 'timer':
                question[name] = parseInt(value) >= parseInt(Config.question.DEFAULT_TIMER_MIN)
                    && parseInt(value) <= parseInt(Config.question.DEFAULT_TIMER_MAX)
                    ? value
                    : question[name];
                break;
            default:
                question[name] = value;
        }

        this.setState({ question });
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChangeLocales(event) {
        let question = this.state.question;
        if (event.value.length > 0) {
            question.locales = event.value;
        }
        this.setState({ question: question });
    }

    /**
     * After each field key press, update state of the value
     * 
     * @param {*} event 
     */
    handleChangeMultiSelect(event) {
        this.setState({ quizzes: event.value });
    }

    /**
     * Set picture file
     * 
     * @param {*} files 
     */
    handleChangePicture(files) {
        const pictureFile = files[0];
        if (pictureFile) {
            let question = this.state.question;
            question.picture = pictureFile.name;
            this.setState({ pictureFile: pictureFile, question: question, dirty: true, picture: URL.createObjectURL(pictureFile) });
        }
    }

    /**
     * Set state published value of question
     * 
     * @param {*} event 
     */
    handleChangePublished(event) {
        let question = this.state.question;
        question.published = event.value;
        this.setState({ question: question });
    }

    /**
     * Set state values of question
     * 
     * @param {*} question 
     */
    handleChangeQuestion(question) {
        this.setState({ question: question });
    }

    /**
     * Set state values of question
     * 
     * @param {*} question 
     */
    handleClickPicture() {
        let question = this.state.question;
        question.picture = '';
        this.setState({ dirty: false, picture: null, question: question });
    }

    /**
     * Submit question
     * 
     * @param {*} event 
     */
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        const { question, pictureFile } = this.state;
        if (Object.keys(question.statement).length > 0 &&
            Object.keys(question.answer1).length > 0 &&
            Object.keys(question.answer2).length > 0 &&
            Object.keys(question.answer3).length > 0 &&
            Object.keys(question.answer4).length > 0 &&
            question.correct > 0) {

            // Send picture to API, response is an anonimised reference name
            let pictureName = question.picture;
            let pictureOK = true;
            if (pictureFile.name) {
                let dataPicture = new FormData();
                dataPicture.append('image', pictureFile);
                const responsePicture = await http.postFile('/upload/image', dataPicture);
                if (!responsePicture.ok) {
                    pictureOK = false;
                    this.props.showError(responsePicture.error, ['app.picture.not.uploaded']);
                } else {
                    pictureName = responsePicture.data.image
                }
            }

            if (pictureOK) {
                const data = {
                    question: { ...question, picture: pictureName, locales: question.locales.toString() },
                    quizzes: this.state.quizzes
                };
                const response = await http.put(`/manager/questions/${this.state.question.id}`, data);
                if (!response.ok) {
                    this.props.showError(response.error, ['app.question.not.updated']);
                } else {
                    this.props.showSuccess({ summary: 'app.question.updated' });
                    history.push(this.state.lastPage);
                }
            }
        }
    }

    handleDelete(event) {
        event.preventDefault();
        this.setState({ dialogOpened: true })
    }

    async handleDeleteOnSubmit() {
        const data = { question: { deleted: true } };
        const response = await http.patch(`/manager/questions/${this.state.question.id}`, data);

        if (!response.ok) {
            this.props.showError(response.error);
        } else {
            this.props.showSuccess({ summary: 'app.question.deleted' });
            history.push(this.state.lastPage);
        }

        this.setState({ dialogOpened: false })
    }

    handleDeleteOnHide() {
        this.setState({ dialogOpened: false })
    }

    render() {

        const styleMultiSelect = {
            marginBottom: '.3em',
            width: '100%',
        };

        const styleParameters = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
        }

        const styleDeleteButton = {
            marginRight: '1rem',
        };

        const dynamicDetails = this.state.question.locales.map((locale) => {
            return <QuestionDetail key={locale} lang={locale} question={this.state.question}
                onChange={this.handleChangeQuestion} submitted={this.state.submitted} styles={this.props.store.style} maxLength={Config.inputText.APP_QUESTION_COMMENT_MAX_LENGTH} />
        });

        return (
            <Page>
                <PageCard title={this.props.intl.formatMessage({ id: 'app.question.form' })}
                    onClick={this.handleAction} back={Config.action.BACK}>

                    <PageSubTitle title={this.props.intl.formatMessage({ id: 'app.publication.parameters' })} />

                    <div className="row">
                        <div className="col-6">
                            <div style={styleParameters}>

                                <InputMultiSelect name="quizzes"
                                    label="app.quiz"
                                    values={this.state.quizzes}
                                    options={this.state.availableQuestionQuizzes}
                                    onChange={this.handleChangeMultiSelect}
                                    submitted={this.state.submitted} styles={styleMultiSelect} />
                                
                                <InputText name="timer"
                                    inputType="number"
                                    label="app.timer"
                                    onChange={this.handleChange}
                                    value={this.state.question.timer} />

                                <InputSwitch label={this.state.question.published ? "app.published" : "app.draft"}
                                checked={this.state.question.published}
                                kind="question"
                                styles={this.props.store.style}
                                onChange={this.handleChangePublished} />
                            </div>
                        </div>
                        <div className="col-6">
                            <ImageUpload name="picture"
                                styles={this.props.store.style} kind="primary" onClick={this.handleClickPicture}
                                onChange={this.handleChangePicture} value={this.state.picture} />
                        </div>
                    </div>

                    <InputMultiSelectInline name="locales" label="app.language" kind="question"
                        options={Config.locales.LIST} value={this.state.question.locales}
                        onChange={this.handleChangeLocales} />

                    {dynamicDetails}

                    <div className="float-right" >
                        <Button label="app.save" onClick={(e) => this.handleSubmit(e)}
                            styles={this.props.store.style} kind="primary" />
                    </div>

                    <div className="float-right" style={styleDeleteButton}>
                        <Button label="app.delete" onClick={(e) => this.handleDelete(e)}
                            styles={this.props.store.style} kind="danger" />
                    </div>
                </PageCard>

                <Dialog visible={this.state.dialogOpened}
                    text={this.props.intl.formatMessage({ id: 'app.dialog.delete.question' })}
                    onHide={this.handleDeleteOnHide} onSubmit={this.handleDeleteOnSubmit} />

            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: {
            app: state.app,
            company: state.company,
            style: state.style
        }
    };
};

const mapDispatchToProps = {
    showError, showSuccess
};

const QuestionModifyPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestionModifyPage);

QuestionModifyPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(QuestionModifyPage);
