import React from 'react';
import { IntlMessage } from '../intl-message.component';
import Config from '../../../config';
import ReactMarkdown from 'react-markdown';
import ReactMde from 'react-mde';
import "react-mde/lib/styles/css/react-mde-all.css";

export const InputMarkdown = React.forwardRef((props, ref) => {
    const [selectedTab, setSelectedTab] = React.useState('write')

    return <div className="form-group">
        <label><IntlMessage id={props.label} /></label>

        <div className="">
            <ReactMde
                value={props.value}
                onChange={props.onChange.bind(this)}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                disabled={props.disabled ? props.disabled : false}
                generateMarkdownPreview={markdown => Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)}
                toolbarCommands={[
                    ['header', 'bold', 'italic', 'strikethrough'],
                    ['link', 'quote', 'code'],
                    ['unordered-list', 'ordered-list', 'checked-list'],
                ]}
                classes={{
                    textArea: 'w-full textarea',
                }}
                childProps={{
                    writeButton: {
                        tabIndex: -1,
                    },
                }}
            />
        </div>

        {/* <textarea ref={ref} type="text" className="form-control" id={props.name}
            name={props.name} value={props.value} placeholder={props.placeholder}
            maxlength={props.maxLength ? props.maxLength : Config.inputText.DEFAULT_MAX_LENGTH}
            onChange={props.onChange.bind(this)}
            rows={props.rows ? props.rows : '6'}
            disabled={props.disabled ? props.disabled : false} ></textarea> */}

        {props.submitted && props.mandatory && !props.value &&
            <div className="help-block">*Ce champ est obligatoire</div>
        }
    </div>
});
