import React, { Component } from 'react';
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';

import ButtonRounded from '../button-rounded.component';
import ImageUploader from 'react-images-upload';

class Upload extends Component {

    render() {
        const imageLabel = this.props.imageLabel ? this.props.imageLabel : 'app.choose.image.label';

        let formatWidth = '20.8rem';
        let formatHeight = '11.7rem';

        switch (this.props.format) {
            case 'square':
                formatWidth = '16rem';
                formatHeight = '16rem';
                break;
            case 'small-square':
                formatWidth = '10rem';
                formatHeight = '10rem';
                break;
            default:
        }

        const styleContainer = {
            base: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }
        }
        const styleButton = {
            ...this.props.styles.button.secondary,
            margin: '0',
            padding: '.3rem'
        }

        const styleFileContainer = {
            ...this.props.styles.imageUpload.image.container,
            width: formatWidth,
            height: formatHeight,
            borderRadius: '0',
            textAlign: 'center'
        }

        const styleImageContainer = {
            base: {
                width: formatWidth,
                height: formatHeight,
                borderRadius: 0,
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${this.props.value})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain'
            }
        }

        return (
            <div className="flex">
                {this.props.label && (
                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <label>{this.props.intl.formatMessage({ id: this.props.label })}</label>
                    </div>
                )}
                {!this.props.value && (
                    <div style={[styleContainer.base]}>
                        <ImageUploader name={this.props.name} withIcon={true} onChange={this.props.onChange.bind(this)}
                            buttonText={this.props.intl.formatMessage({ id: 'app.choose.image' })}
                            buttonStyles={styleButton}
                            fileContainerStyle={styleFileContainer}
                            label={this.props.intl.formatMessage({ id: imageLabel })}
                            imgExtension={['.jpg', '.jpeg', '.png', '.gif']} maxFileSize={512000} singleImage={true}
                            fileSizeError={this.props.intl.formatMessage({ id: 'app.error.image.size' })}
                            fileTypeError={this.props.intl.formatMessage({ id: 'app.error.image.type' })} />
                    </div>
                )}
                {this.props.value && (
                    <div style={[styleContainer.base]}>
                        <div style={[styleImageContainer.base, this.props.styles.imageUpload.image.container]}></div>
                        <div><ButtonRounded label="app.change.image" onClick={this.props.onClick.bind(this)}
                            styles={this.props.styles} kind="secondarySmall" />
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

Upload = Radium(Upload);

const ImageUpload = React.forwardRef((props, ref) => (<Upload {...props} innerRef={ref} />));

ImageUpload.propTypes = {
    intl: intlShape.isRequired,
};


export default injectIntl(ImageUpload);