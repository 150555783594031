import {
    initialState,
    USERS_SET_COMPANY,
    USERS_INITIALIZE_COMPANY
 } from '../actions/users.action';

const users = (state = initialState, action) => {
    switch (action.type) {
        case USERS_SET_COMPANY:
            return { ...state, company: action.company };
        case USERS_INITIALIZE_COMPANY:
            return { ...state, company: action.company };
        default:
            return state
    }
}

export default users;