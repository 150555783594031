import React, { Component } from 'react';
import { connect } from "react-redux";
import { addLocaleData, IntlProvider } from 'react-intl';

import App from './app.component';
import { setLocale } from './store/actions/app.action';

import messages_en from "./translations/en.json";
import messages_es from "./translations/es.json";
import messages_fr from "./translations/fr.json";
import messages_nl from "./translations/nl.json";

import * as en from 'react-intl/locale-data/en';
import * as es from 'react-intl/locale-data/es';
import * as fr from 'react-intl/locale-data/fr';
import * as nl from 'react-intl/locale-data/nl';
addLocaleData([...en, ...es, ...fr, ...nl]);

const messages = {
    'en': messages_en,
    'es': messages_es,
    'fr': messages_fr,
    'nl': messages_nl
};

class ConnectedIntl extends Component {

    componentDidMount() {
        if (this.props.user.locale) {
            this.props.setLocale(this.props.user.locale);
        } else {
            const navigatorLocale = navigator.language || navigator.userLanguage;
            const locale = navigatorLocale.substring(0,2).toLowerCase();
            if (locale in messages) {
                this.props.setLocale(locale);
            }
        }
    }

    render() {
        return (
            <IntlProvider locale={this.props.app.locale} messages={messages[this.props.app.locale]}>
                <App />
            </IntlProvider>
        );
    }
}

const mapStateToProps = state => {
    return {
        app: state.app,
        user: state.user
    }
};

const mapDispatchToProps = {
    setLocale
};

const Intl = connect(mapStateToProps, mapDispatchToProps)(ConnectedIntl);

export default Intl;
