import React, { Component } from 'react';
import { connect } from "react-redux";
import Radium from 'radium';
import { injectIntl, intlShape } from 'react-intl';

import { ButtonRoundedIcon } from '../../shared/components/button-rounded-icon.component';
import Config from '../../config';

export class ConnectedQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            delete: true,
            modify: true,
            deleted: false,
        };
    }

    componentDidMount() {
        let deleteButton = true;
        let modifyButton = true;
        let deleted = false;


        if (typeof this.props.delete !== 'undefined') {
            deleteButton = this.props.delete;
        }

        if (typeof this.props.modify !== 'undefined') {
            modifyButton = this.props.modify;
        }

        if (typeof this.props.deleted !== 'undefined') {
            deleted = this.props.deleted;
        }

        this.setState({ delete: deleteButton, modify: modifyButton, deleted: deleted });
    }

    render() {
        const { style } = this.props.store;

        let styleContainer = {
            base: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                borderRadius: '3px',
                fontSize: '.8em',
                margin: '.5em .5em 0 .5em',
                padding: '.5em'
            }
        };

        styleContainer = { ...styleContainer, ...style.question.container };

        const styleImage = {
            height: '1.8rem',
            textAlign: 'center',
            width: '3rem',
        }

        const styleImageImg = {
            height: '1.8rem',
        }

        const styleLocale = {
            marginLeft: '.3rem'
        }

        const styleLocales = {
            marginLeft: '.5rem'
        }

        const styleQuestion = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center'
        }

        const styleStatement = {
            marginLeft: '1em',
            //paddingTop: '.4em',
            textAlign: 'left'
        }

        const styleActions = {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            alignItems: 'center'
        }

        const locales = this.props.question.locales.split(',');
        const dynamicLocales = locales.map((item, index) => {
            return <i className={`flag-icon flag-icon-${Config.locales.ICON[item]}`} style={styleLocale} title={item} id={item}></i>
        });

        return (
            <div style={[styleContainer.base, styleContainer[this.props.kind]]} >
                <div style={styleQuestion}>
                    <div style={styleImage}>
                    {this.props.question.picture && (<img style={styleImageImg} src={`${process.env.REACT_APP_API_HOST}/media/${this.props.question.picture}?method=resize&h=50`}
                            alt={this.props.question.statement} />)}
                    </div>
                    <div style={styleStatement} >{this.props.question.statement}</div>
                    <div style={styleLocales}>{dynamicLocales}</div>
                </div>
                <div style={styleActions}>
                    {this.state.modify && !this.state.deleted && this.props.onClick &&
                        (<ButtonRoundedIcon icon="fa fa-pen" onClick={() => this.props.onClick(Config.action.MODIFY, this.props.question)}
                            styles={style} kind="secondary" />)}
                    {this.state.delete && !this.state.deleted && this.props.onClick &&
                        (<ButtonRoundedIcon icon="fa fa-trash" onClick={() => this.props.onClick(Config.action.DELETE, this.props.question)}
                            styles={style} kind="dangerSecondary" />)}
                    {this.state.deleted && this.props.onClick &&
                        (<ButtonRoundedIcon icon="fas fa-undo" onClick={() => { this.props.onClick(Config.action.RESTORE, this.props.question) }}
                            styles={style} kind="secondary" />)}
                </div>
            </div>
        );
    }
}

ConnectedQuestion = Radium(ConnectedQuestion);

const mapStateToProps = state => {
    return {
        store: {
            style: state.style
        }
    };
};

const Question = connect(mapStateToProps, null)(ConnectedQuestion);

Question.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Question);