import React, { Component } from "react";
import { connect } from "react-redux";
import { httpService as http } from "../core/services/http.service";
import { historyHelper as history } from "../core/helpers/history.helper";
import { injectIntl, intlShape } from 'react-intl';
import Radium from 'radium';

import { authenticationService as auth } from "./services/authentication.service";
import { addPlayer } from '../store/actions/player.action';
import { addUser } from '../store/actions/user.action';
import { Link } from 'react-router-dom';
import { setCompany } from '../store/actions/company.action';
import { showError, showSuccess } from '../store/actions/message.action';
import { setLocale } from '../store/actions/app.action';

class ConnectedLoginPage extends Component {

    constructor(props) {
        super(props);

        // reset login status
        auth.logout();

        this.state = {
            dirty: false,
            forgot: false,
            password: '',
            submitted: false,
            username: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleForgot = this.handleForgot.bind(this);
        this.handleForgotSubmit = this.handleForgotSubmit.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    }

    handleChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleForgot(event) {
        event.preventDefault();
        this.setState({ forgot: true });
    }

    async handleForgotSubmit(event) {
        event.preventDefault();
        this.setState({ dirty: true, submitted: true });
        if (this.state.username) {
            const data = {
                username: this.state.username
            }
            const response = await http.post(`/users/recovery`, data);
            if (response.ok) {
                this.props.showSuccess({ summary: 'app.recovery.email.sended' });
                this.setState({ forgot: false });
            } else {
                this.props.showError(response.error);
            }
        }
        this.setState({ submitted: false });
    }

    async handleLoginSubmit(event) {
        event.preventDefault();
        this.setState({ dirty: true, submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            const response = await auth.login(username, password);
            if (response.ok) {
                this.props.addUser(response.data.user);
                const user = JSON.parse(localStorage.getItem('user'));
                this.props.setLocale(user.locale);
                if (response && (user.role === 'admin' || user.role === 'superadmin')) {
                    const playerResponse = await http.get('/manager/players/user/' + user.id);
                    if (playerResponse.ok) {
                        this.props.addPlayer(playerResponse.data.player);
                        this.props.setCompany(playerResponse.data.company);
                        history.push('/');
                    }
                } else {
                    this.props.showError({ summary: 'app.error.forbidden' });
                }
            } else {
                this.props.showError(response.error);
            }
        }
        this.setState({ submitted: false });
    }

    render() {
        const { username, password } = this.state;

        const styleContainer = {
            base: {
                backgroundColor: '#f2f2f2',
                display: 'flex',
                flexDirection: 'row',
                marginRight: 'auto',
                marginLeft: 'auto',
                padding: '0',
                overflow: 'hidden',
                width: '100%',
                minHeight: '100vh'
            }
        }

        const styleFormContainer = {
            base: {
                marginTop: '20%',
                textAlign: 'left',
                padding: '1rem'
            }
        }

        const styleImageContainer = {
            base: {
                display: 'flex',
                flexDirection: 'row',
                backgroundImage: 'url(\'templates/default/images/img_connexion.jpg\')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }
        }

        const styleLoginContainer = {
            base: {
                display: 'flex',
                justifyContent: 'center'
            }
        }

        const styleLogoContainer = {
            base: {
                textAlign: 'center',
                marginBottom: '2rem'
            }
        }

        const styleRowContainer = {
            base: {
                flexGrow: '1'
            }
        }

        const styleForm = {
            base: {
                paddingTop: '1rem'
            }
        }

        return (
            <div style={[styleContainer.base]}>
                <div className="row" style={[styleRowContainer.base]}>
                    <div className="col-lg-6" style={[styleLoginContainer.base]}>
                        <div style={[styleFormContainer.base]}>
                            <div style={[styleLogoContainer.base]}>
                                <img src="templates/default/images/logo-br.png" alt="logo" style={{ width: '20rem' }} />
                            </div>
                            {!this.state.forgot && (
                                <div>
                                    <h4>{this.props.intl.formatMessage({ id: 'app.happy.to.see.you.again' })}</h4>
                                    <form onSubmit={this.handleLoginSubmit} style={[styleForm.base]}>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-transparent">
                                                    <span className="input-group-text bg-transparent border-right-0">
                                                        <i className="fa fa-user text-primary"></i>
                                                    </span>
                                                </div>
                                                <input type="email" name="username" value={username} onChange={this.handleChange} className="form-control form-control-lg border-left-0" placeholder={this.props.intl.formatMessage({ id: 'app.username' })} />
                                            </div>
                                            {this.state.dirty && !this.state.username && <div>{this.props.intl.formatMessage({ id: 'app.mandatory.field' })}</div>}
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-transparent">
                                                    <span className="input-group-text bg-transparent border-right-0">
                                                        <i className="fa fa-lock text-primary"></i>
                                                    </span>
                                                </div>
                                                <input type="password" name="password" value={password} onChange={this.handleChange} className="form-control form-control-lg border-left-0" placeholder={this.props.intl.formatMessage({ id: 'app.password' })} />
                                            </div>
                                        </div>
                                        <div className="my-3">
                                            <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                                disabled={this.state.submitted ? true : false}>{this.props.intl.formatMessage({ id: 'app.login' })}</button>
                                        </div>
                                        <div style={[{ margintTop: '.5rem', fontSize: '.9rem', textAlign: 'right', cursor: 'pointer', ':hover': { textDecoration: 'underline' } }]}>
                                            {/*<a onClick={this.handleForgot} href="#top">{this.props.intl.formatMessage({ id: 'app.forgot.password' })}</a>*/}
                                            <Link to="/" onClick={this.handleForgot}>{this.props.intl.formatMessage({ id: 'app.forgot.password' })}</Link>
                                        </div>
                                    </form>
                                </div>
                            )}
                            {this.state.forgot && (
                                <div>
                                    <h4>{this.props.intl.formatMessage({ id: 'app.reset.password' })}</h4>
                                    <form onSubmit={this.handleForgotSubmit} style={[styleForm.base]}>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-transparent">
                                                    <span className="input-group-text bg-transparent border-right-0">
                                                        <i className="fa fa-user text-primary"></i>
                                                    </span>
                                                </div>
                                                <input type="email" name="username" value={username} onChange={this.handleChange} className="form-control form-control-lg border-left-0" placeholder="Username" />
                                            </div>
                                            {this.state.dirty && !this.state.username && <div>{this.props.intl.formatMessage({ id: 'app.mandatory.field' })}</div>}
                                        </div>
                                        <div className="my-3">
                                            <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                                disabled={this.state.submitted ? true : false}>{this.props.intl.formatMessage({ id: 'app.send.me.new.password' })}</button>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6" style={[styleImageContainer.base]}>
                        <p className="text-white font-weight-medium text-center flex-grow align-self-end">© Copyright ØPP Startup Studio SA 2019</p>
                    </div>
                </div>
            </div>
        );
    }
}

ConnectedLoginPage = Radium(ConnectedLoginPage);

const mapDispatchToProps = {
    setCompany, addPlayer, addUser,
    setLocale,
    showError, showSuccess
};

const LoginPage = connect(null, mapDispatchToProps)(ConnectedLoginPage);

LoginPage.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(LoginPage);